import React from "react";
import styled from "styled-components";
import SectionTitle from "./SectionTitle.component";
import { selectTeamMembersByRole } from "store/reducers/programs/programs.selectors";
import { UserCard } from "components/Avatar/UserCard.component";
import { Button } from "components/Buttons/Button.component";

export default function TeamSection({
  selectedProgram,
}: {
  selectedProgram?: any;
}) {
  const usersByRole = selectTeamMembersByRole(selectedProgram);
  const displayTeam = (team) => {
    return (
      <TeamBox>
        {team.map((member, index) => {
          return (
            <CustomUserCard
              role={member.role}
              key={index}
              firstName={member.firstName}
              lastName={member.lastName}
            />
          );
        })}
      </TeamBox>
    );
  };
  return (
    <Box>
      {Object.keys(usersByRole).map((key) => {
        const users = usersByRole[key];
        return (
          <div key={key}>
            <SectionTitle>{key}</SectionTitle>
            {displayTeam(users)}
          </div>
        );
      })}
      <CustomButton>View all team members</CustomButton>
    </Box>
  );
}

const Box = styled.div`
  width: 584px;
  margin-right: 16px;
  height: auto;
`;

const TeamBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
`;

const CustomUserCard = styled(UserCard)`
  width: 280px;
  margin-bottom: 24px;
`;

const CustomButton = styled(Button)`
  height: 48px;
  padding: 8px 16px 8px 16px;
  margin-top: 30px;
  width: 100%;
`;
