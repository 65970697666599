import { LabelType } from "components";
import colors from "theme/colors";

type Mapping = {
  label: string;
  labelType: LabelType;
  color: string;
};

export const statusMapping: {
  awaitingApproval: Mapping;
  complete: Mapping;
  attentionRequired: Mapping;
} = {
  awaitingApproval: {
    label: "Awaiting Approval",
    labelType: "started",
    color: colors.secondary,
  },
  complete: {
    label: "Complete",
    labelType: "complete",
    color: colors.projectComplete,
  },
  attentionRequired: {
    label: "Attention Required",
    labelType: "late",
    color: colors.projectLate,
  },
};
