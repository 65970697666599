import {
  DataTypeEnum,
  dataTypesOptions,
  DomainEnum,
} from "../../../../store/reducers/products/properties/pl-properties.types";

export const determineDataTypeOptions = (
  domain: DomainEnum | undefined,
  hasValues?: boolean
  //dataType?: DataTypeEnum
) => {
  if (hasValues === undefined) {
    if (domain === DomainEnum.RANGE)
      return [DataTypeEnum.FLOAT, DataTypeEnum.INT];
    return dataTypesOptions;
  }

  if (!hasValues) return dataTypesOptions;

  if (hasValues) return [];

  // leave this for future improvement in data type validation
  // if (hasValues && dataType) {
  //   if (domain === DomainEnum.RANGE) {
  //     return [DataTypeEnum.FLOAT, DataTypeEnum.INT];
  //   }
  //   if ([DataTypeEnum.STRING, DataTypeEnum.BOOL].includes(dataType)) {
  //     return [] as string[];
  //   }
  //   if ([DataTypeEnum.INT, DataTypeEnum.FLOAT].includes(dataType)) {
  //     return [DataTypeEnum.FLOAT, DataTypeEnum.INT, DataTypeEnum.STRING];
  //   }
  // }

  return dataTypesOptions;
};

export const PROPERTY_NAME_REGEX = /^$|^[a-zA-Z]$|^[a-zA-Z][a-zA-Z0-9@|_#.]+$/g;
