import React from "react";
import styled from "styled-components";
import { AddCircle } from "assets/logos/icons/MediumIcons";
import colors from "theme/colors";

export function TableButton({
  onClick,
  label,
  disabled = false,
  style,
}: {
  onClick?: any;
  label: string;
  disabled?: boolean;
  style?: any;
}) {
  return (
    <Wrapper onClick={onClick} disabled={disabled} style={style}>
      <AddCircle color={disabled ? colors.disabled : colors.secondary} />
      <Label>{label}</Label>
    </Wrapper>
  );
}

const Label = styled.span`
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  margin-left: 8px;
`;

const Wrapper = styled.button`
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.background.primaryLabels};
  border: 1px solid ${(props) => props.theme.colors.secondary};
  color: ${(props) => props.theme.colors.secondary};
  width: 100%;
  padding: 8px 8px;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;

  /* select */
  text-decoration: none;
  user-select: none;
  outline: none;

  :hover {
    opacity: 0.7;
  }

  :active {
    opacity: 1;
  }

  :disabled {
    :hover {
      opacity: 1;
    }
    background-color: ${(props) => props.theme.colors.tables.hoveredCell};
    border-color: ${(props) => props.theme.colors.disabled};
    color: ${(props) => props.theme.colors.disabled};
    cursor: not-allowed;
  }
`;
