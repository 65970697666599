import React from "react";
import styled from "styled-components";
import ConfirmActionButton from "../ConfirmActionModal/ConfirmActionButton.component";

export function HoverTrashRowIcon({ deleteAction }: { deleteAction: any }) {
  return (
    <RelativeWrapper>
      <Wrapper className="row-trash-container">
        <ConfirmActionButton
          message={"Are you sure you want to delete this row?"}
          action={deleteAction}
          buttonType={"trashIcon"}
        />
      </Wrapper>
    </RelativeWrapper>
  );
}

const RelativeWrapper = styled.div`
  position: relative;
`;

const Wrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0px;
  border: 1px solid ${(props) => props.theme.colors.tables.borders};
  border-right: none;
  left: -31px;
  height: 41px;
  padding: 5px;
  box-sizing: border-box;
  z-index: 1;
  cursor: pointer;
  align-items: center;
  background-color: ${(props) => props.theme.colors.tables.hoveredCell};
`;
