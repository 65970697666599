import colors, { Colors } from "./colors";
import device, { Device } from "./devices";
import borders, { Borders } from "./borders";
import fonts, { Fonts } from "./fonts";

export interface Theme {
  colors: Colors;
  device: Device;
  borders: Borders;
  fonts: Fonts;
}

const theme = {
  colors,
  device,
  borders,
  fonts,
};

export default theme;
