import React from "react";
import styled from "styled-components";
import { NormalP } from "components/Typography/Paragraphs.component";
import { Button } from "components/Buttons/Button.component";
import colors from "theme/colors";
import SectionTitle from "./SectionTitle.component";
import ListElement from "components/Typography/ListElement.component";

export default function OverviewSection({
  requirements,
}: {
  requirements?: any;
}) {
  const displayListElements = () => {
    return (
      <CustomUl>
        {requirements?.map((listElement) => {
          return <ListElement key={listElement}>{listElement}</ListElement>;
        })}
      </CustomUl>
    );
  };
  return (
    <MainBox>
      <SectionTitle>Program Overview</SectionTitle>
      <ContentBox color={colors.muttedHeadlines}>
        <CustomParagraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla aliquet
          quis sem eu dictum. Donec tincidunt arcu nulla, nec maximus felis
          porta et. Sed ultrices condimentum metus, sit amet efficitur sem
          tincidunt nec. Etiam vel luctus elit, sit amet pellentesque erat.
          Integer condimentum eros eget.
        </CustomParagraph>
        <CustomButton type="primary" color="secondary">
          Export Summary
        </CustomButton>
      </ContentBox>
      <SectionTitle>Requirements</SectionTitle>
      <ContentBox color={colors.muttedHeadlines}>
        {displayListElements()}
      </ContentBox>
    </MainBox>
  );
}

const MainBox = styled.div`
  width: 492px;
`;

const ContentBox = styled.div`
  width: 492px;
  padding: 32px 41px 32px 40px;
  border-radius: 4px;
  border: 1px solid ${colors.tables.borders};
  background-color: ${colors.white};
  box-sizing: border-box;
  margin-bottom: 32px;
`;

const CustomParagraph = styled(NormalP)`
  line-height: 28px;
  margin: 0px;
  color: ${colors.muttedHeadlines};
`;

const CustomButton = styled(Button)`
  height: 32px;
  padding: 8px 16px 8px 16px;
  margin-top: 30px;
`;

const CustomUl = styled.ul`
  list-style: none;
  margin-block-start: 0px;
  margin-block-end: 0px;
  padding-inline-start: 0px;
`;
