export function selectRulesTablesPagination(state) {
  const { total, page } = state.products.rulestables;
  return { total, page };
}

export function moreRuleTablesToLoad(state) {
  const { total } = selectRulesTablesPagination(state);
  const rulesTablesCount = state.products.rulestables.allIds.length;
  return total > rulesTablesCount;
}
