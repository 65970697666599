import React from "react";
import styled from "styled-components";
import { ExpandableSection, H4 } from "components";
import { PropertiesDefinitionTable } from "../../../components/PropertiesDefinitionTable/PropertiesDefinitionTable.component";
import { StandardSubPropertiesTableComponent } from "../../../components/StandardSubPropertiesTable/StandardSubPropertiesTable.component";
import { useTypedSelector } from "../../../../../store";
import { useDispatch } from "react-redux";
import {
  getNextPropertyPage,
  getPropertyAction,
} from "store/reducers/products/properties/pl-properties.actions";
import AddSubpropertyButton from "./AddSubpropertyButton.component";
import { getSubPropertiesAction } from "store/reducers/products/subproperties/pl-subproperties.actions";
import colors from "theme/colors";
import { useParams } from "react-router-dom";
import { ParentPropertyContext } from "./ParentPropertyContext";
import { LoaderSpinner2 } from "components";
import { LoadMoreButton } from "../../../../../components/LoadMoreButton/LoadMoreButton.component";
import { morePropertiesToLoad } from "../../../../../store/reducers/products/properties/pl-properties.selectors";

export default function PropertiesList({
  closeSections,
  changeSectionsStatus,
}: {
  closeSections: any;
  changeSectionsStatus: any;
}) {
  const dispatch = useDispatch();
  const { productLineId } = useParams<{ productLineId: string }>();
  const { byId: propertiesById } = useTypedSelector(
    (state) => state.products.properties
  );
  const gettingProperties = useTypedSelector(
    (state) => state.products.properties.getting
  );
  const ruleSets =
    useTypedSelector((state) => state.products?.parameters.ruleSets) || [];
  const tags =
    useTypedSelector((state) => state.products?.parameters.tags) || [];
  const subpropertiesIds =
    useTypedSelector(
      (state) => state.products?.subproperties?.byParentPropertyId
    ) || [];
  const moreItemsToLoad = useTypedSelector((state) =>
    morePropertiesToLoad(state)
  );

  const handleExpandProperty = (propertyId) => {
    dispatch(getPropertyAction({ propertyId }));
    dispatch(
      getSubPropertiesAction({
        productLineId: productLineId,
        PLPropertyId: propertyId,
      })
    );
  };

  const handleNextPage = () => {
    dispatch(getNextPropertyPage());
  };

  return (
    <PropertiesListWrapper>
      {Object.keys(propertiesById).map((propertyId) => (
        <ParentPropertyContext.Provider
          key={propertyId}
          value={{
            parentId: propertyId,
            parentPropertyName: propertiesById[propertyId].name,
            parentPropertyRulesets: propertiesById[propertyId].ruleSets,
            parentPropertyTags: propertiesById[propertyId].tags,
            parentDataType: propertiesById[propertyId].dataType,
            parentDomain: propertiesById[propertyId].domain,
            productLineId: productLineId,
          }}
        >
          <ExpandableSection
            title={propertiesById[propertyId].name}
            onExpand={() => handleExpandProperty(propertyId)}
            closeSection={closeSections}
            changeSectionsStatus={changeSectionsStatus}
            defaultIsExpanded={propertiesById[propertyId].isNewProperty}
          >
            {gettingProperties && (
              <LoadingBox>
                <LoaderSpinner2 />
              </LoadingBox>
            )}
            {!gettingProperties && (
              <>
                <MainBox>
                  <LeftBox>
                    <PropertiesDefinitionTable
                      propertyData={propertiesById[propertyId]}
                      ruleSets={ruleSets}
                      tags={tags}
                      type="main"
                    />
                    <SubPropertiesCol>
                      <HeaderWrapper>
                        <H4 bold color={colors.muttedHeadlines}>
                          Custom Subproperties
                        </H4>
                        <AddSubpropertyButton
                          parentPropertyId={propertyId}
                          productLineId={productLineId}
                          sectionName={propertiesById[propertyId].name}
                        />
                      </HeaderWrapper>

                      {subpropertiesIds[propertyId]?.allIds.map(
                        (subPropertyId) => {
                          return (
                            <PropertiesDefinitionTable
                              propertyData={
                                subpropertiesIds[propertyId].byId[subPropertyId]
                              }
                              key={subPropertyId}
                              type="subproperties"
                              ruleSets={ruleSets}
                              tags={tags}
                            />
                          );
                        }
                      )}
                    </SubPropertiesCol>
                  </LeftBox>
                  <RightBox>
                    <StandardSubPropertiesTableComponent
                      propertyData={
                        subpropertiesIds[propertyId]?.standardById || {}
                      }
                      ruleSets={ruleSets}
                      tags={tags}
                    />
                  </RightBox>
                </MainBox>
              </>
            )}
          </ExpandableSection>
        </ParentPropertyContext.Provider>
      ))}
      <FooterWrapper>
        {gettingProperties ? (
          <LoaderSpinner2 />
        ) : (
          <LoadMoreButton
            onClick={handleNextPage}
            moreItems={moreItemsToLoad}
          />
        )}
      </FooterWrapper>
    </PropertiesListWrapper>
  );
}

const PropertiesListWrapper = styled.div``;

const MainBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
`;

const LeftBox = styled.div`
  margin-right: 32px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

const RightBox = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;

const SubPropertiesCol = styled.div`
  display: flex;
  flex-direction: column;
  //border: 1px solid green;
`;

const LoadingBox = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
`;

const HeaderWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;

  //Children margins
  & > * {
    margin-top: 0px; //will not be 0, but will go to a min value
    margin-bottom: 0px; //will not be 0, but will go to a min value
  }
`;
