import React from "react";
import styled from "styled-components";
import { NormalP, SmallP, Label, ProgressBar, Row } from "components";
import { Avatar } from "components/Avatar/Avatar.component";
import { ClockIcon } from "assets/logos/icons/ActionsSmall";
import { Program } from "store/reducers/programs/programs.types";
import { mappedLabelTypesToColors } from "theme/colors";
import { DottedMenuButton } from "assets/logos/icons/ButtonsIcons";
import { daysDeclination, tasksDeclination } from "helpers/declinations";

export interface CardProgramsProps extends Partial<Program> {
  className?: any;
  completion: number;
  onClick?: any;
}

const mappedStatusToLabel = {
  onTime: (days) => `${days} ${daysDeclination(days)} Left`,
  late: (days) => `${Math.abs(days)} ${daysDeclination(days)} Late`,
  paused: () => `Paused`,
  complete: () => `Complete`,
  started: () => `Started`,
};

export function CardPrograms({
  name,
  status,
  completion,
  className,
  tasksRemaining,
  daysLeft,
  teamMembers,
  onClick,
}: CardProgramsProps) {
  if (!status) return <></>;

  const color = mappedLabelTypesToColors[status].color;

  return (
    <Wrapper className={className} color={color} onClick={onClick}>
      <Header>
        <TextContainer>
          <Row
            style={{ justifyContent: "space-between", whiteSpace: "nowrap" }}
          >
            <NormalP bold style={{ marginBottom: 8, paddingRight: 16 }}>
              {name}
            </NormalP>
            <DottedMenuButton />
          </Row>

          <StyledSmallP>
            {tasksRemaining} {tasksDeclination(tasksRemaining)} Remaining
          </StyledSmallP>
        </TextContainer>

        <Label icon={<ClockIcon color={color} />} type={status}>
          {mappedStatusToLabel[status](daysLeft)}
        </Label>
      </Header>
      <ProgressBar status={status} completion={completion} />
      <AvatarRow>
        {teamMembers?.map((user, index) => {
          if (index <= 3) {
            return (
              <StyledAvatar
                firstName={user.firstName}
                lastName={user.lastName}
                size="small"
                key={user.name}
              />
            );
          }
        })}
        {teamMembers && teamMembers.length >= 3 && (
          <TeamMembersCircle>+5</TeamMembersCircle>
        )}
      </AvatarRow>
    </Wrapper>
  );
}

const AvatarRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: baseline;
  width: 100%;
`;

const StyledAvatar = styled(Avatar)`
  border: 2px solid white;
  margin-right: -8px;
`;

const Header = styled.div``;

const TextContainer = styled.div``;

const StyledSmallP = styled(SmallP)`
  opacity: 0.6;
  margin-top: 0px;
`;

const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;

  width: 348px;
  height: 248px;
  max-width: 100%;

  background: ${(props) => props.theme.colors.white};

  border: 1px solid ${(props) => props.theme.colors.tables.borders};
  box-sizing: border-box;
  border-radius: 4px;

  :hover {
    border: 1px solid transparent;
    box-shadow: inset 0 0 0 2px ${(props) => props.color};
  }
`;

const TeamMembersCircle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 64px;
  background-color: ${(props) => props.theme.colors.muttedHeadlines};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid white;
  margin-right: -8px;
  color: ${(props) => props.theme.colors.white};
  font-size: 16px;
`;
