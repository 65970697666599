import React from "react";
import { SortedValidationObject } from "./helpers";
import ValidationItem from "./ValidationItem.component";

export default function ValidationList({
  validationData,
}: {
  validationData: SortedValidationObject;
}) {
  return (
    <>
      {Object.keys(validationData).map((objectId) => (
        <ValidationItem
          key={objectId}
          validationItemData={validationData[objectId]}
        />
      ))}
    </>
  );
}
