import React, { useEffect, useState } from "react";
import { Button, TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import EditIcon from "@mui/icons-material/Edit";
import { H4 } from "components";
import styled from "styled-components";

import OverlayRightPanel from "containers/OverlayRightPanel/OverlayRightPanel.container";
import { useTypedSelector } from "store";
import { useDispatch } from "react-redux";
import { productRegionOptions } from "store/reducers/products/products.types";
import {
  setAddProductPanelOpen,
  updateProductLineAction,
} from "store/reducers/products/products.actions";
import AddUserForm from "./AddUserForm";
import DeleteProductLineButton from "./DeleteProductLineButton";
import UserList from "./UserList";
import ProductLineValidateButton from "./ProductLineValidateButton.component";

export default function ProductOverview() {
  const dispatch = useDispatch();

  //State
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [region, setRegion] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  //Redux Store
  const { id: currentUserId } = useTypedSelector((state) => state.user);
  const {
    getting,
    selectedProductLine: productLine,
    showAddProductLineSidePanel,
    users,
  } = useTypedSelector((state) => state.products);

  useEffect(() => {
    if (productLine) {
      //Determine whether the current user has admin rights on the product line.
      const currentUserIsAdmin = users.users.some(
        (user) =>
          user.id === currentUserId.toString().trim() && user.role === "ADMIN"
      );
      setIsAdmin(currentUserIsAdmin);

      setName(productLine.name);
      setRegion(productLine.region);
      setDescription(productLine.description || "");
    } else {
      setIsAdmin(false);
      setName("");
      setRegion("");
      setDescription("");
    }
  }, [productLine, currentUserId, users]);

  //Handlers

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const updateName = () => {
    if (!productLine) return;

    const newName = name.trim();
    if (newName === productLine?.name) return;

    dispatch(
      updateProductLineAction(productLine.id, {
        name: newName,
      })
    );
  };

  const handleRegionChange = (event) => {
    if (!productLine) return;

    if (event.target.value === productLine?.region) return;

    dispatch(
      updateProductLineAction(productLine.id, {
        region: event.target.value,
      })
    );
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const updateDescription = () => {
    if (!productLine) return;

    const newDescription = description.trim();
    if (newDescription === productLine?.description) return;

    dispatch(
      updateProductLineAction(productLine.id, {
        description: newDescription,
      })
    );
  };

  const toggleAddUser = (status: boolean) => {
    dispatch(setAddProductPanelOpen(status));
  };

  const handleAddUserPress = () => {
    toggleAddUser(!showAddProductLineSidePanel);
  };

  if (getting) return <></>;

  return (
    <Wrapper>
      <OverlayRightPanel
        open={showAddProductLineSidePanel}
        closeNav={() => toggleAddUser(false)}
        title="Update Team Members"
      >
        <AddUserForm closeMenu={() => toggleAddUser(false)} />
      </OverlayRightPanel>
      <Grid container rowSpacing={0}>
        <Grid xs={4} display="flex" justifyContent="center">
          <StyledH4>Product Line Information</StyledH4>
        </Grid>
        <Grid xs={4} display="flex" justifyContent="center" xsOffset={1}>
          <StyledH4>Actions</StyledH4>
        </Grid>
        <Grid container xs={4} rowSpacing={0}>
          <Grid xs={12}>
            <TextField
              label="Name"
              value={name}
              id="productline-name"
              variant="outlined"
              size="medium"
              fullWidth
              margin="normal"
              onBlur={updateName}
              onChange={handleNameChange}
              disabled={!isAdmin}
            />
          </Grid>
          <Grid xs={12}>
            <TextField
              label="Region"
              value={region}
              id="productline-region"
              variant="outlined"
              size="medium"
              fullWidth
              margin="normal"
              select
              SelectProps={{
                native: true,
              }}
              onChange={handleRegionChange}
              disabled={!isAdmin}
            >
              {productRegionOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid xs={12}>
            <TextField
              label="Product Line Id"
              id="productline-id"
              value={productLine?.id}
              variant="outlined"
              size="medium"
              fullWidth
              margin="normal"
              disabled
            />
          </Grid>
          <Grid xs={12}>
            <TextField
              label="Description"
              value={description}
              id="productline-description"
              variant="outlined"
              size="medium"
              fullWidth
              multiline
              margin="normal"
              onBlur={updateDescription}
              onChange={handleDescriptionChange}
              disabled={!isAdmin}
            />
          </Grid>
        </Grid>
        <Grid container xs={4} rowSpacing={0} xsOffset={1}>
          <Grid xs={12} display="flex" justifyContent="center">
            <ProductLineValidateButton />
          </Grid>
          <Grid xs={12} display="flex" justifyContent="center">
            <DeleteProductLineButton
              productLineId={productLine?.id}
              disabled={!isAdmin}
            />
          </Grid>
        </Grid>
        <Grid xs={4} display="flex" justifyContent="center">
          <StyledH4>Team Members</StyledH4>
        </Grid>
        <Grid
          xs={4}
          xsOffset={1}
          display="flex"
          justifyContent="center"
          alignItems={"center"}
        >
          <StyledButton
            variant="outlined"
            size="small"
            color="primary"
            startIcon={<EditIcon />}
            onClick={handleAddUserPress}
          >
            Update Team Members
          </StyledButton>
        </Grid>
        <Grid xs={12}>
          <UserSection>
            <UserCardWrapper>
              <UserList />
            </UserCardWrapper>
          </UserSection>
        </Grid>
      </Grid>
    </Wrapper>
  );
}

const StyledH4 = styled(H4)`
  color: ${(props) => props.theme.colors.muttedHeadlines};
  font-weight: 700;
`;

const StyledButton = styled(Button)`
  height: 32px;
  width: 250px;
`;

const Wrapper = styled.div`
  display: flex;
  //max-width: 1400px;
  justify-content: space-between;
  padding: 0 24px 24px 24px;
`;

const UserSection = styled.div`
  flex: 1;
  padding-left: 10px;
  padding-right: 10px;
`;

const UserCardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
`;
