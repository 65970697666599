import React from "react";
import styled from "styled-components";
import { SearchInput } from "components";

export default function SearchProductLines({
  searchTermProductLine,
  handleChange,
}: {
  searchTermProductLine: any;
  handleChange: any;
  className?: any;
}) {
  return (
    <StyledSearchInput
      placeholder="Search product lines"
      onChange={handleChange}
      value={searchTermProductLine}
      autoFocus
    />
  );
}

const StyledSearchInput = styled(SearchInput)<any>`
  width: 100%;
  margin-bottom: 10px;
  > input {
    margin-top: 10px;
  }
`;
