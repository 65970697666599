import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { useTypedSelector } from "store";
import { addStandardTags } from "store/reducers/products/parameters/parameters.actions";
import ParametersTable from "../../../components/ParametersTable/ParametersTable.component";
import ConfirmActionButton from "../../../../../components/Buttons/ConfirmActionButton";
import Grid from "@mui/material/Unstable_Grid2";
import { H4 } from "components";

export default function ProductLinePVAs() {
  const dispatch = useDispatch();
  const { getting } = useTypedSelector((state) => state.products);

  if (getting) return <></>;

  const handleAddStandardTags = async () => {
    dispatch(addStandardTags());
  };

  return (
    <ParametersWrapper>
      <Grid container columnSpacing={3}>
        <Grid xs={8} display="flex" justifyContent="left">
          <ParametersTable
            header={"Tags"}
            parameterType="tags"
            addRowButtonLabel="ADD TAG"
          />
        </Grid>
        <Grid container xs={4}>
          <Grid container direction="column" rowSpacing={2}>
            <Grid display="flex" justifyContent="center">
              <StyledH4>Actions</StyledH4>
            </Grid>
            <Grid display="flex" justifyContent="center">
              <ConfirmActionButton
                variant="outlined"
                buttonTitle="Create Standard Tags"
                dialogTitle="Create Standard Tags"
                dialogText="Are you sure you want to create all standard Tags? 
                  This will also reset the description and category of any existing standard tags to their defaults."
                statusTextSuccess="All standard Tags created!"
                onClick={handleAddStandardTags}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ParametersWrapper>
  );
}

const ParametersWrapper = styled.div`
  padding: 0px 24px 0 24px;
`;

const StyledH4 = styled(H4)`
  color: ${(props) => props.theme.colors.muttedHeadlines};
  font-weight: 700;
`;
