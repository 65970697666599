// tsx extension so hex colors are displayed

import colors from "./colors";

export interface Borders {
  primaryColor: string;
  secondaryColor: string;
  width: string;
  radius: string;
}

// We repeat colors here, unused for the moment but will be happy to have it
// if border colors start to differ from main colors

const borders: Borders = {
  primaryColor: colors.tables.borders,
  secondaryColor: colors.secondary,
  width: "1px",
  radius: "4px",
};

export default borders;
