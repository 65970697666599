import { SubPropertiesState } from "./pl-subproperties.types";
import {
  SET_VERB,
  SET_NEW_SUB_PROPERTY,
  SET_PL_SUB_PROPERTIES,
  EDIT_PL_SUB_PROPERTY,
  ADD_NEW_SUBPROPERTY,
  ADD_STANDARD_SUBPROPERTY,
  REMOVE_STANDARD_SUBPROPERTY,
  EDIT_STANDARD_SUBPROPERTY,
  UPDATE_CUSTOM_SUBPROPERTY,
  REMOVE_CUSTOM_SUBPROPERTY,
  SET_NEW_PARENT_PROPERTY,
  GET_SUB_PROPERTY_VALIDATION_BY_OBJECT_ID,
  GET_STANDARD_SUB_PROPERTY_VALIDATION_BY_OBJECT_ID,
  UPDATE_STANDARD_SUB_PROPERTY,
} from "./pl-subproperties.actions";

// the users state is not a {byId: [], allIds: []} because backend only gives us an array of Ids
// matching with 'carrierUsers' would involve a race condition
// that we don't want to deal with inside reducer logic

const newSubPropertyInitialState: any = {
  name: "",
  domain: "",
  dataType: "",
};

const parentPropertyInitialState: any = {
  byId: {},
  allIds: [],
  standardById: {},
  standardAllIds: [],
};

export const plSubPropertiesInitialState: SubPropertiesState = {
  byParentPropertyId: {},
  allParentPropertyIds: [],
  newSubProperty: newSubPropertyInitialState,

  getting: false,
  posting: false,
  deleting: false,
};

export function plSubPropertiesReducer(
  state: SubPropertiesState = plSubPropertiesInitialState,
  action: any
) {
  switch (action.type) {
    case SET_PL_SUB_PROPERTIES:
      return {
        ...state,
        byParentPropertyId: {
          ...state.byParentPropertyId,
          [action.payload.parentId]: action.payload.subproperties,
        },
        allParentPropertyIds: [
          ...state.allParentPropertyIds.concat(action.payload.parentId),
        ],
      };

    case SET_VERB: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case ADD_NEW_SUBPROPERTY: {
      const { newSubProperty } = action.payload;
      const { parentId } = newSubProperty;
      return {
        ...state,
        newSubProperty: newSubPropertyInitialState,
        byParentPropertyId: {
          ...state.byParentPropertyId,
          [parentId]: {
            ...state.byParentPropertyId[parentId],
            byId: {
              ...(state.byParentPropertyId[parentId]
                ? state.byParentPropertyId[parentId].byId
                : {}),
              [newSubProperty.id]: newSubProperty,
            },
            allIds: state.byParentPropertyId[parentId]
              ? state.byParentPropertyId[parentId].allIds.concat(
                  newSubProperty.id
                )
              : [],
          },
        },
      };
    }

    case UPDATE_CUSTOM_SUBPROPERTY: {
      return {
        ...state,
        byParentPropertyId: {
          ...state.byParentPropertyId,
          [action.payload.parentId]: {
            ...state.byParentPropertyId[action.payload.parentId],
            byId: {
              ...(state.byParentPropertyId[action.payload.parentId]
                ? state.byParentPropertyId[action.payload.parentId].byId
                : {}),
              [action.payload.subproperty.id]: action.payload.subproperty,
            },
          },
        },
      };
    }

    case UPDATE_STANDARD_SUB_PROPERTY: {
      return {
        ...state,
        byParentPropertyId: {
          ...state.byParentPropertyId,
          [action.payload.parentId]: {
            ...state.byParentPropertyId[action.payload.parentId],
            standardById: {
              ...(state.byParentPropertyId[action.payload.parentId]
                ? state.byParentPropertyId[action.payload.parentId].standardById
                : {}),
              [action.payload.subproperty.id]: action.payload.subproperty,
            },
          },
        },
      };
    }

    case EDIT_STANDARD_SUBPROPERTY:
    case ADD_STANDARD_SUBPROPERTY:
    case REMOVE_STANDARD_SUBPROPERTY: {
      const { newById, newAllIds, parentId } = action.payload;
      return {
        ...state,
        byParentPropertyId: {
          ...state.byParentPropertyId,
          [parentId]: {
            ...state.byParentPropertyId[parentId],
            standardById: newById,
            standardAllIds: newAllIds,
          },
        },
      };
    }

    case SET_NEW_SUB_PROPERTY: {
      return {
        ...state,
        newSubProperty: action.payload,
      };
    }

    case SET_NEW_PARENT_PROPERTY: {
      return {
        ...state,
        byParentPropertyId: {
          ...state.byParentPropertyId,
          [action.payload.parentId]: parentPropertyInitialState,
        },
        allParentPropertyIds: [
          ...state.allParentPropertyIds.concat(action.payload.parentId),
        ],
      };
    }

    case EDIT_PL_SUB_PROPERTY: {
      return {
        ...state,
        byParentPropertyId: {
          ...state.byParentPropertyId,
          [action.payload.parentId]: {
            ...(state.byParentPropertyId[action.payload.parentId]
              ? state.byParentPropertyId[action.payload.parentId]
              : {}),
            byId: {
              ...(state.byParentPropertyId[action.payload.parentId]
                ? state.byParentPropertyId[action.payload.parentId].byId
                : {}),
              [action.payload.subproperty.id]: action.payload.subproperty,
            },
            allIds: [
              ...(state.byParentPropertyId[action.payload.parentId]
                ? state.byParentPropertyId[action.payload.parentId].allIds
                : ""),
            ],
          },
        },
      };
    }

    case GET_SUB_PROPERTY_VALIDATION_BY_OBJECT_ID: {
      return {
        ...state,
        byParentPropertyId: {
          ...state.byParentPropertyId,
          [action.payload.parentId]: {
            ...state.byParentPropertyId[action.payload.parentId],
            byId: {
              ...state.byParentPropertyId[action.payload.parentId].byId,
              [action.payload.objectId]: {
                ...state.byParentPropertyId[action.payload.parentId].byId[
                  action.payload.objectId
                ],
                validations: action.payload.validations,
              },
            },
          },
        },
      };
    }

    case GET_STANDARD_SUB_PROPERTY_VALIDATION_BY_OBJECT_ID: {
      return {
        ...state,
        byParentPropertyId: {
          ...state.byParentPropertyId,
          [action.payload.parentId]: {
            ...state.byParentPropertyId[action.payload.parentId],
            standardById: {
              ...state.byParentPropertyId[action.payload.parentId].standardById,
              [action.payload.objectId]: {
                ...state.byParentPropertyId[action.payload.parentId]
                  .standardById[action.payload.objectId],
                validations: action.payload.validations,
              },
            },
          },
        },
      };
    }

    case REMOVE_CUSTOM_SUBPROPERTY: {
      return {
        ...state,
        byParentPropertyId: {
          ...state.byParentPropertyId,
          [action.payload.parentId]: {
            ...state.byParentPropertyId[action.payload.parentId],
            byId: action.payload.newById,
            allIds: action.payload.newAllIds,
          },
        },
      };
    }

    default:
      return state;
  }
}
