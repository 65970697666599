import { Button, Table } from "components";
import React, { useState } from "react";
import styled from "styled-components";
import OverlayRightPanel from "containers/OverlayRightPanel/OverlayRightPanel.container";
import { useTypedSelector } from "store";
import Checkbox from "components/Forms/Checkbox.component";
import colors from "theme/colors";
import { useDispatch } from "react-redux";
import {
  addPvaToPropertyAction,
  removePvaFromPropertyAction,
} from "store/reducers/products/properties/pl-properties.actions";
import { useParentPropertyContext } from "../../../pages/ProductDetail/ProductLineProperties/ParentPropertyContext";
import {
  addPvaToSubPropertyAction,
  removePvaFromSubPropertyAction,
} from "store/reducers/products/subproperties/pl-subproperties.actions";
import { Property } from "../../../../../store/reducers/products/properties/pl-properties.types";

const tableHeaderStyle = { color: colors.muttedHeadlines, height: "40px" };
const tableRowStyle = { height: "40px" };

export default function ManagePVA({
  property,
  type,
}: {
  property: Property;
  type: string;
}) {
  const { parentId } = useParentPropertyContext();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { parameters } = useTypedSelector((state) => state.products);
  const { propertyValueAttributes } = parameters;
  if (!property) return <></>;
  const { propertyValueAttributes: selectedPVA } = property;

  if (!selectedPVA) return <></>;
  const selectedPVAIds = selectedPVA.map((pva) => pva.id);
  const tableRows = propertyValueAttributes.map((pva) => {
    const checked = selectedPVAIds.includes(pva.id);

    const handlePvaSelect = () => {
      if (!property.id) return;
      if (type === "main") {
        if (checked) {
          dispatch(removePvaFromPropertyAction(property.id, pva, property));
          return;
        }
        dispatch(addPvaToPropertyAction(property.id, pva, property));
        return;
      }
      if (checked) {
        dispatch(
          removePvaFromSubPropertyAction(
            property.id || "",
            pva,
            property,
            parentId
          )
        );
        return;
      }
      dispatch(
        addPvaToSubPropertyAction(property.id || "", pva, property, parentId)
      );
    };

    return [
      {
        content: <div style={{ width: 160 }}>{pva.name}</div>,
        tableRowStyle,
        id: "pvaName" + pva.id,
      },
      {
        content: (
          <Checkbox
            disabled={pva.name === "Value"}
            checked={checked}
            onClick={handlePvaSelect}
            key={"checkBox" + pva.id}
          />
        ),
        tableRowStyle,
        id: "checkBox" + pva.id,
      },
    ];
  });

  const tableData = {
    header: [
      { content: "Name", style: tableHeaderStyle, id: "tableHeaderRow1" },
      { content: "Select", style: tableHeaderStyle, id: "tableHeaderRow2" },
    ],
    rows: tableRows,
  };
  return (
    <>
      <StyledButton type="secondary" onClick={() => setOpen(true)}>
        Manage PVA
      </StyledButton>
      <OverlayRightPanel
        title="Manage PVA"
        closeNav={() => setOpen(false)}
        open={open}
      >
        <OverlayWrapper>
          <Table data={tableData} />
        </OverlayWrapper>
      </OverlayRightPanel>
    </>
  );
}

const StyledButton = styled(Button)`
  width: 200px;
  margin-top: 24px;
`;

const OverlayWrapper = styled.div`
  padding: 16px;
  display: flex;
  justify-content: center;
`;
