import React, { useState } from "react";
import ActionBar from "./components/ActionBar.component";
import ListView from "./components/ListView.component";
import GridView from "./components/GridView.component";
import styled from "styled-components";
import { useTypedSelector } from "store";

export default function ProgramsPage() {
  const programs = useTypedSelector((state) => state.programs.programs);

  const { navigationOpen } = useTypedSelector(
    (state) => state.user.preferences
  );

  const [activeTab, setTab] = useState("List");
  return (
    <MainBox navigationOpen={navigationOpen}>
      <ActionBar setTab={setTab} activeTab={activeTab} />
      {activeTab === "List" && <ListView mockedPrograms={programs} />}
      {activeTab === "Grid" && <GridView mockedPrograms={programs} />}
    </MainBox>
  );
}

const MainBox = styled.div<any>`
  padding-left: 10px;
  padding-top: 20px;

  @media ${(props) => props.theme.device.mobile} {
    padding-left: ${(props) => (props.navigationOpen ? "50px" : "10px")};
  }
`;
