import React from "react";
import styled from "styled-components";
import { Link } from "components";
import { useRouteMatch } from "react-router-dom";

export default function MenuItem(props: any) {
  const { label, page, icon, disabled } = props.item;
  const { hide } = props;
  const match = useRouteMatch(page);

  return (
    <LinkWrapper
      to={page}
      onClick={(e) => disabled && e.preventDefault()}
      iscurrent={match}
      disabled={disabled}
    >
      <Icon>{icon}</Icon>
      <Text hide={hide}>{label}</Text>
    </LinkWrapper>
  );
}

const LinkWrapper = styled(Link)`
  display: flex;
  padding: 16px;
  font-weight: bold;
  background-color: ${(props) =>
    props.iscurrent
      ? props.theme.colors.darkBlue
      : props.theme.colors.secondary};
  color: white !important;
  text-align: center;

  @media ${(props) => props.theme.device.mobile} {
    text-align: left;
  }
`;

const Text = styled.span<any>`
  display: none;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media ${(props) => props.theme.device.mobile} {
    display: ${(props) => (props.hide ? "none" : "block")};
  }
`;

const Icon = styled.span`
  display: block;
  margin: auto;

  @media ${(props) => props.theme.device.mobile} {
    display: block;
    margin: 0 16px 0 0;
  }
`;
