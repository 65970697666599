import React, { useEffect, useState } from "react";
import { Button } from "components";
import OverlayRightPanel from "containers/OverlayRightPanel/OverlayRightPanel.container";
import styled from "styled-components";
import { useTypedSelector } from "store";
import { useDispatch } from "react-redux";
import {
  addRuleTableToProductLineAction,
  setNewRulesTable,
} from "store/reducers/products/rulestables/pl-rulestables.actions";
import { tablesTypes } from "store/reducers/products/rulestables/pl-rulestables.types";
import SelectDropdownComponent, {
  getSingleValueFromOptions,
  getSelectedValueFromOptions,
  stringArrayToOptions,
} from "../../../../../components/Forms/SelectDropdown/SelectDropdown.component";

export default function AddNewTableButton() {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const { ruleSets } =
    useTypedSelector((state) => state.products?.parameters) || [];
  const { newRulesTable, posting } = useTypedSelector(
    (state) => state.products?.rulestables
  );

  const rulesetOptions = ruleSets.map((ruleset) => ({
    value: ruleset.id,
    label: ruleset.name,
  }));

  const { TableType, RulesetIds } = useTypedSelector(
    (state) => state.products.rulestables.newRulesTable
  );

  useEffect(() => {
    if (ruleSets?.length === 1 && !RulesetIds.length) {
      dispatch(
        setNewRulesTable({ ...newRulesTable, RulesetIds: [ruleSets[0].id] })
      );
    }
  }, [RulesetIds, ruleSets]);

  const isValidRuleTable = TableType && RulesetIds.length;

  const handleChange = (selectedOption, category, multi) => {
    const newValue = getSelectedValueFromOptions(multi, selectedOption);
    dispatch(setNewRulesTable({ ...newRulesTable, [category]: newValue }));
  };

  const handleSubmit = () => {
    dispatch(addRuleTableToProductLineAction(newRulesTable));
    setOpen(false);
  };

  return (
    <>
      <OverlayRightPanel
        open={open}
        closeNav={() => setOpen(false)}
        title="New Table"
      >
        {open && (
          <AddPropertyContent>
            <StyledSelectDropdownComponent
              placeholder="Table type"
              name="TableType"
              options={stringArrayToOptions(tablesTypes)}
              isMulti={false}
              handleChange={(e) => handleChange(e, "TableType", false)}
              value={getSingleValueFromOptions(tablesTypes, TableType)}
            />
            <StyledSelectDropdownComponent
              placeholder="Rulesets"
              name="RulesetIds"
              options={rulesetOptions}
              isMulti={true}
              closeMenuOnSelect={false}
              handleChange={(e) => handleChange(e, "RulesetIds", true)}
              value={rulesetOptions.filter(({ value }) =>
                RulesetIds.includes(value)
              )}
            />

            <StyledAddProductLineButton
              onClick={handleSubmit}
              buttonType="submit"
              loading={posting}
              disabled={!isValidRuleTable}
            >
              Save
            </StyledAddProductLineButton>
          </AddPropertyContent>
        )}
      </OverlayRightPanel>
      <CustomButton size="small" type="primary" onClick={() => setOpen(true)}>
        Add Table
      </CustomButton>
    </>
  );
}

const AddPropertyContent = styled.div`
  padding: 0 24px 24px 24px;
`;

const StyledSelectDropdownComponent = styled(SelectDropdownComponent)<any>`
  margin-bottom: 32px;
`;

const StyledAddProductLineButton = styled(Button)`
  width: 100%;

  /* for smooth transition */
  white-space: nowrap;
`;

const CustomButton = styled(Button)`
  width: 200px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
