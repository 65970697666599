import React from "react";
import styled from "styled-components";
import { CardPrograms } from "components/Cards/CardPrograms.component";
import TitleBar from "./TitleBar.component";
import { AddNewProgram } from "components/Cards/AddNewProgram.component";
import { convertTimestampToDays } from "helpers/datesConverter";
import { useHistory } from "react-router-dom";

export default function GridView({ mockedPrograms }: { mockedPrograms?: any }) {
  const history = useHistory();
  const displayElements = () => {
    if (mockedPrograms.length !== 0) {
      return mockedPrograms.map((el, index) => {
        const daysLeft = convertTimestampToDays(el.daysLeft);
        return (
          <div
            onClick={() => history.push(`programs/${el.id}/overview`)}
            key={index}
          >
            <ProgramBox
              completion={el.completion}
              name={el.name}
              status={el.status}
              teamMembers={el.teamMembers}
              daysLeft={daysLeft}
              region={el.region}
              type={el.type}
              tasksRemaining={el.tasksRemaining}
            />
          </div>
        );
      });
    }
  };
  return (
    <>
      <TitleBar title={"Unsorted Programs"} amount={mockedPrograms.length} />
      <GridBox>
        <CustomAddNewProgram>
          <AddNewProgram />
        </CustomAddNewProgram>
        {displayElements()}
      </GridBox>
    </>
  );
}

const GridBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding-left: 24px;
  justify-content: flex-start;
`;

const ProgramBox = styled(CardPrograms)`
  margin-top: 24px;
  margin-right: 24px;
`;

const CustomAddNewProgram = styled.div`
  margin-top: 24px;
  @media ${(props) => props.theme.device.tablet} {
    margin-right: 24px;
  }
  max-width: 100%;
`;
