import {
  UM_APP_NAME_DEV,
  UM_APP_NAME_PROD,
  URL_API_DEV,
  URL_API_PROD,
} from "./constants";

const getBaseUrl = () => {
  const hostname = window.location.hostname;

  let apiBaseURL = "";

  //production
  if (hostname.includes("prod")) apiBaseURL = URL_API_PROD;

  //development
  if (!apiBaseURL) {
    if (hostname.includes("dev")) apiBaseURL = URL_API_DEV;
  }

  //If we are running the UI locally, we are assuming that we are also running the api
  //locally for development purposes.  So we return just the "/" which will cause the
  //application to look at the package.json proxy setting for the actual localhost URL
  //for the api.
  if (!apiBaseURL) {
    if (hostname.includes("localhost")) apiBaseURL = "/";
  }

  return apiBaseURL;
};

const getAppName = () => {
  const hostname = window.location.hostname;

  let appName = "";

  //Determine the User Management application name.  This will be used
  //to request the user permissions for the application from User Mamangement API
  //NOTE: This name must exactly match the name the application is registered under
  //in the User Management application.

  //production
  if (hostname.includes("prod")) appName = UM_APP_NAME_PROD;

  //development
  if (!appName) {
    if (hostname.includes("dev")) appName = UM_APP_NAME_DEV;
  }

  //running locally
  if (!appName) {
    if (hostname.includes("localhost")) appName = UM_APP_NAME_DEV;
  }

  return appName;
};

export const appName = getAppName();
export const baseUrl = getBaseUrl();
