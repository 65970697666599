import React from "react";
import colors from "theme/colors";

export function ClockIcon({ color = colors.secondary }: { color?: string }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 6C8.7 6 6 8.7 6 12C6 15.3 8.7 18 12 18C15.3 18 18 15.3 18 12C18 8.7 15.3 6 12 6ZM15 13H11V9H12.6V11.4H15V13Z"
        fill={color}
        strokeWidth="0px"
      />
    </svg>
  );
}
