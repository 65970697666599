import React from "react";
import { DataTypeEnum } from "store/reducers/products/properties/pl-properties.types";
import { HoveredInputCell } from "./HoveredInputCell.component";
import colors from "theme/colors";
import styled from "styled-components";
import { toast } from "react-toastify";
import { secureTrim } from "helpers/string";
import SelectDropdownComponent, {
  getSingleValueFromOptions,
  stringArrayToOptions,
} from "../Forms/SelectDropdown/SelectDropdown.component";

const validationRegex = {
  // TODO: 2 edge cases that we need to address in the actions:
  // - empty
  // - for floats trailing dot (ex: 312., 53.)

  [DataTypeEnum.FLOAT]: /^$|^[+-]?([0-9]+([.][0-9]*)?)?$/,
  [DataTypeEnum.STRING]: /^$|^(?!\s*$).+/,
  [DataTypeEnum.INT]: /^$|^[+-]?[0-9]*$/,
};

const booleanOptions = ["TRUE", "FALSE"];

export function TypeConstrainedCell({
  dataType = DataTypeEnum.STRING,
  type = "editCell",
  handleSubmit = () =>
    console.error("Missing handleSubmit in <TypeConstrainedCell />"),
  value = "",
  multiple = false,
  background = "",
}: {
  dataType?: DataTypeEnum;
  type: "editCell" | "newCell";
  handleSubmit: any;
  value?: any;
  id?: any;
  multiple?: boolean;
  background?: string;
}) {
  // formats exactly like the backend expect
  const parsingValues = (dataType, rawValue?) => {
    const value = secureTrim(rawValue);
    if (dataType === DataTypeEnum.BOOL) {
      // the backend takes boolean then returns a string
      const newValue = rawValue.value;
      handleSubmit(newValue);
      return;
    }

    if (dataType === DataTypeEnum.STRING) {
      handleSubmit(value);
      return;
    }

    if (dataType === DataTypeEnum.FLOAT) {
      const float = parseFloat(value);
      if (isNaN(float) && value.length) {
        toast.error("Number is not a float");
        return;
      }
      handleSubmit(value);
      return;
    }

    if (dataType === DataTypeEnum.INT) {
      const int = parseInt(value);
      if (isNaN(int) && value.length) {
        toast.error("Number is not an integer");
        return;
      }
      handleSubmit(value);
      return;
    }
  };

  const isNewCell = type === "newCell";
  if (dataType === DataTypeEnum.BOOL) {
    return (
      <StyledSelectDropdownComponent
        options={stringArrayToOptions(booleanOptions)}
        name="booleanCell"
        isSearchable={false}
        noBorder={true}
        isMulti={multiple}
        height="40px"
        backgroundColor={
          isNewCell || background === "white"
            ? "white"
            : colors.tables.hoveredCell
        }
        value={getSingleValueFromOptions(booleanOptions, value.toUpperCase())}
        handleChange={(val) => parsingValues(dataType, val)}
      />
    );
  }

  return (
    <HoveredInputCell
      initialContent={isNewCell ? "" : value}
      isNewCell={isNewCell}
      handleSubmit={(val) => parsingValues(dataType, val)}
      rows={1}
      validationRegex={validationRegex[dataType]}
      backgroundColor={
        isNewCell || background === "white"
          ? "white"
          : colors.tables.hoveredCell
      }
      width="100%"
    />
  );
}

const StyledSelectDropdownComponent = styled(SelectDropdownComponent)<any>`
  min-width: 100px;
  //max-width: 140px;
`;
