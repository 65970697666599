import React from "react";
import styled from "styled-components";
import SelectSearch from "react-select-search";
import fuzzySearch from "./fuzzySearch";

// you have examples here: https://react-select-search.com/
// The repo itself is also very helpful: https://github.com/tbleckert/react-select-search

export function SearchableDropdown({
  options = [],
  getOptions,
  placeholder,
  name,
  className,
  noShadow = false,
  noHoverBorder = false,
  value,
  handleChange,
  height,
  customFontSize,
  search = true,
  multiple = false,
  style = {},
  id,
  backgroundColor = "white",
  onBlur,
  tabIndex,
  disabled = false,
}: {
  name: string;
  getOptions?: any;
  options?: Array<any>;
  placeholder?: string;
  className?: any;
  noShadow?: boolean;
  noHoverBorder?: boolean;
  value?: any;
  handleChange?: any;
  height?: string;
  customFontSize?: string;
  search?: boolean;
  multiple?: boolean;
  style?: any;
  id?: any;
  backgroundColor?: string;
  onBlur?: any;
  tabIndex?: number;
  disabled?: boolean;
}) {
  return (
    <Wrapper
      noShadow={noShadow}
      noHoverBorder={noHoverBorder}
      height={height}
      className={className}
      style={style}
      id={id}
      backgroundColor={backgroundColor}
      tabIndex={tabIndex}
    >
      <SelectSearch
        options={options}
        getOptions={getOptions}
        closeOnSelect={multiple ? false : true}
        // @ts-ignore
        debounce={1000}
        name={name}
        placeholder={placeholder}
        search={search}
        filterOptions={fuzzySearch}
        printOptions={multiple ? "on-focus" : "auto"}
        onChange={handleChange}
        value={value}
        fontSize={customFontSize}
        multiple={multiple}
        onBlur={onBlur}
        disabled={disabled}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div<any>`
  min-width: 150px;
  /**
 * Main wrapper
 */
  .select-search {
    /* TODO: this fixed width may cause problem for long values, find an alternative */
    width: 100%;
    max-width: 400px;
    position: relative;
    box-sizing: border-box;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
  }

  .select-search *,
  .select-search *::after,
  .select-search *::before {
    box-sizing: inherit;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
  }

  /**
 * Value wrapper
 */
  .select-search__value {
    position: relative;
  }

  .select-search__value::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: calc(50% - 6px);
    right: 10px;
    width: 7px;
    height: 7px;
  }

  /**
 * Input
 */
  .select-search__input {
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    display: block;
    height: ${(props) => (props.height ? props.height : "16px")};
    width: 100%;
    padding: ${(props) => (props.noHoverBorder ? 0 : "0px 16px")};
    padding-right: 33px;

    background: ${(props) => props.backgroundColor};
    border: 1px solid transparent;
    box-shadow: ${(props) =>
      props.noShadow ? "none" : "0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15)"};
    border-radius: 3px;
    outline: none;
    font-size: ${(props) => props.fontSize || "14px"};
    text-align: left;
    text-overflow: ellipsis;
    line-height: 36px;
    -webkit-appearance: none;
    box-sizing: border-box;
    min-height: 14px;
  }

  .select-search__input::-webkit-search-decoration,
  .select-search__input::-webkit-search-cancel-button,
  .select-search__input::-webkit-search-results-button,
  .select-search__input::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  .select-search__input:not([readonly]):focus {
    cursor: initial;
  }

  /**
 * Options wrapper
 */
  .select-search__select {
    background: #fff;
    box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
  }

  /**
 * Options
 */
  .select-search__options {
    list-style: none;
    padding: 0;
    list-style-type: none;
  }

  /**
 * Option row
 */
  .select-search__row:not(:first-child) {
    border-top: 1px solid #eee;
  }

  /**
 * Option
 */
  .select-search__option,
  .select-search__not-found {
    display: block;
    height: 36px;
    width: 100%;
    padding: 0 16px;
    background: #fff;
    border: none;
    outline: none;
    font-size: ${(props) => props.fontSize || "14px"};
    text-align: left;
    cursor: pointer;
  }

  .select-search--multiple .select-search__option {
    height: 48px;
  }

  .select-search__option.is-selected {
    background: ${(props) => props.theme.colors.secondary};
    color: #fff;
  }

  .select-search__option.is-highlighted,
  .select-search__option:not(.is-selected):hover {
    background: ${(props) => props.theme.colors.background.primaryLabels};
  }

  .select-search__option.is-highlighted.is-selected,
  .select-search__option.is-selected:hover {
    background: ${(props) => props.theme.colors.secondary};
    color: #fff;
  }

  /**
 * Group
 */
  .select-search__group-header {
    font-size: ${(props) => props.fontSize || "14px"};
    text-transform: uppercase;
    background: #eee;
    padding: 8px 16px;
  }

  /**
 * States
 */
  .select-search.is-disabled {
    opacity: 0.5;
  }

  .select-search.is-loading .select-search__value::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
    background-size: 11px;
  }

  .select-search:not(.is-disabled) .select-search__input {
    cursor: pointer;
  }

  /**
 * Modifiers
 */
  .select-search--multiple {
    border-radius: 3px;
    overflow: hidden;
  }

  .select-search:not(.is-loading):not(.select-search--multiple)
    .select-search__value::after {
    transform: rotate(45deg);
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    pointer-events: none;
  }

  .select-search--multiple .select-search__input {
    cursor: initial;
  }

  .select-search--multiple .select-search__input {
    border-radius: 3px 3px 0 0;
  }

  .select-search--multiple:not(.select-search--search) .select-search__input {
    cursor: default;
  }

  .select-search:not(.select-search--multiple) .select-search__input:hover {
    border-color: ${(props) =>
      props.noHoverBorder ? "none" : props.theme.colors.secondary};
  }

  .select-search:not(.select-search--multiple) .select-search__select {
    position: absolute;
    z-index: 2;
    top: 44px;
    right: 0;
    left: 0;
    border-radius: 3px;
    overflow: auto;
    max-height: 360px;
  }

  .select-search--multiple .select-search__select {
    position: relative;
    overflow: auto;
    max-height: 260px;
    border-top: 1px solid #eee;
    border-radius: 0 0 3px 3px;
  }

  .select-search__not-found {
    height: auto;
    padding: 16px;
    text-align: center;
    color: #888;
  }
`;
