//"use client";

import { LicenseInfo } from "@mui/x-license-pro";

LicenseInfo.setLicenseKey(
  "9d91907c9c0bc2782084d23f19b20333Tz04NTg5NyxFPTE3NDEzNjczODgwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

export default function MuiXLicense() {
  //console.log("Rendering MuiXLicense");
  return null;
}
