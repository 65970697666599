import { api } from "../../../../api/api";
import { setRestVerb } from "../../generalActions";
import {
  newPropertyPriorityList,
  Property,
  PropertyPrioritiesById,
} from "./pl-propertyPriorities.types";

export const SET_PROPERTY_PRIORITIES_LIST =
  "products/plPropertyPriorities/SET_PROPERTY_PRIORITIES_LIST";
export const ADD_NEW_PROPERTY_PRIORITIES_LIST =
  "products/plPropertyPriorities/ADD_NEW_PROPERTY_PRIORITIES_LIST";
export const CLEAR_PROPERTY_PRIORITY_LIST =
  "products/plPropertyPriorities/CLEAR_PROPERTY_PRIORITY_LIST";
export const REMOVE_PROPERTY_PRIORITIES_LIST =
  "products/plPropertyPriorities/REMOVE_PROPERTY_PRIORITIES_LIST";
export const ADD_PROPERTIES_TO_PROPERTIES_PRIORITY_LIST =
  "products/plPropertyPriorities/ADD_PROPERTIES_TO_PROPERTIES_PRIORITY_LIST";
export const EDIT_PROPERTY_IN_PRIORITY_LIST =
  "products/plPropertyPriorities/EDIT_PROPERTY_IN_PRIORITY_LIST";
export const REMOVE_PROPERTY_IN_PRIORITY_LIST =
  "products/plPropertyPriorities/REMOVE_PROPERTY_IN_PRIORITY_LIST";
export const EDIT_PROPERTY_PRIORITY_LIST =
  "products/plPropertyPriorities/EDIT_PROPERTY_PRIORITY_LIST";

export const getPropertyPrioritiesList = () => {
  return async (dispatch, getState) => {
    dispatch(setRestVerb("products/PropertyPriorities", true, "getting"));
    const productLineId = getState().products.selectedProductLine.id;

    try {
      const { data } = await api.getPropertyPrioritiesList(productLineId);

      const newById: PropertyPrioritiesById = {};
      const newAllIds: Array<string> = [];

      data.map((el) => {
        newAllIds.push(el.id);
        newById[el.id] = { ...el };
      });

      dispatch({
        type: SET_PROPERTY_PRIORITIES_LIST,
        payload: { newById, newAllIds },
      });
    } catch (e) {
      console.error(e, "error while getting property priorities list");
    }
    dispatch(setRestVerb("products/PropertyPriorities", true, "getting"));
  };
};

export const addPropertyPriorityList = ({
  propertyPriorityListObject,
}: {
  propertyPriorityListObject: newPropertyPriorityList;
}) => {
  return async (dispatch, getState) => {
    dispatch(setRestVerb("products/PropertyPriorities", true, "posting"));

    const productLineId = getState().products.selectedProductLine.id;

    try {
      const { data } = await api.addPropertyPrioritiesList(
        productLineId,
        propertyPriorityListObject
      );

      dispatch({ type: ADD_NEW_PROPERTY_PRIORITIES_LIST, payload: data });
    } catch (e) {
      console.error(e, "error while createing new property priority list");
    }
    dispatch(setRestVerb("products/PropertyPriorities", false, "posting"));
  };
};

export const removePropertyPrioritiesList = ({
  propertyPrioritiesListId,
}: {
  propertyPrioritiesListId: string;
}) => {
  return async (dispatch, getState) => {
    dispatch(setRestVerb("products/PropertyPriorities", true, "deleting"));

    const productLineId = getState().products.selectedProductLine.id;
    const { byId, allIds } = getState().products.propertyPriorities;

    try {
      await api.removePropertyPrioritiesList(
        productLineId,
        propertyPrioritiesListId
      );

      const newAllIds = allIds.filter(
        (el) => el.id !== propertyPrioritiesListId
      );
      const newById = {};
      Object.keys(byId).forEach((id) => {
        if (id !== propertyPrioritiesListId) newById[id] = byId[id];
      });

      dispatch({
        type: REMOVE_PROPERTY_PRIORITIES_LIST,
        payload: { newAllIds, newById },
      });
    } catch (e) {
      console.error(e, "error while deleting property priority list");
    }
    dispatch(setRestVerb("products/PropertyPriorities", false, "deleting"));
  };
};

export const addPropertiesToPropertyPriorityList = ({
  propertyPrioritiesId,
  propertyIds,
}: {
  propertyPrioritiesId: string;
  propertyIds: string[];
}) => {
  return async (dispatch, getState) => {
    dispatch(setRestVerb("products/PropertyPriorities", true, "posting"));

    const productLineId = getState().products.selectedProductLine.id;

    const properties = propertyIds.map((property) => {
      return { propertyId: property, fixed: false };
    });

    try {
      const { data } = await api.addPropertiesToPropertyPriorityList(
        productLineId,
        propertyPrioritiesId,
        properties
      );

      dispatch({
        type: ADD_PROPERTIES_TO_PROPERTIES_PRIORITY_LIST,
        payload: data,
      });
    } catch (e) {
      console.error(
        e,
        "error while adding properties to property priority list"
      );
    }
    dispatch(setRestVerb("products/PropertyPriorities", false, "posting"));
  };
};

export const editPropertyInPriorityList = ({
  propertyPrioritiesId,
  editPropertyObject,
}: {
  propertyPrioritiesId: string;
  editPropertyObject: Partial<Property>;
}) => {
  return async (dispatch, getState) => {
    dispatch(setRestVerb("products/PropertyPriorities", true, "posting"));

    const productLineId = getState().products.selectedProductLine.id;

    try {
      const { data } = await api.editPropertyInPriorityList(
        productLineId,
        propertyPrioritiesId,
        editPropertyObject
      );

      dispatch({ type: EDIT_PROPERTY_IN_PRIORITY_LIST, payload: data });
    } catch (e) {
      console.error(e, "error while editing property in priority list");
    }
    dispatch(setRestVerb("products/PropertyPriorities", true, "posting"));
  };
};

export const editPropertyPriorityList = ({
  propertyPrioritiesId,
  newPropertyList,
}: {
  propertyPrioritiesId: string;
  newPropertyList: any;
}) => {
  return async (dispatch, getState) => {
    dispatch(setRestVerb("products/PropertyPriorities", true, "posting"));

    const { id: productLineId } = getState().products.selectedProductLine;
    const {
      properties: prevPropertiesList,
    } = getState().products.propertyPriorities.byId[propertyPrioritiesId];

    try {
      dispatch({
        type: EDIT_PROPERTY_PRIORITY_LIST,
        payload: {
          id: propertyPrioritiesId,
          propertyList: newPropertyList.properties,
        },
      });

      await api.editPropertyPriorityList(
        productLineId,
        propertyPrioritiesId,
        newPropertyList
      );
    } catch (e) {
      dispatch({
        type: EDIT_PROPERTY_PRIORITY_LIST,
        payload: { id: propertyPrioritiesId, propertyList: prevPropertiesList },
      });

      console.error(
        e,
        "error while editing property list in property priority"
      );
    }
    dispatch(setRestVerb("products/PropertyPriorities", false, "posting"));
  };
};

export const removePropertyFromPriorityList = ({
  propertyPrioritiesId,
  propertyIds,
}: {
  propertyPrioritiesId: string;
  propertyIds: string[];
}) => {
  return async (dispatch, getState) => {
    dispatch(setRestVerb("products/PropertyPriorities", true, "deleting"));
    const { id: productLineId } = getState().products.selectedProductLine;

    try {
      const { data } = await api.removePropertyInPriorityList(
        productLineId,
        propertyPrioritiesId,
        propertyIds
      );

      dispatch({ type: REMOVE_PROPERTY_IN_PRIORITY_LIST, payload: data });
    } catch (e) {
      console.error(e, "error while removing property from priority list");
    }
    dispatch(setRestVerb("products/PropertyPriorities", false, "deleting"));
  };
};

export const clearPropertyPrioritiesList = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_PROPERTY_PRIORITY_LIST });
  };
};
