export const metadataJsonExample = {
  keyValuePairs: {},
  innerRulesets: {
    "19DV": {
      keyValuePairs: {
        PartNumberVariable: "PN_sModel",
      },
    },
    "19MV": {
      keyValuePairs: {
        PartNumberVariable: "PN_sModel",
      },
    },
    "19XR": {
      keyValuePairs: {
        PartNumberVariable: "PN_sModel",
      },
    },
    "23XR": {
      keyValuePairs: {
        PartNumberVariable: "PN_sModel",
      },
    },
  },
};
