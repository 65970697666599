import React from "react";
import styled from "styled-components";

import { Link } from "components";
import { useParams, useRouteMatch } from "react-router-dom";

export default function MenuItem({
  path,
  children,
}: {
  path: string;
  children: any;
}) {
  const { productLineId } = useParams();
  const { path: rootPath } = useRouteMatch();
  const match = useRouteMatch(`${rootPath}/${path}`);

  return (
    <StyledMenuItem
      iscurrent={match}
      to={`/products/${productLineId}/${path}`}
      color="white"
    >
      {children}
    </StyledMenuItem>
  );
}

const StyledMenuItem = styled(Link)`
  border-bottom: 1px solid ${(props) => props.theme.colors.tables.borders};
  font-size: 14px;
  height: 50px;
  display: flex;
  justify-content: baseline;
  align-items: center;
  text-align: left;
  padding-left: 40px;
  cursor: pointer;
  background-color: ${(props) =>
    props.iscurrent ? props.theme.colors.secondary : props.theme.colors.white};

  /* !important because need to override color in <Link /> re-usable component */
  color: ${(props) =>
    props.iscurrent
      ? props.theme.colors.white
      : props.theme.colors.primary} !important;
  font-weight: normal;
  transition: 0.4s;

  :hover {
    background-color: ${(props) => props.theme.colors.secondary};
    opacity: 0.8;
    color: white !important;
  }
`;
