import React from "react";
import styled from "styled-components";
import { HoverTrashRowIcon } from "./HoverTrashRowIcon.component";

export type TableRow = {
  content: any;
  style?: any;
  id?: string;
  padding?: string;
};

export function TableRow({
  row,
  type = "row",
  rowDeleteAction,
  innerRef,
  draggableProps,
  dragHandleProps,
  isDragging,
}: {
  row: TableRow[];
  type?: "addRow" | "row";
  rowDeleteAction?: any;
  innerRef?: any;
  draggableProps?: any;
  dragHandleProps?: any;
  isDragging?: boolean;
}) {
  return (
    <>
      {rowDeleteAction && (
        <HoverTrashRowIcon deleteAction={() => rowDeleteAction(row)} />
      )}
      {type === "addRow" && <TrSpacer />}
      <Tr
        ref={innerRef}
        {...draggableProps}
        {...dragHandleProps}
        isDragging={isDragging}
      >
        {row &&
          Array.isArray(row) &&
          row.map((props: any, index) => {
            const { id, content, style } = props;

            let uniqueId: any = "";

            if (typeof content === "string") {
              uniqueId = id || content || index.toString();
            } else {
              uniqueId = content?.props?.id || id;
            }

            return (
              <Cell type={type} key={uniqueId} style={style}>
                {content}
              </Cell>
            );
          })}
      </Tr>
    </>
  );
}

// this is only to accomodate specific conditional style, the TableCell style is in ./Table.component.tsx
const Cell = styled.td<any>`
  background-color: ${(props) =>
    props.type === "addRow" ? "white" : props.theme.colors.tables.hoveredCell};
`;

const Tr = styled.tr<any>`
  border-left: ${(props) =>
    props.isDragging
      ? `5px solid ${props.theme.colors.projectPaused}`
      : "none"};
`;
const TrSpacer = styled.tr<any>`
  background-color: transparent;
  height: 20px;
`;
