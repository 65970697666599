import React from "react";
import {
  setNewTag,
  changeTagAction,
} from "store/reducers/products/parameters/parameters.actions";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "store";
import { HoveredInputCell, SearchableDropdown } from "components";
import { secureTrim } from "helpers/string";
import colors from "theme/colors";
import {
  TagCategoryEnum,
  TagCategoryOptions,
} from "store/reducers/products/properties/pl-properties.types";
import { TagKeys } from "store/reducers/products/parameters/pl-parameters.types";

export function SetNewTagInput({
  type = TagKeys.NAME,
  id,
}: {
  type: TagKeys;
  id?: string;
}) {
  const dispatch = useDispatch();
  const newTag = useTypedSelector((state) => state.products.parameters.newTag);

  const handleInputKeyChange = (event) => {
    dispatch(setNewTag(type, event.target.value));
  };

  return (
    <StyledInput
      placeholder={type}
      onChange={handleInputKeyChange}
      value={newTag[type]}
      id={id}
    />
  );
}

export function SetNewTagCategoryDropDown({ id }: { id: string }) {
  const dispatch = useDispatch();

  const handleDropdownSelect = (value) => {
    dispatch(setNewTag(TagKeys.CATEGORY, value));
  };

  return (
    <SearchableDropdown
      name="selectPropertyType"
      placeholder="select type"
      noShadow={true}
      search={false}
      value={TagCategoryEnum.NONE}
      noHoverBorder={true}
      options={TagCategoryOptions}
      handleChange={handleDropdownSelect}
      id={id}
    />
  );
}

export const EditTagNameCell = ({ rowData }: { rowData: any; id?: any }) => {
  const dispatch = useDispatch();
  const handleSubmit = (val) => {
    dispatch(
      changeTagAction({
        ...rowData,
        name: secureTrim(val),
      })
    );
  };
  return (
    <HoveredInputCell
      initialContent={rowData.name}
      handleSubmit={handleSubmit}
      rows={1}
      width={"146px"}
    />
  );
};

export const EditTagDescriptionCell = ({
  rowData,
}: {
  rowData: any;
  id?: any;
}) => {
  const dispatch = useDispatch();
  const handleSubmit = (val) => {
    dispatch(
      changeTagAction({
        ...rowData,
        description: secureTrim(val),
      })
    );
  };
  return (
    <HoveredInputCell
      initialContent={rowData.description}
      handleSubmit={handleSubmit}
      rows={1}
      width={"146px"}
    />
  );
};

export const EditTagCategoryCell = ({
  rowData,
  id,
}: {
  rowData: any;
  id?: string;
}) => {
  const dispatch = useDispatch();
  const changeValue = (value) => {
    dispatch(
      changeTagAction({
        ...rowData,
        category: secureTrim(value),
      })
    );
  };
  return (
    <SearchableDropdown
      value={rowData.category}
      options={TagCategoryOptions}
      name="category"
      placeholder=""
      noShadow
      noHoverBorder
      backgroundColor={colors.tables.hoveredCell}
      search={false}
      handleChange={changeValue}
      id={id}
    />
  );
};

export const addTagFields = [
  {
    content: <SetNewTagInput type={TagKeys.NAME} id={"AddRowName"} />,
    style: {},
  },
  {
    content: (
      <SetNewTagInput type={TagKeys.DESCRIPTION} id={"AddRowDescription"} />
    ),
    style: {},
  },
  {
    content: <SetNewTagCategoryDropDown id={"AddRowCategory"} />,
    style: {},
  },
];

export const StyledInput = styled.input<any>`
  text-decoration: none;
  font-size: 14px;
  color: ${(props) => props.theme.colors.primary};
  outline: none;
  border: none;
  background: none;
  padding: 0;

  :disabled {
    cursor: not-allowed;
  }

  :focus {
    outline: none; /* Remove default outline and use border or box-shadow */
  }
`;
