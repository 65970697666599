import React from "react";
import styled from "styled-components";
import { Avatar } from "./Avatar.component";
import { H5, NormalP } from "components";
import sizes, { Size } from "./sizes";
import colors from "theme/colors";
import { useDispatch } from "react-redux";
import { editUser } from "../../store/reducers/products/users/pl-users.actions";
import { useParams } from "react-router-dom";
import { useTypedSelector } from "../../store";

export function User({
  firstName = "",
  lastName = "",
  role = "",
  size = "normal",
  color,
  hideRole = false,
  className,
  textWrapperWidth = "140px",
  id,
}: {
  firstName: string;
  lastName: string;
  role?: string;
  size?: Size;
  color?: string;
  hideRole?: boolean;
  className?: any;
  textWrapperWidth?: string;
  id?: string;
}) {
  const { fontSize, fontSizeUserText } = sizes[size];
  let titleColor = colors.slate;
  let roleColor = colors.muttedHeadlines;

  if (color === "white") {
    titleColor = "white";
    roleColor = "rgb(255, 255, 255, 0.6)";
  }

  const { productLineId } = useParams<{ productLineId: string }>();

  const dispatch = useDispatch();

  const handleChangeRole = (e) => {
    dispatch(editUser(productLineId, id, { role: e.target.value }));
  };

  const currentUserId = useTypedSelector((state) => state.user.id);

  const enableRoleDropdown = ["ADMIN", "VIEWER", "EDITOR"].includes(role);

  return (
    <Wrapper className={className}>
      <Avatar
        firstName={firstName.toUpperCase()}
        lastName={lastName.toUpperCase()}
        size={size}
      />
      {!hideRole && (
        <TextWrapper textWrapperWidth={textWrapperWidth}>
          <H5
            style={{
              margin: 0,
              fontSize: fontSize,
              color: titleColor,
              fontWeight: "bold",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {firstName} {lastName}
          </H5>
          <NormalP
            style={{
              margin: 0,
              fontSize: fontSizeUserText,
              color: roleColor,
            }}
          >
            {enableRoleDropdown && (
              <StyledSelect
                defaultValue={role}
                onChange={handleChangeRole}
                name="role"
                disabled={!id || id == currentUserId}
              >
                <option value="ADMIN">ADMIN</option>
                <option value="EDITOR">EDITOR</option>
                <option value="VIEWER">VIEWER</option>
              </StyledSelect>
            )}
          </NormalP>
        </TextWrapper>
      )}
    </Wrapper>
  );
}

const TextWrapper = styled.div<any>`
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-left: 16px;
  height: 100%;
  justify-content: space-evenly;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${(props) => props.textWrapperWidth};
`;

const StyledSelect = styled.select<any>`
  border: none;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
