import React from "react";
import styled from "styled-components";
import SelectDropdownComponent, {
  Option,
} from "./SelectDropdown/SelectDropdown.component";

export function DropdownWithLabel({
  label,
  options,
  placeholder,
  name,
  onChange,
  multiple = false,
  value,
  className,
  defaultValue,
}: {
  label: string;
  options?: any;
  placeholder?: string;
  name: any;
  onChange: any;
  multiple?: boolean;
  value?: any;
  className?: any;
  defaultValue?: Option;
}) {
  return (
    <StyledWrapper className={className}>
      <StyledLabel>{label}</StyledLabel>
      <SelectDropdownComponent
        options={options}
        name={name}
        handleChange={onChange}
        isMulti={multiple}
        value={value}
        placeholder={placeholder}
        noBorder
        defaultValue={defaultValue || undefined}
        height={"30px"}
      />
    </StyledWrapper>
  );
}

export const StyledWrapper = styled.div<any>`
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  min-height: 40px;
  min-width: 190px;
  padding: 0 8px;
  border: 1px solid ${(props) => props.theme.colors.tables.borders};
  border-radius: 4px;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.colors.white};

  @media (min-width: 1700px) {
    width: 230px;
  }
`;

const StyledLabel = styled.div<any>`
  display: inline-block;
  position: absolute;
  padding: 0 5px 0 5px;
  top: -10px;
  left: 10px;
  z-index: 1;
  background-color: #fff;
  color: ${(props) => props.theme.colors.secondary};
  font-size: 10px;
`;
