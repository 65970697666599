import {
  SearchableDropdown,
  HoveredInputCell,
} from "../../../../../components";
import React from "react";
import {
  setNewPVAName,
  setNewPVAType,
  changePVAAction,
} from "store/reducers/products/parameters/parameters.actions";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "store";
import { getNewPVAName, getNewPVAType } from "../helpers";
import colors from "theme/colors";
import { DataTypeEnum } from "store/reducers/products/properties/pl-properties.types";
import { secureTrim } from "helpers/string";

export const pvaTableTypeOptions = Object.entries(DataTypeEnum).map(
  (dataType) => {
    return {
      name: dataType[1],
      value: dataType[1],
    };
  }
);

export const addPVAFields = [
  {
    content: <SetNewPVANameInput id="AddPVAName" />,
    style: {},
  },
  {
    content: <SetNewPVATypeDropDown id="AddPVADatType" />,
    style: {},
  },
];

export function SetNewPVANameInput({ id }: { id: string }) {
  const dispatch = useDispatch();

  const handleInputKeyChange = (event) => {
    dispatch(setNewPVAName(event.target.value));
  };

  const PVAName = getNewPVAName(
    useTypedSelector(
      (state) => state.products.parameters.newPropertyValueAttribute
    )
  );

  return (
    <StyledInput
      placeholder="name"
      value={PVAName}
      onChange={handleInputKeyChange}
      id={id}
    />
  );
}

export function SetNewPVATypeDropDown({ id }: { id: string }) {
  const dispatch = useDispatch();

  const handleDropdownSelect = (event) => {
    dispatch(setNewPVAType(event));
  };

  const PVAType = getNewPVAType(
    useTypedSelector(
      (state) => state.products.parameters.newPropertyValueAttribute
    )
  );

  return (
    <SearchableDropdown
      name="selectPropertyType"
      placeholder="select type"
      noShadow={true}
      search={false}
      value={PVAType}
      noHoverBorder={true}
      options={pvaTableTypeOptions}
      handleChange={handleDropdownSelect}
      id={id}
    />
  );
}

export const EditTypeCell = ({
  rowData,
  id,
}: {
  rowData: any;
  id?: string;
}) => {
  const dispatch = useDispatch();
  const changeValue = (value) => {
    dispatch(
      changePVAAction({
        ...rowData,
        type: secureTrim(value),
      })
    );
  };
  return (
    <SearchableDropdown
      value={rowData.dataType}
      options={pvaTableTypeOptions}
      name="type"
      placeholder={rowData.dataType}
      noShadow
      noHoverBorder
      backgroundColor={colors.tables.hoveredCell}
      search={false}
      handleChange={changeValue}
      id={id}
    />
  );
};

export const EditNameCell = ({ rowData }: { rowData: any; id?: any }) => {
  const dispatch = useDispatch();
  const handleSubmit = (val) => {
    dispatch(
      changePVAAction({
        ...rowData,
        name: secureTrim(val),
      })
    );
  };
  return (
    <HoveredInputCell
      initialContent={rowData.name}
      handleSubmit={handleSubmit}
      rows={1}
      width={"146px"}
    />
  );
};

export const StyledInput = styled.input<any>`
  text-decoration: none;
  font-size: 14px;
  color: ${(props) => props.theme.colors.primary};
  outline: none;
  border: none;
  background: none;
  padding: 0;

  :disabled {
    cursor: not-allowed;
  }

  :focus {
    outline: none; /* Remove default outline and use border or box-shadow */
  }
`;
