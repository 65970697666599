import React, { useRef, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Modal } from "components";
import Grid from "@mui/material/Unstable_Grid2";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { getValidation } from "../../../../../store/reducers/products/validation/validation.action";
import { api } from "../../../../../api/api";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../../../store";
import ValidationModal from "./Validation/ValidationModal.component";
import styled from "styled-components";
import { useInterval } from "hooks/useInterval";
import { ProcessState } from "modules/constants/enums";

//Interval between validation status polling requests in milliseconds
const POLLING_INTERVAL = 2000;

export default function ProductLineValidateButton() {
  const [validationModalOpen, setValidationModalOpen] = useState(false);
  const [status, setStatus] = useState(ProcessState.IDLE);
  const [error, setError] = useState<any>(undefined);
  const [validationProcessId, setValidationProcessId] = useState<string>("");
  const [pollingInterval] = useState<number>(POLLING_INTERVAL);
  const [statusMsg, setStatusMsg] = useState<string>("");
  const waitingForPollResponse = useRef(false);

  const { selectedProductLine: productLine } = useTypedSelector(
    (state) => state.products
  );

  const dispatch = useDispatch();

  //Request API to begin validating the entire productline
  const beginValidation = async () => {
    setStatus(ProcessState.INITIALIZING);

    try {
      //Request to validate the productline.
      //This will queue the request to run asynchronously and immediately return
      setStatusMsg("Validating Product Line");
      const response = await api.validateAllAsync(productLine?.id);
      //If the validation request was accepted successfully by the ap
      if (response && response.status == 202) {
        if (response.data) {
          setStatus(response.data.processState);
          setValidationProcessId(response.data.id);
          buildStatusMessage(response.data);
        }
      } else {
        //some error happened
        setStatus(ProcessState.ERROR);
        setError(
          response?.data?.message
            ? response.data.message
            : "Unable to initiate the Validate Product Line process.  The connection to the server may have been lost."
        );
      }
    } catch (e) {
      console.error(e, "error while validating product line");
      setStatus(ProcessState.ERROR);
      setError(e);
    } finally {
    }
  };

  const pollValidationStatus = async () => {
    //Begin polling request if we have a process we are tracking and we are not already
    //waiting on a response.
    if (validationProcessId && !waitingForPollResponse.current) {
      //flag that we are waiting to prevent re-entrance
      waitingForPollResponse.current = true;

      try {
        const response = await api.getProcess(validationProcessId);

        if (response.status != 200) {
          setStatus(ProcessState.ERROR);
        }

        if (response && response.data && response.data.processState) {
          setStatus(response.data.processState);
          buildStatusMessage(response.data);

          //If the validating process is complete, retrieve any validations from the database.
          if (response.data.processState == ProcessState.COMPLETE) {
            setValidationProcessId("");
            getValidations();
          }
        } else {
          setStatus(ProcessState.ERROR);
          setError(
            "Unable to retrieve status of the Validate Product Line process.  The connection to the server may have been lost."
          );
        }
      } catch (error) {
        console.error(error, "error while validating product line");
        setStatus(ProcessState.ERROR);
        setError(error);
      } finally {
        waitingForPollResponse.current = false;
      }
    }
  };

  //While the validation is queued or actively processing, we will periodically poll the validation status.
  //If the validation is not processing, we will stop polling by settin the delay/interval to 0.
  useInterval(
    pollValidationStatus,
    status === ProcessState.QUEUED || status === ProcessState.PROCESSING
      ? pollingInterval
      : 0
  );

  //Request all validations for the productLine from the API
  const getValidations = async () => {
    try {
      //Retrieve the all validations from api
      setStatusMsg("Retrieving Validation Errors");
      let validations;
      await dispatch(
        getValidation((result) => {
          validations = result;
        })
      );

      //If we have validations, set state to show the validation modal dialog
      if (validations && validations.length) {
        setStatusMsg("Displaying Validation Errors");
        setValidationModalOpen(true);
        return;
      }

      //Restore back to idle state
      setStatus(ProcessState.IDLE);
      setStatusMsg("Validation Complete - no problems found");
    } catch (e) {
      console.error(e, "error while validating product line");
    } finally {
    }
  };

  const closeValidationModal = () => {
    setValidationModalOpen(false);

    //return to idle state
    setStatus(ProcessState.IDLE);
    setStatusMsg("");
  };

  const closeErrorModal = () => {
    //return to idle state
    setStatus(ProcessState.IDLE);
    setStatusMsg("");
  };

  const buildStatusMessage = (processStatus) => {
    let msg = "";
    if (processStatus) {
      msg = processStatus.message;
      switch (processStatus.message) {
        case "Retrieving Properties":
          msg += " (" + processStatus.propertyTotal + ")";
          break;
        case "Validating Properties":
          msg +=
            " (" +
            processStatus.propertyNumComplete +
            " of " +
            processStatus.propertyTotal +
            ")";
          break;
        case "Retrieving Tables":
          msg += " (" + processStatus.ruleTableTotal + ")";
          break;
        case "Validating Tables":
          msg +=
            " (" +
            processStatus.ruleTableNumComplete +
            " of " +
            processStatus.ruleTableTotal +
            ")";
          break;
        default:
          break;
      }
    }
    setStatusMsg(msg);
  };

  return (
    <Wrapper>
      <Grid container direction="column">
        <Grid display="flex" justifyContent="center">
          <StyledLoadingButton
            variant="outlined"
            size="small"
            color="primary"
            loading={status !== ProcessState.IDLE}
            loadingPosition="start"
            startIcon={<TaskAltIcon />}
            onClick={beginValidation}
            disabled={status !== ProcessState.IDLE}
          >
            Validate Product Line
          </StyledLoadingButton>
        </Grid>
        <Grid display="flex" justifyContent="center">
          <StatusText>{statusMsg}</StatusText>
        </Grid>
      </Grid>
      {validationModalOpen && (
        <ValidationModal
          open={validationModalOpen}
          closeModal={closeValidationModal}
          handleExport={beginValidation}
        />
      )}
      {status === ProcessState.ERROR && (
        <Modal
          open={status === ProcessState.ERROR}
          closeModal={closeErrorModal}
          title={"Validate Product Line Error"}
        >
          {typeof error !== undefined && error}
        </Modal>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledLoadingButton = styled(LoadingButton)`
  height: 32px;
  width: 250px;
`;

const StatusText = styled.div`
  font-size: 14px;
  //margin-top: 6px;
  color: blue;
`;
