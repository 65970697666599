import React from "react";
import styled from "styled-components";

import { Delete } from "assets/logos/icons/MicroIcons";
import { SearchableDropdown } from "components";
import colors from "theme/colors";
import { teamMembers } from "store/reducers/mocks/users";

const TrashIcon = styled(Delete)`
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`;

const formattedTeamMembers = teamMembers.map((member) => {
  return {
    ...member,
    value: member.id,
  };
});

const MockSearchableDropdownWithState = ({ userID, setUserId }: any) => {
  return (
    <SearchableDropdown
      placeholder="Search for individual"
      name="newUser"
      options={formattedTeamMembers}
      value={userID}
      handleChange={setUserId}
      noShadow
      noHoverBorder
      style={{ width: "200px" }}
    />
  );
};

export const tableData = {
  header: [
    {
      content: "TYPE",
    },
    {
      content: "NAME",
    },
    {
      content: "COUNT",
    },
    {
      content: "PERMISSIONS",
    },
    {
      content: <TrashIcon color={colors.slate} />,
      style: {
        textAlign: "center",
      },
    },
  ],
  rows: [
    [
      {
        content: "Individual",
      },
      {
        content: "John Doe",
      },
      {
        content: "1",
      },
      {
        content: "Admin",
      },
      {
        content: (
          <div>
            <TrashIcon />
          </div>
        ),
        style: {
          textAlign: "center",
        },
      },
    ],
    [
      {
        content: "Individual",
      },
      {
        content: "John Doe",
      },
      {
        content: "1",
      },
      {
        content: "Admin",
      },
      {
        content: (
          <div>
            <TrashIcon />
          </div>
        ),
        style: {
          textAlign: "center",
        },
      },
    ],
  ],
};

export const addRowContent = (userID: string, setUserId: any) => [
  {
    content: "Individual",
    style: {},
  },
  {
    content: (
      <MockSearchableDropdownWithState userID={userID} setUserId={setUserId} />
    ),
    style: {},
  },
  {
    content: "1",
    style: {},
  },
  {
    content: "User",
    style: {},
  },
  {
    content: "-",
    style: {
      textAlign: "center",
    },
  },
];
