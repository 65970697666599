import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Checkbox,
  FormControlLabel,
  Box,
  Select,
  MenuItem,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { Graphviz } from "graphviz-react";
import { getRelatedProperties, generateGraphviz } from "./helpers";
import { Close as CloseIcon } from "@mui/icons-material";
import { LoaderSpinner2 } from "../../../../components";

interface GraphvizPopUpProps {
  open: boolean;
  onClose: () => void;
  productLineId: string;
  propertyData: any;
  ruleSets: any[];
}

const GraphvizPopUp: React.FC<GraphvizPopUpProps> = ({
  open,
  onClose,
  productLineId,
  propertyData,
  ruleSets,
}: GraphvizPopUpProps) => {
  const [dotContent, setDotContent] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [subpropertyFilter, setSubpropertyFilter] = useState(true);
  const [tables, setTables] = useState<any>([]);
  const [selectedRulesets, setSelectedRulesets] = useState<any>([]);
  const [filteredTables, setFilteredTables] = useState<any>([]);

  const handleChange = (event: SelectChangeEvent<typeof selectedRulesets>) => {
    const {
      target: { value },
    } = event;
    if (value.length === 0) return;
    setSelectedRulesets(value);
  };

  const handleSubpropertyFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSubpropertyFilter(e.target.checked);
  };

  useEffect(() => {
    if (tables.length === 0) return;
    setIsLoading(true);
    //keep tables that have at least one of the selected rulesets
    const NewTables = tables.filter((table: any) => {
      const tableRulesets = table.rulesets.map((ruleset: any) => ruleset.id);
      return selectedRulesets.some((ruleset: any) =>
        tableRulesets.includes(ruleset)
      );
    });
    setFilteredTables(NewTables);
  }, [selectedRulesets]);

  useEffect(() => {
    setIsLoading(true);
    const graph = generateGraphviz(
      filteredTables,
      propertyData.name,
      subpropertyFilter
    );
    setDotContent(graph);
    setIsLoading(false);
  }, [filteredTables, subpropertyFilter]);

  useEffect(() => {
    if (!open) return;
    setSelectedRulesets(ruleSets.map((ruleset) => ruleset.id));
    setIsLoading(true);
    getRelatedProperties(productLineId, propertyData.id)
      .then((tablesResponse) => {
        setTables(tablesResponse.data.ruleTables);
        setFilteredTables(tablesResponse.data.ruleTables);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {propertyData.name} related properties
        <CloseIcon sx={{ float: "right" }} onClick={onClose}></CloseIcon>
      </DialogTitle>
      <DialogContent sx={{ minWidth: "500px" }}>
        <Grid container>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked
                  value={subpropertyFilter}
                  onChange={handleSubpropertyFilterChange}
                />
              }
              label="Subproperties"
            />
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Select
                sx={{ width: "250px" }}
                value={selectedRulesets}
                onChange={handleChange}
                placeholder="select rulesets"
                multiple
              >
                {ruleSets.map((ruleset) => (
                  <MenuItem key={ruleset.id} value={ruleset.id}>
                    {ruleset.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ minWidth: "500px", minHeight: "300px" }}>
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <LoaderSpinner2 />
              </div>
            ) : (
              open && (
                <Graphviz
                  dot={dotContent}
                  options={{
                    fit: true,
                    height: 300,
                    width: 500,
                    zoom: true,
                    engine: "neato",
                  }}
                />
              )
            )}
          </Grid>
          <Grid item xs={12} display={"flex"}>
            <Typography variant="h6">Legend: </Typography>
            <img src="/LegendGraph.png" alt="graph" />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default GraphvizPopUp;
