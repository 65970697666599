import React from "react";
import styled from "styled-components";
import { Delete } from "../../../../assets/logos/icons/MicroIcons";
import { EditTypeCell, EditNameCell } from "./AddRows/PVARow";
import {
  EditTagCategoryCell,
  EditTagDescriptionCell,
  EditTagNameCell,
} from "./AddRows/addTagsRow";
import { EditRulesetCell } from "./AddRows/ruleSetsAddRow";
import { RulesetKeys } from "store/reducers/products/parameters/pl-parameters.types";
import { toast } from "react-toastify";
import { ToolTip } from "components";
import colors from "theme/colors";
import ConfirmActionButton from "components/ConfirmActionModal/ConfirmActionButton.component";

const addDeleteRow = (row, id, handleDelete, type, isDeletable = true) => {
  const count = row[1].content;
  row.push({
    content: isDeletable ? (
      <StyledConfirmDeleteAction
        buttonType={"trashIcon"}
        message={"Are you sure you want to delete this element?"}
        action={() => {
          if (type === "tags" && count > 0) {
            toast.error("Count is more than 0");
          } else {
            handleDelete(id);
          }
        }}
      />
    ) : (
      <ToolTip
        text="This is a standard PVA that can't be deleted"
        style={{ cursor: "not-allowed" }}
      >
        <DisabledDeleteIcon
          id={id}
          style={{ cursor: "not-allowed", color: colors.muttedHeadlines }}
        />
      </ToolTip>
    ),
    id: id + "deleteUUIDCell",
  });
};

const getTagRows = (rows, handleDelete) => {
  return rows.map((rowData) => {
    const row = [
      {
        content: (
          <EditTagNameCell rowData={rowData} id={rowData.id + "nameUUIDCell"} />
        ),
      },
      {
        content: (
          <EditTagDescriptionCell
            rowData={rowData}
            id={rowData.id + "descUUIDCell"}
          />
        ),
      },
      {
        content: (
          <EditTagCategoryCell
            rowData={rowData}
            id={rowData.id + "categoryUUIDCell"}
          />
        ),
      },
      {
        content: rowData.count || 0,
        id: rowData.id,
      },
    ];
    addDeleteRow(row, rowData.id, handleDelete, "tags");
    return row;
  });
};

const getPvaRows = (rows, handleDelete) => {
  return rows.map((rowData) => {
    const {
      isValueEditable = true,
      isTypeEditable = true,
      isDeletable = true,
      name,
      dataType,
    } = rowData;

    const row = [
      {
        content: isValueEditable ? (
          <EditNameCell rowData={rowData} id={rowData.id + "nameUUIdCell"} />
        ) : (
          <ToolTip
            text="This is a standard PVA, the name can't be edited"
            style={{ cursor: "not-allowed" }}
          >
            {name}
          </ToolTip>
        ),
      },
      {
        content: isTypeEditable ? (
          <EditTypeCell
            rowData={rowData}
            id={rowData.id + "dataTypeUUIDCell"}
          />
        ) : (
          <ToolTip
            text="This is a standard PVA, the type can't be edited"
            style={{ cursor: "not-allowed" }}
          >
            {dataType}
          </ToolTip>
        ),
      },
    ];
    addDeleteRow(row, rowData.id, handleDelete, "pva", isDeletable);
    return row;
  });
};

const getRulesetsRows = (rows, handleDelete) => {
  return rows.map((rowData) => {
    const row = [
      {
        content: (
          <EditRulesetCell
            rowData={rowData}
            type={RulesetKeys.NAME}
            id={rowData.id + "nameUUIDCell"}
          />
        ),
      },
      {
        content: (
          <EditRulesetCell
            rowData={rowData}
            type={RulesetKeys.DESCRIPTION}
            id={rowData.id + "descriptionUUIDCell"}
          />
        ),
      },
    ];
    addDeleteRow(row, rowData.id, handleDelete, "ruleset");
    return row;
  });
};

const mapParameterTypeToTableData = {
  pva: {
    header: [
      { content: "ATTRIBUTE" },
      { content: "DATA TYPE" },
      { content: "DELETE" },
    ],
    rows: getPvaRows,
  },
  ruleSets: {
    header: [
      { content: "NAME" },
      { content: "DESCRIPTION" },
      { content: "DELETE" },
    ],
    rows: getRulesetsRows,
  },
  tags: {
    header: [
      { content: "NAME" },
      { content: "DESCRIPTION" },
      { content: "CATEGORY" },
      { content: "COUNT" },
      { content: "DELETE" },
    ],
    rows: getTagRows,
  },
  metadata: { header: [] },
};

export function getTableData(state, parameterType, handleDelete) {
  let rawData;
  if (parameterType === "pva") {
    rawData = state.propertyValueAttributes;
  } else if (parameterType === "tags") {
    rawData = state.tags;
  } else if (parameterType === "ruleSets") {
    rawData = state.ruleSets;
  }

  const tableData = mapParameterTypeToTableData[parameterType];
  const header = tableData.header;
  const rows = tableData.rows(rawData, handleDelete);

  return {
    header,
    rows,
  };
}

const StyledConfirmDeleteAction = styled(ConfirmActionButton)<any>`
  cursor: pointer;
  margin-left: 16px;
`;

const DisabledDeleteIcon = styled(Delete)<any>`
  cursor: not-allowed;
  margin-left: 16px;
  path {
    fill: grey;
  }
`;

export function getNewPVAName(state) {
  return state.name;
}

export function getNewPVAType(state) {
  return state.type;
}
