import { setRestVerb } from "../../generalActions";
import { api } from "../../../../api/api";
import {
  PropertyValueAttribute,
  RulesetKeys,
  TagKeys,
} from "./pl-parameters.types";
import { toast } from "react-toastify";
import { secureTrim } from "helpers/string";

export const SET_PVA = "productLine/SET_PVA";

// PVA
export const ADD_NEW_PVA = "productLine/ADD_NEW_PVA";
export const REMOVE_PVA = "productLine/REMOVE_PVA";
export const SET_NEW_PVA_NAME = "productLine/SET_NEW_PVA_NAME";
export const SET_NEW_PVA_TYPE = "productLine/SET_NEW_PVA_TYPE";

// RULESETS
export const SET_NEW_RULESET = "productLine/SET_NEW_RULESET";
export const ADD_RULESET = "productLine/ADD_RULESET";
export const SET_RULESETS = "productLine/SET_RULESETS";

// TAGS
export const SET_NEW_TAG = "productLine/SET_NEW_TAG";
export const ADD_TAG = "productLine/ADD_TAG";
export const SET_TAGS = "productLine/SET_TAGS";
export const SET_TAGS_COUNT = "productLine/SET_TAGS_COUNT";

export const getProductValueAttributes = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const selectedProductLineId = state.products.selectedProductLine.id;

    try {
      dispatch(setRestVerb("products", true, "getting"));
      const res = await api.getProductLinePVA(selectedProductLineId);

      if (!res) throw null;
      dispatch({
        type: SET_PVA,
        payload: res.data,
      });
    } catch (e) {
      console.error(e, "error while getting PVA");
    }
    dispatch(setRestVerb("products", false, "getting"));
  };
};

export const getProductLineTags = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const selectedProductLineId = state.products.selectedProductLine.id;

    try {
      dispatch(setRestVerb("products", true, "getting"));
      const res = await api.getProductLineTags(selectedProductLineId);

      if (!res) throw null;
      dispatch({
        type: SET_TAGS,
        payload: res.data,
      });
    } catch (e) {
      console.error(e, "error while getting Tags");
    }
    dispatch(setRestVerb("products", false, "getting"));
  };
};

export const getProductLineTagsCount = (productLineId?: string) => {
  return async (dispatch, getState) => {
    const state = getState();
    const selectProductLineId = productLineId
      ? productLineId
      : state.products.selectedProductLine.id;

    try {
      const res = await api.getProductLineTagsCount(selectProductLineId);

      if (!res) throw null;
      dispatch({
        type: SET_TAGS,
        payload: res.data,
      });
    } catch (e) {
      console.error(e, "error while getting Tags count");
    }
  };
};

export const getProductRulesets = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const selectedProductLineId = state.products.selectedProductLine.id;

    try {
      dispatch(setRestVerb("products", true, "getting"));
      const res = await api.getProductLineRulesets(selectedProductLineId);

      if (!res) throw null;
      dispatch({
        type: SET_RULESETS,
        payload: res.data,
      });
    } catch (e) {
      console.error(e, "error while getting Rulesets");
    }
    dispatch(setRestVerb("products", false, "getting"));
  };
};

export const setNewPVAName = (PVAName) => {
  return (dispatch) => {
    dispatch({ type: SET_NEW_PVA_NAME, payload: PVAName });
  };
};

export const setNewPVAType = (PVAType) => {
  return (dispatch) => {
    dispatch({ type: SET_NEW_PVA_TYPE, payload: PVAType });
  };
};

export const addPVA = () => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const pvaState = state.products.parameters.propertyValueAttributes;
      const name = secureTrim(
        state.products?.parameters.newPropertyValueAttribute?.name
      );
      const { type } = state.products?.parameters.newPropertyValueAttribute;
      const selectedProductLineId = state.products.selectedProductLine?.id;

      if (!name || !type) {
        toast.error("Name and description must not be empty");

        return;
      }

      dispatch(setRestVerb("products", true, "posting"));

      const newPvaLocalState = [...pvaState, { name: name, dataType: type }];

      dispatch({ type: ADD_NEW_PVA, payload: newPvaLocalState });

      const res = await api.createProductLinePVA(
        { name, dataType: type },
        selectedProductLineId
      );

      if (!res) throw null;

      const { data } = res;
      const newPvaState = [
        ...pvaState,
        { id: data.id, name: name, dataType: type },
      ];
      dispatch({ type: ADD_NEW_PVA, payload: newPvaState });
    } catch (e) {
      console.error(e, "error while creating new PVA");
    }
    dispatch(setRestVerb("products", false, "posting"));
  };
};

export const addStandardPVAs = () => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const pvaState = state.products.parameters.propertyValueAttributes;
      const selectedProductLineId = state.products.selectedProductLine?.id;

      dispatch(setRestVerb("products", true, "posting"));

      //Call api to create standard pvas
      const res = await api.createStandardPVAs(selectedProductLineId);
      if (!res) throw null;
      const { data } = res;

      //Update the pva list for redux store
      const newPvaState = [...pvaState, ...data];
      dispatch({ type: SET_PVA, payload: newPvaState });
    } catch (e) {
      console.error(e, "Error while creating standard PVAs");
    }
    dispatch(setRestVerb("products", false, "posting"));
  };
};

export const removePVA = (pvaId) => {
  return async (dispatch, getState) => {
    const state = getState();
    const selectedProductLineId = state.products.selectedProductLine?.id;
    const pvaState = state.products.parameters.propertyValueAttributes;

    try {
      dispatch(setRestVerb("products", true, "deleting"));
      const newPvaState = pvaState.filter((pva) => pva.id !== pvaId);
      dispatch({ type: REMOVE_PVA, payload: newPvaState });
      await api.deleteProductLinePVA(selectedProductLineId, pvaId);
    } catch (e) {
      console.error(e, "error while deleting pva");
      dispatch({ type: REMOVE_PVA, payload: pvaState });
    }
    dispatch(setRestVerb("products", false, "deleting"));
  };
};

export const changePVAAction = (newPva: PropertyValueAttribute) => {
  return async (dispatch, getState) => {
    const selectedProductLineId = getState().products.selectedProductLine?.id;
    const { propertyValueAttributes } = getState().products.parameters;

    try {
      if (!newPva.name) return toast.error("Name  cannot be null");

      const { data }: any = await api.changeProductLinePVA(
        newPva,
        selectedProductLineId
      );
      console.log("changePVAAction data = ", data);

      //Update the state of the pvas
      const newPVAs = propertyValueAttributes.map((pva) => {
        if (pva.id === data.id) return data;
        return pva;
      });
      console.log("new pvas = ", newPVAs);

      dispatch({ type: SET_PVA, payload: newPVAs });
    } catch (e) {
      // if error, get the data again to refresh the state
      console.error(e, "error while modifying pva");
    }
  };
};

// ruleSets
export const setNewRuleset = (type: RulesetKeys, value: string) => {
  return (dispatch, getState) => {
    const newProductLineRuleset = {
      ...getState().products.parameters.newRuleset,
    };

    newProductLineRuleset[type] = secureTrim(value);

    dispatch({ type: SET_NEW_RULESET, payload: newProductLineRuleset });
  };
};

export const addRuleset = () => {
  return async (dispatch, getState) => {
    const prevState = getState();
    const { ruleSets } = prevState.products.parameters;
    const selectedProductLineId = prevState.products.selectedProductLine?.id;

    const { name = "", description = "" } = {
      ...prevState.products.parameters.newRuleset,
    };
    if (!name) {
      toast.error("Name must not be empty");
      return;
    }
    const newState = [...ruleSets, { name, description }];

    try {
      // optimistic
      dispatch({ type: ADD_RULESET, payload: newState });

      const res = await api.createProductLineRuleset(
        { name, description },
        selectedProductLineId
      );

      if (!res) throw null;

      const { data } = res;
      const newStateRes = [...ruleSets, data];

      // adjust for response
      dispatch({ type: ADD_RULESET, payload: newStateRes });
    } catch (e) {
      // if something went wrong, go back to previous state
      dispatch({ type: SET_RULESETS, payload: ruleSets });
      console.error(e, "error while creating new PVA");
    }
  };
};

export const removeRuleset = (ruleSetId) => {
  return async (dispatch, getState) => {
    const prevState = getState();
    const { ruleSets } = prevState.products.parameters;
    const selectedProductLineId = prevState.products.selectedProductLine?.id;

    try {
      const newState = ruleSets.filter((ruleSet) => ruleSet.id !== ruleSetId);

      // optimistic
      dispatch({ type: SET_RULESETS, payload: newState });

      const res = await api.deleteProductLineRuleset(
        selectedProductLineId,
        ruleSetId
      );

      if (!res) throw null;
    } catch (e) {
      // if something went wrong, go back to previous state
      dispatch({ type: SET_RULESETS, payload: ruleSets });
      console.error(e, "error while creating new PVA");
    }
  };
};

export const changeRulesetAction = (newRuleset) => {
  return async (dispatch, getState) => {
    const selectedProductLineId = getState().products.selectedProductLine?.id;
    const prevState = getState();
    const { ruleSets } = prevState.products.parameters;

    try {
      if (!newRuleset.name)
        return toast.error("Name or Description cannot be null");

      const { data }: any = await api.changeProductLineRuleset(
        newRuleset,
        selectedProductLineId
      );

      const newRulesets = ruleSets.map((ruleSet) => {
        if (ruleSet.id === data.id) return data;
        return ruleSet;
      });

      dispatch({ type: SET_RULESETS, payload: newRulesets });
    } catch (e) {
      // if error, get the data again to refresh the state
      console.error(e, "error while modifying ruleSet");
    }
  };
};

// tags
export const setNewTag = (type: TagKeys, value: string) => {
  return (dispatch, getState) => {
    const newTag = {
      ...getState().products.parameters.newTag,
    };

    newTag[type] = value;

    dispatch({ type: SET_NEW_TAG, payload: newTag });
  };
};

export const addTag = () => {
  return async (dispatch, getState) => {
    const prevState = getState();
    const { tags } = prevState.products.parameters;
    const selectedProductLineId = prevState.products.selectedProductLine?.id;

    const { name = "", description = "", category = "" } = {
      ...prevState.products.parameters.newTag,
    };

    if (!name) {
      toast.error("Name must not be empty");
      return;
    }

    const newState = [...tags, { name, description, category, count: 0 }];

    try {
      // optimistic
      dispatch({ type: SET_TAGS, payload: newState });

      const res = await api.createProductLineTag(
        { name, description, category },
        selectedProductLineId
      );

      if (!res) throw null;

      const { data } = res;
      const newStateRes = [...tags, data];

      // adjust for response
      dispatch({ type: SET_TAGS, payload: newStateRes });
    } catch (e) {
      // if something went wrong, go back to previous state
      dispatch({ type: SET_TAGS, payload: tags });
      console.error(e, "error while creating new tag");
    }
  };
  s;
};

export const addStandardTags = () => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const selectedProductLineId = state.products.selectedProductLine?.id;

      dispatch(setRestVerb("products", true, "posting"));

      //Call api to create standard tags
      const res = await api.createProductLineStandardTags(
        selectedProductLineId
      );
      if (!res) throw null;
      const { data } = res;
      data.sort((a, b) => a.name.localeCompare(b.name));

      //Call api to get all tags with usage counts again
      let newTagsState;
      const countRes = await api.getProductLineTagsCount(selectedProductLineId);
      if (countRes) {
        console.log("addStandardTags all tags = ", countRes.data);
        newTagsState = [...countRes.data];
      } else newTagsState = [...data];

      //Update the tags list for redux store
      dispatch({ type: SET_TAGS, payload: newTagsState });
    } catch (e) {
      console.error(e, "Error while creating standard PVAs");
    }
    dispatch(setRestVerb("products", false, "posting"));
  };
};

export const removeTag = (tagId: string) => {
  return async (dispatch, getState) => {
    const prevState = getState();
    const { tags } = prevState.products.parameters;
    const selectedProductLineId = prevState.products.selectedProductLine?.id;

    try {
      const newState = tags.filter((tag) => tag.id !== tagId);

      // optimistic
      dispatch({ type: SET_TAGS, payload: newState });

      const res = await api.deleteProductLineTag(selectedProductLineId, tagId);

      if (!res) throw null;
    } catch (e) {
      // if something went wrong, go back to previous state
      dispatch({ type: SET_TAGS, payload: tags });
      console.error(e, "error while creating new tag");
    }
  };
};

export const changeTagAction = (newTag) => {
  return async (dispatch, getState) => {
    const selectedProductLineId = getState().products.selectedProductLine?.id;
    const { tags } = getState().products.parameters;

    try {
      if (!newTag.name) return toast.error("Name cannot be null");

      const { data }: any = await api.changeProductLineTag(
        newTag,
        selectedProductLineId
      );

      //Replace the updated tag
      const newTags = tags.map((tag) => {
        if (tag.id === data.id) return data;
        return tag;
      });

      dispatch({ type: SET_TAGS, payload: newTags });
    } catch (e) {
      // if error, get the data again to refresh the state
      console.error(e, "error while modifying tag");
    }
  };
};
