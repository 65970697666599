import React from "react";
import { useLocation, matchPath, useHistory } from "react-router-dom";
import { stepRoutesArray } from "pages/ProgramsAddNew/ProgramsAddNew.page";
import styled from "styled-components";
import colors from "theme/colors";

export function HeaderAddNewProgram() {
  const history = useHistory();
  const { pathname } = useLocation();
  const currentRoute = stepRoutesArray.find((route) =>
    matchPath(pathname, route)
  );
  if (!currentRoute) return <></>;

  const { position: currentPosition } = currentRoute;

  return (
    <Wrapper>
      <Cell
        style={{ fontWeight: "bold", color: colors.primary, cursor: "default" }}
      >
        Add New Program
      </Cell>
      {stepRoutesArray.map((route) => {
        const { label, step, position, path } = route;
        const isCurrentOrPastStep = position <= currentPosition;
        return (
          <Cell
            key={step}
            isCurrentOrPastStep={isCurrentOrPastStep}
            onClick={() => isCurrentOrPastStep && history.push(path)}
          >
            {label}
          </Cell>
        );
      })}
    </Wrapper>
  );
}

const Cell = styled.div<any>`
  cursor: ${(props) => (props.isCurrentOrPastStep ? "pointer" : "not-allowed")};
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  margin: auto;
  text-align: center;
  padding: 24px;
  text-transform: uppercase;
  white-space: nowrap;
  color: ${(props) => props.theme.colors.muttedHeadlines};
  font-size: 12px;
  box-sizing: border-box;
  border-right: 1px solid #e2e2e2;
  box-shadow: ${(props) =>
    props.isCurrentOrPastStep
      ? `inset 0px -4px 0px -2px ${props.theme.colors.secondary}`
      : "none"};
`;

const Wrapper = styled.div`
  display: none;
  padding-left: 64px;

  @media ${(props) => props.theme.device.mobile} {
    display: flex;
  }
`;
