import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { H5, H6 } from "components";
import colors from "theme/colors";
import { ArrowLeft, ArrowRight } from "assets/logos/icons/MediumIcons";
import { Close } from "assets/logos/icons/MicroIcons";

export function DatePicker({
  children,
  setInputDate,
}: {
  children: any;
  setInputDate?: (value: any) => void;
}) {
  const togglePicker = () => {
    setPickerVisibility((prevState) => !prevState);
  };
  const [monthDetails, setMonthDetails] = useState<any[]>([]);
  const [isPickerVisible, setPickerVisibility] = useState(false);
  const date = new Date();
  const [currentDay, setCurrentDay] = useState(date.getDate());
  const [currentYear, setCurrentYear] = useState(date.getUTCFullYear());
  const [currentMonth, setCurrentMonth] = useState(date.getMonth() + 1);
  const [numberOfDays, setNumberOfDays] = useState(
    new Date(currentYear, currentMonth, 0).getDate()
  );
  const [dayOfTheWeek, setTheDayOfTheWeek] = useState(
    new Date(currentYear, currentMonth - 1, 1).getDay()
  );
  useEffect(() => {
    setTheMonthDays();
  }, [dayOfTheWeek, numberOfDays]);
  const setTheMonthDays = () => {
    setMonthDetails([]);
    const weekDay = dayOfTheWeek !== 0 ? dayOfTheWeek : 7;
    for (let i = 1; i < weekDay; i++) {
      setMonthDetails((prevArray) => [...prevArray, " "]);
    }
    for (let i = 1; i <= numberOfDays; i++) {
      setMonthDetails((prevArray) => [...prevArray, i]);
    }
  };
  const daysNames = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const nextMonth = () => {
    if (currentMonth < 11) {
      setCurrentMonth(currentMonth + 1);
      setTheDayOfTheWeek(new Date(currentYear, currentMonth, 1).getDay());
      setNumberOfDays(new Date(currentYear, currentMonth + 1, 0).getDate());
    } else {
      setCurrentMonth(0);
      setCurrentYear(currentYear + 1);
      setTheDayOfTheWeek(new Date(currentYear + 1, 0, 1).getDay());
      setNumberOfDays(new Date(currentYear + 1, 0, 0).getDate());
    }
    setCurrentDay(1);
  };
  const previousMonth = () => {
    if (currentMonth > 0) {
      setCurrentMonth(currentMonth - 1);
      setTheDayOfTheWeek(new Date(currentYear, currentMonth - 2, 1).getDay());
      setNumberOfDays(new Date(currentYear, currentMonth - 1, 0).getDate());
    } else {
      setCurrentMonth(11);
      setCurrentYear(currentYear - 1);
      setTheDayOfTheWeek(new Date(currentYear - 1, 11, 1).getDay());
      setNumberOfDays(new Date(currentYear - 1, 11, 0).getDate());
    }
    setCurrentDay(1);
  };

  const handleClick = (day) => {
    if (day !== " ") {
      setCurrentDay(day);
      setInputDate
        ? setInputDate(
            new Date(Date.UTC(currentYear, currentMonth - 1, day)).getTime()
          )
        : null;
      setPickerVisibility(false);
    }
  };

  const displayCalendar = () => {
    return (
      <Container>
        {daysNames.map((day, index) => {
          return (
            <DayName key={index}>
              <CustomDayName bold color={colors.muttedHeadlines}>
                {day}
              </CustomDayName>
            </DayName>
          );
        })}
        {monthDetails.map((day, index) => {
          return (
            <Day
              key={index}
              onClick={() => handleClick(day)}
              today={currentDay === day && day !== " "}
              day={day}
            >
              <CustomDayName>{day}</CustomDayName>
            </Day>
          );
        })}
      </Container>
    );
  };
  return (
    <>
      {isPickerVisible && (
        <Picker>
          <StyledContainer>
            <CloseRow>
              <Close onClick={() => setPickerVisibility(false)} />
            </CloseRow>
            <HeaderRow>
              <CustomIcon onClick={previousMonth}>
                <ArrowLeft />
              </CustomIcon>
              <CustomTitle bold color={colors.muttedHeadlines}>
                {months[currentMonth - 1]} {currentYear}
              </CustomTitle>
              <CustomIcon onClick={nextMonth}>
                <ArrowRight />
              </CustomIcon>
            </HeaderRow>
            {displayCalendar()}
          </StyledContainer>
        </Picker>
      )}
      <Box onClick={togglePicker}>{children}</Box>
    </>
  );
}

const Box = styled.div``;

const Picker = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  width: 400px;
  position: absolute;
  height: 300px;
`;

const Container = styled.div`
  column-gap: 2px;
  row-gap: 2px;
  columns: 7;
  grid-template-columns: 40px 40px 40px 40px 40px 40px 40px;
  display: grid;
`;

const Day = styled.div<any>`
  width: 40px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.day !== " " ? "pointer" : "default")};
  border-radius: 100px;
  font-weight: ${(props) => (props.today ? "bold" : "normal")};
  background-color: ${(props) =>
    props.today ? props.theme.colors.secondary : "none"};
  color: ${(props) =>
    props.today
      ? props.theme.colors.white
      : props.theme.colors.muttedHeadlines};
  &:hover {
    font-weight: "bold";
    background-color: ${(props) =>
      props.day !== " " ? props.theme.colors.secondary : "none"};
    color: ${(props) => props.theme.colors.white};
  }
`;
const DayName = styled.div`
  width: 40px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const CustomTitle = styled(H5)`
  margin-block-start: 0px;
  margin-block-end: 8px;
`;

const CustomDayName = styled(H6)`
  margin-block-start: 0px;
  margin-block-end: 0px;
  color: inherit;
  font-weight: inherit;
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 280px;
  margin-bottom: 10px;
`;

const CustomIcon = styled.div`
  cursor: pointer;
`;

const StyledContainer = styled.div`
  position: relative;
  top: 350px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 20px 0px;
  border: 1px solid ${colors.tables.borders};
  padding: 25px;
  width: 400px;
  box-sizing: border-box;
  position: absolute;
  max-height: 300px;
  z-index: 10001;
`;

const CloseRow = styled.div`
  width: 100%;
  justify-content: flex-end;
  display: flex;
  margin-bottom: 14px;
`;
