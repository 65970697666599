import {
  SET_LOGIC_GROUP,
  SET_LOGIC_GROUPS,
  SET_LOGIC_GROUPS_INITIAL_STATE,
} from "./logicGroups.actions";
import { LogicGroupsState } from "./logicGroups.types";

export const plLogicGroupsInitialState: LogicGroupsState = {
  byId: {},
  allIds: [],

  getting: false,
  posting: false,
  deleting: false,
};

export function plLogicGroupsReducer(
  state: LogicGroupsState = plLogicGroupsInitialState,
  action: any
) {
  switch (action.type) {
    case SET_LOGIC_GROUPS:
      return {
        ...state,
        byId: {
          ...action.payload.byId,
        },
        allIds: [...action.payload.allIds],
      };
    case SET_LOGIC_GROUP:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: action.payload.data,
        },
        allIds: [...state.allIds, action.payload.id],
      };

    case SET_LOGIC_GROUPS_INITIAL_STATE:
      return plLogicGroupsInitialState;

    default:
      return state;
  }
}
