import styled from "styled-components";

interface PProps {
  bold?: boolean;
  textAlign?:
    | "start"
    | "end"
    | "left"
    | "right"
    | "center"
    | "justify"
    | "match-parent";
}
export const LargeP = styled.p<PProps>`
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
  color: ${(props) => (props.color ? props.color : props.theme.colors.primary)};
  font-size: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const NormalP = styled.p<PProps>`
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
  line-height: 18px;
  font-size: 14px;

  @media ${(props) => props.theme.device.mobile} {
    font-size: 16px;
  }
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SmallP = styled.p<PProps>`
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
  line-height: 16px;
  font-size: 12px;
  color: ${(props) => (props.color ? props.color : props.theme.colors.primary)};
  overflow: hidden;
  text-overflow: ellipsis;
`;
