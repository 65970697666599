const sizes = {
  large: { width: "64px", fontSize: "20px", fontSizeUserText: "16px" },
  normal: { width: "48px", fontSize: "16px", fontSizeUserText: "16px" },
  small: { width: "40px", fontSize: "16px", fontSizeUserText: "12px" },
  extraSmall: { width: "32px", fontSize: "12px", fontSizeUserText: "12px" },
};

export type Size = "large" | "normal" | "small" | "extraSmall";

export default sizes;
