import React from "react";
import styled from "styled-components";

export const AddImage = ({
  type = "text",
  size = "short",
  label = "Image",
  required = false,
  disabled = false,
  className,
  name,
  onSelectFile,
  imgName,
}: {
  label?: string;
  type?: string;
  size?: "tall" | "short";
  required?: boolean;
  disabled?: boolean;
  className?: any;
  name?: any;
  onSelectFile?: any;
  imgName: string;
}) => {
  return (
    <StyledInputWrapper
      className={className}
      type={type}
      size={size}
      required={required}
      disabled={disabled}
      name={name}
    >
      <ImageName>{imgName}</ImageName>

      {label?.length > 0 && size === "tall" && (
        <StyledLabel>{label}</StyledLabel>
      )}
      {label?.length > 0 && size === "short" && (
        <StyledLabelShort>{label}</StyledLabelShort>
      )}
      <AddLabelButton htmlFor="upload-photo">Add</AddLabelButton>
      <StyledInput
        type="file"
        accept="image/*"
        name="photo"
        id="upload-photo"
        onChange={onSelectFile}
      />
    </StyledInputWrapper>
  );
};

const ImageName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 16px;
  box-sizing: border-box;
  color: ${(props) => props.theme.colors.secondaryHeadlines};
`;

export const StyledInputWrapper = styled.div<any>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: ${(props) => (props.size === "tall" ? "72px" : "48px")};
  width: 464px;
  max-width: 100%;
  border: 1px solid ${(props) => props.theme.colors.tables.borders};
  border-radius: 4px;
  padding: 0 0 0 24px;
  box-sizing: border-box;
`;

const StyledLabel = styled.span<any>`
  color: ${(props) => props.theme.colors.secondary};
  font-size: 12px;
  margin-bottom: 4px;
`;

const StyledLabelShort = styled(StyledLabel)<any>`
  display: inline-block;
  padding: 4px;
  position: absolute;
  margin: -47px 0 0 -10px;
  background-color: #fff;
`;

const StyledInput = styled.input<any>`
  opacity: 0;
  position: absolute;
  z-index: -1;
`;

const AddLabelButton = styled.label<any>`
  width: 100px;
  display: flex;
  align-self: flex-end;
  height: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  font-weight: 700;
  color: white;
  background-color: ${(props) => props.theme.colors.secondary};

  :hover {
    opacity: 0.7;
  }
`;
