import React from "react";
import styled from "styled-components";
import colors from "theme/colors";
import { H4 } from "components/Typography/Headlines.component";
import { Button } from "components/Buttons/Button.component";
import { useHistory } from "react-router-dom";
import routes from "modules/router/routes";
export default function TitleBar({
  title,
  amount,
}: {
  title: string;
  amount: number;
}) {
  const history = useHistory();
  return (
    <Bar>
      <TitleBox>
        <H4 bold color={colors.primary}>
          {title}
        </H4>
        <CountBox>
          <Count>{amount}</Count>
        </CountBox>
      </TitleBox>
      <Button
        type="secondary"
        color="primary"
        size="small"
        style={{ minHeight: "32px" }}
        onClick={() => history.push(routes.PROGRAMS_ADD_NEW)}
      >
        Add new program
      </Button>
    </Bar>
  );
}

const Bar = styled.div`
  width: 100%;
  justify-content: space-between;
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${colors.tables.borders};
  align-items: center;
`;

const CountBox = styled.div`
  height: 24px;
  padding: 4px 8px 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.background.primaryLabels};
  border-radius: 4px;
  margin-left: 8px;
  max-width: auto;
`;

const Count = styled.p`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 700;
  color: ${colors.secondary};
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
