import React, { useState } from "react";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import { Modal } from "components";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
//import { getValidation } from "../../../../../store/reducers/products/validation/validation.action";
import { api } from "../../../../../api/api";
import {
  saveJsonToFile,
  getFilenameFromContentDisposition,
} from "../../../../../api/helpers";
//import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../../../store";
import ValidationModal from "./Validation/ValidationModal.component";
import styled from "styled-components";

enum ExportStates {
  IDLE,
  PENDING,
  SUCCESS,
  ERROR,
}

export default function ProductLineDatabaseExportButton() {
  const [validationModalOpen, setValidationModalOpen] = useState(false);
  const [exportStatus, setExportStatus] = useState(ExportStates.IDLE);
  const [exportError, setExportError] = useState<any>(undefined);
  const closeValidationModal = () => {
    setValidationModalOpen(false);
  };
  const closeExportErrorModal = () => {
    setExportStatus(ExportStates.IDLE);
  };

  //const dispatch = useDispatch();

  const { selectedProductLine: productLine } = useTypedSelector(
    (state) => state.products
  );

  const handleExport = async () => {
    //MRU: 2024-04-18:  I have commented this out so that on export of
    //product line database json, we don't stop if there are validations.
    //TODO: This would be better if we warned the user there were validations
    //and asked them if they want to resolve them before exporting.
    // let validation;
    // await dispatch(
    //   getValidation((result) => {
    //     validation = result;
    //   })
    // );

    // if (validation && validation.length) {
    //   setValidationModalOpen(true);
    //   return;
    // }

    // setValidationModalOpen(false);

    setExportStatus(ExportStates.PENDING);

    try {
      const res = await api.exportProductLineDatabaseJson(productLine?.id);

      if (res?.response?.status === 400) {
        setExportError(res.response.data);
        setExportStatus(ExportStates.ERROR);
        throw null;
      }

      setExportStatus(ExportStates.SUCCESS);

      const filename = getFilenameFromContentDisposition(
        // it is lowercase in local and capitalized when deployed
        res.headers["content-disposition"] || res.headers["Content-Disposition"]
      );
      saveJsonToFile(res.data, filename || productLine?.name);

      toast.success(
        `'${filename}' successfully exported to downloads directory`
      );
    } catch (e) {
      console.error(e, "error while exporting product line");
    }
  };

  return (
    <Wrapper>
      <LoadingButton
        variant="outlined"
        size="small"
        color="primary"
        loading={exportStatus === ExportStates.PENDING}
        loadingPosition="start"
        startIcon={<FileDownloadIcon />}
        onClick={handleExport}
        disabled={exportStatus === ExportStates.PENDING}
      >
        Backup Product Line
      </LoadingButton>
      {validationModalOpen && (
        <ValidationModal
          open={validationModalOpen}
          closeModal={closeValidationModal}
          handleExport={handleExport}
        />
      )}
      {exportStatus === ExportStates.ERROR && (
        <Modal
          open={exportStatus === ExportStates.ERROR}
          closeModal={closeExportErrorModal}
          title={"Export tool error"}
        >
          {typeof exportError !== undefined && exportError}
        </Modal>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
