import React from "react";
import { SearchableDropdown } from "../../../components";
import { productRegionOptionsName } from "../../../store/reducers/products/products.types";
import styled from "styled-components";
import colors from "../../../theme/colors";

// const sortOptions = [
//   { name: "name A - Z", value: "AZ" },
//   { name: "name Z - A", value: "ZA" },
// ];

export default function ProductLinesListControl({
  setSearchRegions,
}: {
  setSearchRegions: any;
}) {
  return (
    <Wrapper>
      <Label>{"Select region"}</Label>
      <StyledSearchableDropdown
        name="region"
        options={productRegionOptionsName}
        search={false}
        noShadow
        multiple={true}
        placeholder={"select"}
        noHoverBorder
        handleChange={(e) => setSearchRegions(e)}
      />
      {/* <Label>{"Sort by"}</Label> */}
      {/* <StyledSearchableDropdown
        name="region"
        options={sortOptions}
        search={false}
        noShadow
        placeholder={"select"}
        noHoverBorder
      /> */}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Label = styled.span`
  color: ${(props) => props.theme.colors.muttedHeadlines};
`;

const StyledSearchableDropdown = styled(SearchableDropdown)<any>`
  border: 1px solid ${colors.tables.borders};
  box-sizing: border-box;
  border-radius: 4px;
  margin: 0 16px 0 8px;
  padding: 6px 6px 6px 8px;
  width: 131px;
  min-width: 131px;
`;
