import React, { useState } from "react";
import { H3, Table } from "components";
import { tableData, addRowContent } from "components/Table/stories/mockTable";

// All this component is WIP - no need to review atm
export default function Team() {
  const [userID, setUserId] = useState("");

  return (
    <>
      <H3 bold style={{ marginBottom: 16 }}>
        <Table
          data={tableData}
          addRowIsDisabled={!userID}
          addRowContent={addRowContent(userID, setUserId)}
          canAddRow={true}
          addRowAction={() => console.log("hello world")}
          addRowButtonLabel="ADD INDIVIDUAL"
        />
      </H3>
    </>
  );
}
