import React, { useState } from "react";
import { ConfirmActionContext } from "components/ConfirmActionModal/ConfirmActionContext";
import { ConfirmActionModal } from "./ConfirmActionModal.component";

export function ConfirmActionProvider({ children }: { children: any }) {
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(
    false
  );
  const [confirmAction, setConfirmAction] = useState(() => null);
  const [message, setMessage] = useState("Confirm action");

  return (
    <ConfirmActionContext.Provider
      value={{
        display: displayConfirmationModal,
        setDisplay: setDisplayConfirmationModal,
        confirmAction: confirmAction,
        setConfirmAction: setConfirmAction,
        message: message,
        setMessage: setMessage,
      }}
    >
      {children}
      <ConfirmActionModal
        displayConfirmationModal={displayConfirmationModal}
        setDisplayConfirmationModal={setDisplayConfirmationModal}
        confirmAction={confirmAction}
        message={message}
      />
    </ConfirmActionContext.Provider>
  );
}
