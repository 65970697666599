import React from "react";
import styled from "styled-components";
import { Input, Dropdown, H3, LargeP, Row } from "components";
import colors from "theme/colors";
import { useDispatch } from "react-redux";
import { setNewProgramAction } from "store/reducers/programs/programs.actions";
import { useTypedSelector } from "store";
import {
  programTypeOption,
  programRegionOptions,
} from "store/reducers/programs/programs.types";

export default function Name() {
  const dispatch = useDispatch();
  const { newProgram } = useTypedSelector((state) => state.programs);

  const handleChange = (e: any) => {
    const { value, name } = e.target;
    dispatch(setNewProgramAction({ [name]: value }));
  };

  return (
    <>
      <H3 bold style={{ marginBottom: 16 }}>
        Add a New Program
      </H3>
      <LargeP
        style={{ marginTop: 0, textAlign: "center" }}
        color={colors.muttedHeadlines}
      >
        Specify the program name and type.
      </LargeP>

      <StyledRow>
        <StyledInput
          onChange={handleChange}
          autoFocus={true}
          label="Program Name"
          name="name"
          value={newProgram.name}
        />
      </StyledRow>
      <StyledRow>
        <StyledDropdown
          placeholder="Program Type"
          options={programTypeOption}
          onChange={handleChange}
          name="type"
          value={newProgram.type}
        />
        <StyledDropdown
          placeholder="Region"
          options={programRegionOptions}
          onChange={handleChange}
          name="region"
          value={newProgram.region}
        />
      </StyledRow>
    </>
  );
}

const StyledRow = styled(Row)`
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
  align-items: center;

  @media ${(props) => props.theme.device.mobile} {
    width: 640px;
    flex-direction: row;
  }
`;

const StyledInput = styled(Input)`
  width: 95%;

  @media ${(props) => props.theme.device.mobile} {
    width: 640px;
  }
`;

const StyledDropdown = styled(Dropdown)`
  margin-top: 16px;
  width: 95%;

  @media ${(props) => props.theme.device.mobile} {
    width: 310px;
  }
`;
