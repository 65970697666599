import {
  ObjectAssignedEnum,
  ValidationById,
  ValidatorNames,
} from "./validation.types";
import { api } from "api/api";
import { setRestVerb } from "../../generalActions";
import { toast } from "react-toastify";

export const SET_VALIDATION = "products/plValidation/SET_VALIDATION";
export const CLEAR_VALIDATION = "products/plValidation/CLEAR_VALIDATION";
export const ADD_VALIDATION = "products/plValidation/ADD_VALIDATION";
export const GET_VALIDATION_BY_OBJECT_ID =
  "products/plValidation/GET_VALIDATION_BY_OBJECT_ID";
export const SET_VERB = "products/plValidation/REST_VERB";
export const DELETE_VALIDATION = "products/plValidation/DELETE_VALIDATION";

export const getValidation = (callback) => {
  return async (dispatch, getState) => {
    dispatch(setRestVerb("products/plValidation", true, "getting"));
    const selectedProductLineId = getState().products?.selectedProductLine?.id;

    try {
      const { data } = await api.getValidation(selectedProductLineId);

      const newAllIds: Array<string> = [];
      const newById: ValidationById = {};

      data.map((errorObject) => {
        newAllIds.push(errorObject.id);
        newById[errorObject.id] = { ...errorObject };
      });

      dispatch({
        type: SET_VALIDATION,
        payload: { byId: newById, allIds: newAllIds },
      });
      callback(data);
    } catch (e) {
      console.error(e, "error while getting validation");
    }
    dispatch(setRestVerb("products/plValidation", false, "getting"));
  };
};

export const addValidation = ({
  objectAssigned,
  objectId,
  message,
  validatorName,
}: {
  objectAssigned: ObjectAssignedEnum;
  objectId: string;
  message: string;
  validatorName: ValidatorNames;
}) => {
  return async (dispatch, getState) => {
    dispatch(setRestVerb("products/plValidation", true, "posting"));

    const { id } = getState().products.selectedProductLine;

    const requestBody = {
      type: "ERROR",
      origin: "FRONTEND",
      objectAssigned,
      objectId,
      message,
      validatorName,
      relatedObjects: [],
    };

    try {
      const { data } = await api.addValidation(id, requestBody);

      dispatch({
        type: ADD_VALIDATION,
        payload: { data },
      });
    } catch (e) {
      toast.error(e);
      console.error(e, "error while adding validation");
    }
    dispatch(setRestVerb("products/plValidation", false, "posting"));
  };
};

export const deleteValidation = ({
  validationId,
}: {
  validationId: string;
}) => {
  return async (dispatch, getState) => {
    dispatch(setRestVerb("products/plValidation", true, "deleting"));
    const { id: productLineId } = getState().products.selectedProductLine;
    const { byId } = getState().products.validation;

    try {
      await api.removeValidation(productLineId, validationId);

      const newAllIds: Array<string> = [];
      const newById: ValidationById = {};

      Object.keys(byId).map((errorObjectId) => {
        newAllIds.push(errorObjectId);
        newById[errorObjectId] = { ...byId[errorObjectId] };
      });

      dispatch({
        type: DELETE_VALIDATION,
        payload: { newAllIds, newById },
      });
    } catch (e) {
      console.error(e, "error while deleting validation");
    }
    dispatch(setRestVerb("products/plValidation", false, "deleting"));
  };
};

export const clearValidation = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_VALIDATION });
  };
};
