import { GridRowId } from "@mui/x-data-grid-pro";

// CONSTANTS
export const ROUND_DIGIT_DEFAULT = 0;
export const ROUND_DIGIT_MIN = 0;
export const ROUND_DIGIT_MAX = 10;

export enum RoundMethod {
  DECIMAL = "DECIMAL",
  SIGNIFICANT = "SIGNIFICANT",
}

export const ROUND_METHOD_DEFAULT = RoundMethod.DECIMAL;

//INTERFACES
export interface DisplayUnitsDataGridRow {
  id: GridRowId;
  displayUnit: string;
  isDefault: boolean;
  roundMethod: RoundMethod;
  roundDigit: number | null;
  __reorder__: string;
}

export interface DisplayUnitsDataGridProps {
  rows: DisplayUnitsDataGridRow[];
  setRows: React.Dispatch<React.SetStateAction<DisplayUnitsDataGridRow[]>>;
  displayUnitOptions: string[];
}

export interface UnitPickerDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface UnitGroup {
  group: string;
  units: string[];
}

export interface UnitInfo {
  label: string;
  group: string;
}
