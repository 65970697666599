export function selectProgramById(state, id) {
  const selectedProgram = state.programs.find(
    (program: any) => program.id === id
  );
  return selectedProgram;
}

export function selectTeamMembersByRole(selectedProgram) {
  const teamMembersByRole = {};
  selectedProgram.teamMembers.forEach((teamMember) => {
    if (teamMembersByRole[teamMember.role]) {
      teamMembersByRole[teamMember.role].push(teamMember);
    } else {
      teamMembersByRole[teamMember.role] = [teamMember];
    }
  });

  return teamMembersByRole;
}

export function selectDocumentsByCategory(allDocs) {
  const docsByCategory = {};
  allDocs?.forEach((doc) => {
    if (docsByCategory[doc.category]) {
      docsByCategory[doc.category].push(doc);
    } else {
      docsByCategory[doc.category] = [doc];
    }
  });
  return docsByCategory;
}

export function selectTaskById(state, id) {
  const isTheTaskAvalaible = state.selectedProgramTasks.allIds.includes(id);
  const selectedTask = isTheTaskAvalaible
    ? state.selectedProgramTasks.byId[id]
    : null;
  return selectedTask;
}
