import { useTypedSelector } from "store";
import React from "react";
import styled from "styled-components";
import { UserCard } from "components";
import LoaderWrapper from "containers/LoaderWrapper/LoaderWrapper.container";

export function getUserList(carrierUsers, users) {
  return users
    .map((user) => {
      if (!user || !carrierUsers?.byId) return null;

      const { id, role } = user;
      const carrierUser = carrierUsers.byId[id];
      if (!carrierUser) return null;

      const { firstName, lastName } = carrierUser;

      if (!firstName || !lastName) return null;

      return { ...carrierUser, role };
    })
    .filter((user) => user);
}

export default function UserList() {
  const { getting, users: userState } = useTypedSelector(
    (state) => state.products
  );
  const { users } = userState;
  const { users: carrierUsers } = useTypedSelector((state) => state.users);

  if (getting || !carrierUsers) return <LoaderWrapper loading={true} />;

  if (!users?.length) return <>No users to display</>;

  const userList = getUserList(carrierUsers, users);

  if (!userList?.length) return <>No users to display</>;

  return userList.map((user) => {
    if (!user) return <></>;
    const { firstName, lastName, userID, role } = user;
    return (
      user && (
        <StyledUserCard
          firstName={firstName}
          lastName={lastName}
          role={role}
          key={userID}
          id={userID}
        />
      )
    );
  });
}
const StyledUserCard = styled(UserCard)<any>`
  margin: 0 10px 15px 0;
`;
