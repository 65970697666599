import { toast } from "react-toastify";
import {
  ProductDto,
  GetProductParams,
  ProductLinePVADto,
} from "store/reducers/products/products.types";
import { encodeParams, getUserId } from "./helpers";
import {
  API_ERROR_DEFAULT,
  API_ERROR_NO_SERVER_RESPONSE,
  API_ERROR_SENDING_REQUEST,
} from "./error";
import {
  Ruleset,
  Tag,
  PropertyValueAttribute,
} from "store/reducers/products/parameters/pl-parameters.types";
import { Property } from "store/reducers/products/properties/pl-properties.types";
import {
  RulesTable,
  TablesTypeEnum,
} from "store/reducers/products/rulestables/pl-rulestables.types";
import { newPropertyPriorityList } from "../store/reducers/products/propertyPriorities/pl-propertyPriorities.types";
import { Property as PropertyPP } from "../store/reducers/products/propertyPriorities/pl-propertyPriorities.types";
import axiosInstance from "./interceptor";
import { AxiosError } from "axios";

class API {
  _getHeaders() {
    //Add the user ID to the headers.  API uses this to determine what
    //productlines the user has permissions to view/modify
    return {
      "X-user-id": getUserId(),
    };
  }

  // private methods
  async _getRequest(
    endpoint: string,
    params?: any,
    displayError = true,
    returnError = true
  ) {
    try {
      const encodedParams = encodeParams(params);
      const response = await axiosInstance.get(endpoint + "?" + encodedParams, {
        headers: this._getHeaders(),
      });

      return response;
    } catch (error) {
      const axiosError = error as AxiosError;

      //If we are flagged to display error, display using toast
      if (displayError) {
        if (axiosError.response) {
          // Error response received
          toast.error(axiosError?.response?.data || API_ERROR_DEFAULT);
        } else if (axiosError.request) {
          // No response received
          toast.error(API_ERROR_NO_SERVER_RESPONSE);
        } else {
          // Error setting up the request
          toast.error(API_ERROR_SENDING_REQUEST + ": " + axiosError.message);
        }
      }

      //If we aren't displaying the error, we have the option to return it.
      if (returnError) return axiosError;
    }
  }

  async _postRequest(
    endpoint: string,
    body: any,
    displayError = true,
    returnError = false
  ) {
    try {
      const response = await axiosInstance.post(endpoint, body, {
        headers: this._getHeaders(),
      });

      return response;
    } catch (error) {
      const axiosError = error as AxiosError;

      //If we are flagged to display error, display using toast
      if (displayError) {
        if (axiosError.response) {
          // Error response received
          toast.error(axiosError?.response?.data || API_ERROR_DEFAULT);
        } else if (axiosError.request) {
          // No response received
          toast.error(API_ERROR_NO_SERVER_RESPONSE);
        } else {
          // Error setting up the request
          toast.error(API_ERROR_SENDING_REQUEST + ": " + axiosError.message);
        }
      }

      //If we aren't displaying the error, we have the option to return it.
      if (returnError) return axiosError;
    }
  }

  async _putRequest(
    endpoint: string,
    body: any,
    displayError = true,
    returnError = false
  ) {
    try {
      const response = await axiosInstance.put(endpoint, body, {
        headers: this._getHeaders(),
      });

      return response;
    } catch (error) {
      const axiosError = error as AxiosError;

      //If we are flagged to display error, display using toast
      if (displayError) {
        if (axiosError.response) {
          // Error response received
          toast.error(axiosError?.response?.data || API_ERROR_DEFAULT);
        } else if (axiosError.request) {
          // No response received
          toast.error(API_ERROR_NO_SERVER_RESPONSE);
        } else {
          // Error setting up the request
          toast.error(API_ERROR_SENDING_REQUEST + ": " + axiosError.message);
        }
      }

      //If we aren't displaying the error, we have the option to return it.
      if (returnError) return axiosError;
    }
  }

  async _deleteRequest(
    endpoint: string,
    body?: any,
    params?: any,
    displayError = true,
    throwError = true
  ) {
    try {
      const encodedParams = encodeParams(params);
      const response = await axiosInstance.delete(
        endpoint + "?" + encodedParams,
        {
          headers: this._getHeaders(),
          data: body,
        }
      );

      return response;
    } catch (error) {
      const axiosError = error as AxiosError;

      //If we are flagged to display error, display using toast
      if (displayError) {
        if (axiosError.response) {
          // Error response received
          toast.error(axiosError?.response?.data || API_ERROR_DEFAULT);
        } else if (axiosError.request) {
          // No response received
          toast.error(API_ERROR_NO_SERVER_RESPONSE);
        } else {
          // Error setting up the request
          toast.error(API_ERROR_SENDING_REQUEST + ": " + axiosError.message);
        }
      }

      //If we aren't displaying the error, we have the option to return it.
      if (throwError) throw error;
    }
  }

  async _putFile(endpoint: string, file: Blob) {
    const imgBody = new FormData();
    imgBody.append("file", file, "cover-image.png");

    return axiosInstance.put(endpoint, imgBody, {
      headers: {
        ...this._getHeaders(),
        "Content-Type": "multipart/form-data",
      },
    });
  }

  // application API
  async getApplicationInfo(): Promise<any> {
    return this._getRequest("/Application");
  }

  // products API
  async getProducts(params?: GetProductParams) {
    return this._getRequest("/productlines", params);
  }

  async createProduct({ name, region, description }: ProductDto) {
    return this._postRequest("/productlines", {
      name,
      region,
      description,
    });
  }

  async addOrUpdateCoverImageProductLine(coverImage: Blob, productLineId) {
    return this._putFile(
      `/ProductLines/${productLineId}/Cover-Image`,
      coverImage
    );
  }

  async getProductLineImage(productLineId: string): Promise<any> {
    return this._getRequest(`/ProductLines/${productLineId}/Cover-Image`);
  }

  async getProductLine(id: string, displayError = true) {
    console.log("getProductLine api");
    return this._getRequest(`/productlines/${id}`, null, displayError);
  }

  async updateProductLIne(id: string, body: any) {
    return this._putRequest(`/productlines/${id}`, body);
  }

  async deleteProductLine(id: string) {
    return this._deleteRequest(`/productlines/${id}`);
  }

  async addUserToProductLine(productLineId, userID: string) {
    return this._putRequest(`/productlines/${productLineId}/Users`, {
      Id: userID.toString(),
    });
  }

  async updateProductLineUser(
    productLineId: string,
    userId: string,
    body: any
  ): Promise<any> {
    return this._putRequest(
      `/productlines/${productLineId}/users/${userId}`,
      body
    );
  }

  async removeUserFromProductLine(productLineId, userID: string) {
    return this._deleteRequest(`/productlines/${productLineId}/Users`, {
      Id: userID,
    });
  }

  async getProductLinePVA(productLineId) {
    return this._getRequest(
      `/productlines/${productLineId}/properties-value-attributes`
    );
  }

  async createProductLinePVA(
    { name, dataType }: ProductLinePVADto,
    productLineId
  ) {
    return this._postRequest(
      `/productlines/${productLineId}/properties-value-attributes`,
      {
        name,
        dataType,
      }
    );
  }

  async createStandardPVAs(productLineId) {
    return this._postRequest(
      `/productlines/${productLineId}/properties-value-attributes/CreateStandardPVAs`,
      null
    );
  }

  async changeProductLinePVA(
    { name, type, id }: PropertyValueAttribute,
    productLineId: string
  ) {
    return this._putRequest(
      `/productlines/${productLineId}/properties-value-attributes/${id}`,
      { name, dataType: type }
    );
  }

  async deleteProductLinePVA(productLineId, propertyValueAttributeId) {
    return this._deleteRequest(
      `/productlines/${productLineId}/properties-value-attributes/${propertyValueAttributeId}`
    );
  }

  async createProductLineRuleset(ruleSet: Ruleset, productLineId) {
    return this._postRequest(
      `/productlines/${productLineId}/ruleSets`,
      ruleSet
    );
  }

  async deleteProductLineRuleset(productLineId: string, ruleSetId: string) {
    return this._deleteRequest(
      `/productlines/${productLineId}/ruleSets/${ruleSetId}`
    );
  }

  async changeProductLineRuleset(
    { name, description, id }: Ruleset,
    productLineId: string
  ) {
    return this._putRequest(`/productlines/${productLineId}/ruleSets/${id}`, {
      name,
      description,
    });
  }

  async createProductLineTag(tag: Tag, productLineId) {
    return this._postRequest(`/productlines/${productLineId}/tags`, tag);
  }

  async createProductLineStandardTags(productLineId) {
    return this._postRequest(
      `/productlines/${productLineId}/tags/CreateStandardTags`,
      null
    );
  }

  async deleteProductLineTag(productLineId: string, tagId: string) {
    return this._deleteRequest(`/productlines/${productLineId}/tags/${tagId}`);
  }

  async getProductLineTags(productLineId) {
    return this._getRequest(`/productlines/${productLineId}/tags`);
  }

  async getProductLineTagsCount(productLineId) {
    return this._getRequest(`/productlines/${productLineId}/tags/count`);
  }

  async changeProductLineTag(
    { name, description, category, id }: Tag,
    productLineId: string
  ) {
    return this._putRequest(
      `/productlines/${productLineId}/tags/UpdateWithCount/${id}`,
      {
        name,
        description,
        category,
      }
    );
  }

  async getProductLineRulesets(productLineId) {
    return this._getRequest(`/productlines/${productLineId}/ruleSets`);
  }

  async exportProductLineRuleEngineJson(
    productLineId,
    sortedOutput = false
  ): Promise<any> {
    return this._getRequest(
      `/productlines/${productLineId}/ExportRuleEngineJson`,
      { sortedOutput }
    );
  }

  async importProductLineRuleEngineJson(formData: any) {
    return this._postRequest(`/productlines/ImportRuleEngineJson`, formData);
  }

  async exportProductLineDatabaseJson(productLineId): Promise<any> {
    return this._getRequest(
      `/productlines/${productLineId}/ExportDatabaseJson`
    );
  }

  async importProductLineDatabaseJson(
    file: any,
    name: string,
    region: string,
    conflictAction: string
  ): Promise<any> {
    //Create a form data object containing the file
    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", name);
    formData.append("region", region);
    formData.append("conflictAction", conflictAction);

    return this._postRequest(
      `/productlines/ImportDatabaseJson`,
      formData,
      true,
      true
    );
  }

  async validateProductLine(productLineId): Promise<any> {
    return this._postRequest(`/productlines/${productLineId}/validate`, null);
  }

  // products/properties
  async getProperties(
    productLineId,
    name?: string,
    dataTypes?: any,
    tags?: any,
    ruleSets?: any,
    domains?: any,
    includePVAProperties?: boolean,
    includeSubProperties?: boolean,
    items?: number,
    page?: number,
    exclusiveRulesetFilter?: boolean,
    regexNameMatch?: boolean
  ): Promise<any> {
    return this._getRequest(`/productlines/${productLineId}/properties`, {
      name,
      dataTypes,
      tags,
      ruleSets,
      domains,
      includePVAProperties,
      includeSubProperties,
      items,
      page,
      exclusiveRulesetFilter,
      regexNameMatch,
    });
  }

  async getPLSubProperties(productLineId, PLPropertyId): Promise<any> {
    return this._getRequest(
      `/productlines/${productLineId}/properties/${PLPropertyId}/sub-properties`
    );
  }

  // products/properties
  async getProductLineProperty(productLineId, propertyId) {
    return this._getRequest(
      `/productlines/${productLineId}/properties/${propertyId}`
    );
  }

  async createProductLineProperty(
    property: Property,
    productLineId
  ): Promise<any> {
    return this._postRequest(
      `/productlines/${productLineId}/properties`,
      property
    );
  }

  async createSubProperty(property: Property, productLineId): Promise<any> {
    return this._postRequest(
      `/productlines/${productLineId}/properties`,
      property
    );
  }

  async cloneProperty(
    productLineId: string,
    propertyId: string,
    newName: string
  ): Promise<any> {
    return this._postRequest(
      `/productlines/${productLineId}/properties/${propertyId}/clone`,
      { newName }
    );
  }

  async editProperty(
    productLineId,
    propertyId,
    property: Property
  ): Promise<any> {
    return this._putRequest(
      `/productlines/${productLineId}/properties/${propertyId}`,
      property
    );
  }

  async addPvaRowToProperty(propertyId, pvaRow, productLineId): Promise<any> {
    return this._postRequest(
      `/productlines/${productLineId}/properties/${propertyId}/property-values`,
      pvaRow
    );
  }

  async addPvasToProperty(propertyId, pvas, productLineId): Promise<any> {
    return this._postRequest(
      `/productlines/${productLineId}/properties/${propertyId}/properties-value-attributes`,
      pvas
    );
  }

  async editPropertyPvaCell(
    { propertyId, productLineId, propertyValueId, propertyValueAttributeId },
    newBody
  ): Promise<any> {
    return this._putRequest(
      `/productlines/${productLineId}/properties/${propertyId}/property-values/${propertyValueId}/property-value-attributes/${propertyValueAttributeId}`,
      newBody
    );
  }

  async removePvasFromProperty(propertyId, pvas, productLineId): Promise<any> {
    return this._deleteRequest(
      `/productlines/${productLineId}/properties/${propertyId}/properties-value-attributes`,
      pvas
    );
  }

  async removeProperty(
    productLineId,
    propertyId: string,
    params = { force: false }
  ) {
    return this._deleteRequest(
      `/productlines/${productLineId}/properties/${propertyId}`,
      {},
      params
    );
  }

  async removePropertyValueRowFromProperty(
    productLineId: string,
    propertyId: string,
    propertyValueId: string
  ) {
    return this._deleteRequest(
      `/productlines/${productLineId}/properties/${propertyId}/property-values/${propertyValueId}`
    );
  }

  async reOrderPropertyValueRows(
    propertyId,
    productLineId,
    propertyValueId,
    position: number
  ): Promise<any> {
    return this._putRequest(
      `productlines/${productLineId}/properties/${propertyId}/property-values/${propertyValueId}`,
      {
        position,
      }
    );
  }

  async validateProperties(productLineId): Promise<any> {
    return this._postRequest(
      `/productlines/${productLineId}/properties/validate`,
      null
    );
  }

  // products/candidates
  async getCandidateSelection(productLineId: string) {
    return this._getRequest(
      `/productlines/${productLineId}/candidate-selection`
    );
  }

  async modifyCandidateSelection(productLineId: string, candidateSel: any) {
    return this._putRequest(
      `/productlines/${productLineId}/candidate-selection`,
      candidateSel
    );
  }

  async createCandidateSelection(productLineId: string) {
    return this._postRequest(
      `/productlines/${productLineId}/candidate-selection`,
      {}
    );
  }

  // products/metadata
  async getMetadata(productLineId: string) {
    return this._getRequest(`/productlines/${productLineId}/meta-data-db`);
  }

  async modifyMetadata(productLineId: string, metadata: any) {
    return this._putRequest(
      `/productlines/${productLineId}/meta-data-db`,
      metadata
    );
  }

  async createMetadata(productLineId: string) {
    return this._postRequest(`/productlines/${productLineId}/meta-data-db`, {});
  }

  //products/rules-tables

  async getRulesTables(
    productLineId: string,
    items: number | string,
    page: number,
    tableTypes?: TablesTypeEnum[],
    rulesetIds?: string[],
    propertyIds?: string[]
  ): Promise<any> {
    return this._getRequest(`/productlines/${productLineId}/rule-tables`, {
      tableTypes,
      rulesetIds,
      propertyIds,
      items,
      page,
    });
  }

  async getRuleTable(productLineId: string, ruletableId): Promise<any> {
    return this._getRequest(
      `productlines/${productLineId}/rule-tables/${ruletableId}`
    );
  }

  async createProductLineRulesTable(
    rulesTable: RulesTable,
    productLineId
  ): Promise<any> {
    return this._postRequest(
      `/productlines/${productLineId}/rule-tables`,
      rulesTable
    );
  }

  async deleteRuleTable(productLineId, ruleTableId) {
    return this._deleteRequest(
      `/productlines/${productLineId}/rule-tables/${ruleTableId}`
    );
  }

  async exportRuleTableToTSV(productLineId, ruleTableId) {
    return this._getRequest(
      `/productlines/${productLineId}/rule-tables/${ruleTableId}/ExportToTSV`
    );
  }

  async importRuleTableFromTSV(productLineId, formData: any) {
    const endpoint = `/productlines/${productLineId}/rule-tables/ImportFromTSV`;

    //Not calling the usual _postRequest helper method here because
    //we want to NOT handle any errors in the usual way.  Import table
    //function will have a dedicated dialog to display any errors to the
    //user so the error handling is one level up.
    const response = await axiosInstance.post(endpoint, formData, {
      headers: this._getHeaders(),
    });
    return response;
  }

  async editProductLineRulesTable(
    ruleTableId,
    productLineId,
    ruleTable: RulesTable
  ): Promise<any> {
    return this._putRequest(
      `/productlines/${productLineId}/rule-tables/${ruleTableId}`,
      ruleTable
    );
  }

  async reOrderProductLineRulesTableRows(
    ruleTableId,
    productLineId,
    rowId,
    position: number
  ): Promise<any> {
    return this._putRequest(
      `productlines/${productLineId}/rule-tables/${ruleTableId}/rows/${rowId}`,
      {
        position,
      }
    );
  }

  async editRuleTableCell(
    productLineId: string,
    ruleTableId: string,
    rowId: string,
    cellId: string,
    value
  ) {
    return this._putRequest(
      `/productlines/${productLineId}/rule-tables/${ruleTableId}/rows/${rowId}/cells/${cellId}`,
      value
    );
  }

  async addPropertiesRowToRuleTable(
    ruleTableId,
    propertiesRow,
    productLineId
  ): Promise<any> {
    return this._postRequest(
      `/productlines/${productLineId}/rule-tables/${ruleTableId}/rows`,
      propertiesRow
    );
  }

  async deleteRuleTableRow(productLineId, ruleTableId, rowId) {
    return this._deleteRequest(
      `/productlines/${productLineId}/rule-tables/${ruleTableId}/rows/${rowId}`
    );
  }

  async addNewPropertyToRuleTable(
    ruleTableId,
    properties,
    productLineId
  ): Promise<any> {
    return this._postRequest(
      `/productlines/${productLineId}/rule-tables/${ruleTableId}/properties`,
      properties
    );
  }

  async addNewPropertyPvaToRuleTable(
    productLineId,
    ruleTableId,
    propertyId,
    pvaId
  ): Promise<any> {
    return this._postRequest(
      `/productlines/${productLineId}/rule-tables/${ruleTableId}/pva-column`,
      { propertyId, pvaId }
    );
  }

  async removePropertyFromRuleTable(
    productLineId,
    ruleTableId,
    propertiesId
  ): Promise<any> {
    return this._deleteRequest(
      `/productlines/${productLineId}/rule-tables/${ruleTableId}/properties`,
      propertiesId
    );
  }

  async validateRuleTables(productLineId): Promise<any> {
    return this._postRequest(
      `/productlines/${productLineId}/rule-tables/validate`,
      null
    );
  }

  //products/validation

  async getValidation(
    productLineId,
    objectId = "",
    objectAssigned = ""
  ): Promise<any> {
    return this._getRequest(`/productlines/${productLineId}/validations`, {
      objectId,
      objectAssigned,
    });
  }

  async addValidation(productLineId, body): Promise<any> {
    return this._postRequest(`productlines/${productLineId}/validations`, body);
  }

  async removeValidation(productLineId, validationId): Promise<any> {
    return this._deleteRequest(
      `productlines/${productLineId}/validations/${validationId}`
    );
  }

  async validateAllAsync(productLineId): Promise<any> {
    return this._postRequest(
      `/productlines/${productLineId}/validations/validateAllAsync`,
      null
    );
  }

  // property priorities

  async getPropertyPrioritiesList(productLineId): Promise<any> {
    return this._getRequest(
      `/productlines/${productLineId}/property-priorities`
    );
  }

  async addPropertyPrioritiesList(
    productLineId: string,
    propertyPriorityListObject: newPropertyPriorityList
  ): Promise<any> {
    return this._postRequest(
      `/productlines/${productLineId}/property-priorities`,
      propertyPriorityListObject
    );
  }

  async addPropertiesToPropertyPriorityList(
    productLineId,
    propertyPrioritiesId,
    properties
  ): Promise<any> {
    return this._postRequest(
      `productlines/${productLineId}/property-priorities/${propertyPrioritiesId}/filtering-properties`,
      properties
    );
  }

  async removePropertyPrioritiesList(
    productLineId: string,
    propertyPriorityListId
  ): Promise<any> {
    return this._deleteRequest(
      `/productlines/${productLineId}/property-priorities/${propertyPriorityListId}`
    );
  }

  async editPropertyInPriorityList(
    productLineId: string,
    propertyPrioritiesId: string,
    editPropertyObject: Partial<PropertyPP>
  ): Promise<any> {
    return this._putRequest(
      `productlines/${productLineId}/property-priorities/${propertyPrioritiesId}/filtering-properties`,
      editPropertyObject
    );
  }

  async editPropertyPriorityList(
    productLineId: string,
    propertyPrioritiesId: string,
    newPropertyList: any
  ): Promise<any> {
    return this._putRequest(
      `productlines/${productLineId}/property-priorities/${propertyPrioritiesId}`,
      newPropertyList
    );
  }

  async removePropertyInPriorityList(
    productLineId: string,
    propertyPrioritiesId: string,
    propertyIdsToRemove: string[]
  ): Promise<any> {
    return this._deleteRequest(
      `productlines/${productLineId}/property-priorities/${propertyPrioritiesId}/filtering-properties`,
      propertyIdsToRemove
    );
  }

  // logic groups

  async getLogicGroups(
    productLineId: string,
    parentLogicGroupId?: string
  ): Promise<any> {
    return this._getRequest(`productlines/${productLineId}/logic-groups`, {
      parentLogicGroupId,
    });
  }

  async getLogicGroup(
    productLineId: string,
    logicGroupId: string
  ): Promise<any> {
    return this._getRequest(`productlines/${productLineId}/${logicGroupId}`);
  }

  async createLogicGroup(
    productLineId: string,
    newLogicGroup: any
  ): Promise<any> {
    return this._postRequest(
      `productlines/${productLineId}/logic-groups`,
      newLogicGroup
    );
  }

  // Processes

  async getProcess(processId): Promise<any> {
    return this._getRequest(`/processes/${processId}`);
  }

  // programs API
  // ...

  // notifications API
  // ...
}

export const api = new API();
