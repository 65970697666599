import React from "react";
import colors from "theme/colors";
import styled from "styled-components";

export function SearchIcon({
  color = colors.secondary,
  className,
}: {
  color?: string;
  className?: any;
}) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M19 19L15.242 15.242M17 11C17 14.3137 14.3137 17 11 17C7.68629 17 5 14.3137 5 11C5 7.68629 7.68629 5 11 5C14.3137 5 17 7.68629 17 11Z"
        stroke={color}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function AddCircle({ color = colors.secondary }: { color?: string }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0ZM12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM12.8333 6.16667L12.8327 11.1657L17.8333 11.1667V12.8333L12.8327 12.8327L12.8333 17.8333H11.1667V12.8327L6.16667 12.8333V11.1667L11.1667 11.1657V6.16667H12.8333Z"
        fill={color}
      />
    </svg>
  );
}

export function ArrowDown({ color = colors.secondary }: { color?: string }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.38496 9.31312L5.68696 7.79512L12.795 13.8881L19.903 7.79512L21.205 9.31312L13.445 15.9641C13.071 16.2851 12.518 16.2851 12.144 15.9641L4.38496 9.31312Z"
        fill={color}
      />
    </svg>
  );
}

export function ArrowRight({
  color = colors.secondary,
  className,
  size = "24",
}: {
  color?: string;
  className?: any;
  size?: string;
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.108 20.41L8.58997 19.108L14.683 12L8.58997 4.892L10.108 3.59L16.759 11.35C17.08 11.724 17.08 12.277 16.759 12.651L10.108 20.41Z"
        fill={color}
      />
    </svg>
  );
}

export function ArrowLeft({
  color = colors.secondary,
  className,
}: {
  color?: string;
  className?: any;
}) {
  return (
    <svg
      width="9"
      height="18"
      viewBox="0 0 9 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.89167 17.41L8.40967 16.108L2.31667 9L8.40967 1.892L6.89167 0.589996L0.240667 8.35C-0.0803328 8.724 -0.0803328 9.277 0.240667 9.651L6.89167 17.41Z"
        fill={color}
      />
    </svg>
  );
}

export function ArrowUp({ color = colors.secondary }: { color?: string }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.38496 14.6869L5.68696 16.2049L12.795 10.1119L19.903 16.2049L21.205 14.6869L13.445 8.03588C13.071 7.71488 12.518 7.71488 12.144 8.03588L4.38496 14.6869Z"
        fill={color}
      />
    </svg>
  );
}

export function Calendar({ className }: { className?: string }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 5H4H2V7V19V21H4H20H22V19V7V5H20H17.5V3H15.5V5H8.5V3H6.5V5ZM4 7H20V9.5H4V7ZM4 11.5V19H20V11.5H4ZM11.5 14.5V13H12.5V14.5H14V15.5H12.5V17H11.5V15.5H10V14.5H11.5Z"
      />
    </svg>
  );
}

export const CalendarIcon = styled(Calendar)`
  path {
    fill: ${(props) => props.theme.colors.secondary};
  }
`;

export function ChangeLog({ color = colors.secondary }: { color?: string }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 4C19.6 4 20 4.4 20 5V19C20 19.6 19.6 20 19 20H5C4.4 20 4 19.6 4 19V5C4 4.4 4.4 4 5 4H19ZM18 6H6V18H18V6ZM16 13V15H8V13H16ZM16 9V11H8V9H16Z"
        fill={color}
      />
    </svg>
  );
}

export function Phone({ color = colors.secondary }: { color?: string }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.84999 3.79798L4.82805 4.59742C4.17834 4.76587 3.73131 5.36113 3.7506 6.03214C3.95383 9.7522 5.49228 13.2536 8.08102 15.9054C10.7456 18.508 14.2474 20.047 17.9485 20.2491C18.6375 20.2725 19.236 19.824 19.4035 19.1713L20.2017 16.149C20.3846 15.4609 20.0349 14.7415 19.3798 14.4598L15.5325 12.8101L15.4166 12.7657C14.9092 12.5964 14.3424 12.7242 13.9548 13.106L12.7748 14.2698L12.6001 14.1533C12.0601 13.7783 11.5508 13.3604 11.077 12.9034L10.8151 12.6216C10.4706 12.2389 10.1459 11.8287 9.8464 11.3973L9.72911 11.2229L10.8918 10.0438C11.3015 9.62758 11.4191 9.00532 11.1897 8.46853L9.53936 4.62122C9.25769 3.96581 8.5391 3.61579 7.84999 3.79798ZM8.18191 5.26125L9.79436 9.01993L7.83157 11.0121L8.13337 11.5113C8.66812 12.3958 9.29944 13.2181 10.0157 13.9631C10.7799 14.7011 11.6025 15.333 12.488 15.867L12.9871 16.168L14.9767 14.2038L18.7378 15.8172L17.9639 18.7471L18.0145 18.7505C14.6921 18.5689 11.5335 17.1808 9.14161 14.8448L8.9198 14.6121C6.80401 12.34 5.51381 9.40311 5.27055 6.29204L5.25325 6.03609L8.18191 5.26125Z"
        fill={color}
      />
    </svg>
  );
}

export function PaperPlane({ color = colors.secondary }: { color?: string }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.507 3.28944L17.0021 20.814L11.1649 16.4362L7.50005 20.8142L7.49967 13.6874L2.98242 10.2993L20.507 3.28944ZM18.3604 6.37119L9.59442 13.3837L15.9976 18.186L18.3604 6.37119ZM8.99967 14.8124L9.00005 16.6853L9.96267 15.5347L8.99967 14.8124ZM15.0792 7.07544L6.0173 10.7003L8.35542 12.4544L15.0792 7.07544Z"
        fill={color}
      />
    </svg>
  );
}

function Filter({ className }: { className: string }) {
  return (
    <svg
      width="12"
      height="10"
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 8V10H4V8H8ZM10 4V6H2V4H10ZM12 0V2H0V0H12Z"
      />
    </svg>
  );
}

export const FilterIcon = styled(Filter)<any>`
  path {
    fill: ${(props) => props.theme.colors.secondary};
  }
`;

export function Edit({ color = colors.secondary }: { color?: string }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8 0.585785L19.4142 5.2L6.49299 18.1212L0.72522 19.2748L1.87877 13.507L14.8 0.585785ZM11.3992 6.81379L3.72097 14.493L3.27397 16.725L5.50597 16.279L13.1852 8.59978L11.3992 6.81379ZM14.8 3.415L12.8132 5.39979L14.5992 7.18578L16.585 5.2L14.8 3.415Z"
        fill={color}
      />
    </svg>
  );
}

export function CloseProgram({ color = colors.secondary }: { color?: string }) {
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.57254 0L8.257 2.4H18V5.2H16V4.4H7.21693L5.533 2H2V15.3969C2.00091 15.6886 2.24556 15.9475 2.58314 15.9929L2.68705 16L2.997 16.001C3.30414 15.9991 3.54613 15.7959 3.59211 15.5462L3.6 15.4615V6H20V15.4646C19.9956 16.8721 18.8269 17.9959 17.4 18H3.681L3.68137 18.0028L2.68137 18C1.26993 17.996 0.0979224 16.934 0.00581551 15.5656L0 15.4V0H6.57254ZM18 8H5.6L5.6 15.4646C5.59947 15.6335 5.58218 15.7983 5.54969 15.9576L5.539 16H17.3971C17.7041 15.9991 17.9461 15.7959 17.9921 15.5462L18 15.4615V8Z"
        fill={color}
      />
    </svg>
  );
}

export function Check({ color = colors.secondary }: { color?: string }) {
  return (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9999 0.585785L15.4141 2L5.99991 11.4142L0.585693 6L1.99991 4.58579L5.99991 8.585L13.9999 0.585785Z"
        fill={color}
      />
    </svg>
  );
}

export function PauseProgram({ color = colors.secondary }: { color?: string }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0ZM10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2ZM8.5 6V14H6.5V6H8.5ZM13.5 6V14H11.5V6H13.5Z"
        fill={color}
      />
    </svg>
  );
}

export function Upload({ color = colors.secondary }: { color?: string }) {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 14V16H0V14H14ZM7 0L12.7071 5.70711L11.2929 7.12132L7.99989 3.829L8 12.4142H6L5.99989 3.829L2.70711 7.12132L1.29289 5.70711L7 0Z"
        fill={color}
      />
    </svg>
  );
}

export function Plus({ color = colors.secondary }: { color?: string }) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0V6H14V8H7.999L8 14H6L5.999 7.999L0 8V6L6 5.999V0H8Z"
        fill={color}
      />
    </svg>
  );
}

export function Minus({ color = colors.secondary }: { color?: string }) {
  return (
    <svg
      width="14"
      height="2"
      viewBox="0 0 14 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 0V2H0V0H14Z"
        fill={color}
      />
    </svg>
  );
}

export function Link({ color = colors.secondary }: { color?: string }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.47221 8L5.73744 9.26533L2.80874 12.1942C1.44822 13.5883 1.44822 15.8133 2.79337 17.192C4.13929 18.5057 6.25973 18.551 7.6521 17.3348L7.79843 17.1996L10.7348 14.2631L12 15.5284L9.05593 18.4726C6.96685 20.5117 3.63268 20.5117 1.52823 18.4572C-0.457026 16.423 -0.50927 13.2082 1.37855 11.1047L1.53587 10.9366L4.47221 8ZM13.694 5L15 6.30602L6.30602 15L5 13.694L13.694 5ZM18.4718 1.54277C20.457 3.57702 20.5093 6.79181 18.6214 8.89535L18.4641 9.06342L15.5278 12L14.2626 10.7347L17.1913 7.80583C18.5518 6.41173 18.5518 4.18673 17.2066 2.808C15.8607 1.49429 13.7403 1.44899 12.3479 2.66522L12.2016 2.80036L9.26523 5.73694L8 4.47161L10.9441 1.52739C13.0331 -0.511686 16.3673 -0.511686 18.4718 1.54277Z"
        fill={color}
      />
    </svg>
  );
}
