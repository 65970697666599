import styled from "styled-components";
import React from "react";
import { Loader1 } from "components";

/**
 * Wrapper with integrated loading
 * TODO: we need to add smooth transition
 */
export default function LoaderWrapper({
  children,
  loading,
  Loader = () => <Loader1 />,
}: {
  children?: any;
  loading: boolean;
  Loader?: any;
}) {
  if (loading) {
    return (
      <Wrapper>
        <Loader />
      </Wrapper>
    );
  }

  return <>{children}</>;
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 72px;
`;
