export const mockedTasks = {
  byId: {
    1: {
      name: "Add new voltage options",
      milestone: "Milestone 1",
      details: "Task Details",
      taskOwners: ["8", "34", "35"],
      taskManager: ["8", "34", "35"],
      dueDate: 1636537111000,
      id: "1",
      status: "late",
    },
    2: {
      name: "Add new voltage options 2",
      milestone: "Milestone 2",
      details: "Task Details 2",
      taskOwner: ["8", "34", "35"],
      taskManager: ["8", "34", "35"],
      dueDate: 1636537111000,
      id: "2",
      status: "completed",
    },
  },
  allIds: ["1", "2"],
};
