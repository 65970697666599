import React from "react";
import styled from "styled-components";
import { Check } from "assets/logos/icons/MediumIcons";

export default function Checkbox({
  checked,
  borderColor,
  backgroundColor,
  onClick,
  disabled = false,
}: {
  checked?: boolean;
  borderColor?: string;
  backgroundColor?: string;
  onClick?: any;
  disabled?: boolean;
}) {
  return (
    <CheckboxContainer
      onClick={disabled ? () => null : onClick}
      disabled={disabled}
    >
      <HiddenCheckbox checked={checked} onChange={() => null} />
      <StyledCheckbox
        checked={checked}
        borderColor={borderColor}
        backgroundColor={backgroundColor}
      >
        {checked && <Check />}
      </StyledCheckbox>
    </CheckboxContainer>
  );
}

const StyledCheckbox = styled.div<any>`
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  border: 1px solid
    ${(props) =>
      props.borderColor ? props.borderColor : props.theme.colors.secondary};
  background-color: ${(props) =>
    props.backgroundColor
      ? props.backgroundColor
      : props.theme.colors.background.primaryLabels};
`;

const CheckboxContainer = styled.div<any>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  clip: rect(0, 0, 0, 0);
  clip-path: inset(50%);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;
