import { Notification } from "./notifications.types";

// Actions constants
export const SET_NOTIFICATION = "notifications/SET_NOTIFICATION";

// Action creators
export const setNotificationAction = (notification: Notification) => {
  return {
    type: SET_NOTIFICATION,
    payload: notification,
  };
};
