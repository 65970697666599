import React from "react";
import styled from "styled-components";

export function ToolTip({
  text = "",
  children,
  style,
  disabled = false,
  width,
}: {
  text: string;
  children: any;
  style?: any;
  disabled?: boolean;
  width?: string;
}) {
  return (
    <Tooltip style={style} width={width}>
      {children}
      {disabled && <TooltipText className="tooltip-text">{text}</TooltipText>}
    </Tooltip>
  );
}

const Tooltip = styled.div<any>`
  position: relative;
  display: inline-block;
  width: ${(props) => props.width};

  :hover .tooltip-text {
    visibility: visible;
    transition: opacity 0.5s;
    opacity: 0.5;
  }
`;

const TooltipText = styled.span`
  visibility: hidden;
  max-width: 400px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 9px;
  border: 1px solid black;

  opacity: 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 99;
  top: 160%;
  left: 50%;
  margin-left: -60px;
`;
