import axios from "axios";
import promise from "promise";
import { logout, validateTokenExpiry } from "@carrier/auth-module";
import appConfig from "./carrierAuthApi/appConfig";
import { baseUrl } from "./baseUrl";

const axiosInstance = axios.create({
  baseURL: window.location.hostname.includes("localhost") ? "/" : baseUrl,
});

axiosInstance.interceptors.request.use(
  function (config) {
    //validate token and update if necessary
    validateTokenExpiry();

    //Add the validation token to the request headers
    const accessToken = localStorage.getItem("msal.idtoken");
    if (accessToken) {
      if (config.method !== "OPTIONS") {
        config.headers.authorization = "Bearer " + accessToken;
      }
    }
    return config;
  },
  function (error) {
    console.log(error);
    return promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    //If there was a 401 error (unauthorized) then attempt to log in again
    if (error && error.response && error.response.status === 401) {
      logout(appConfig, true);
      return;
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
