import React from "react";
import { SearchableDropdown, TypeConstrainedCell } from "components";
import colors from "theme/colors";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { editRuleTableAction } from "store/reducers/products/rulestables/pl-rulestables.actions";
import { DataTypeEnum } from "../../../../store/reducers/products/properties/pl-properties.types";

export function RulesTableSettingsInput({
  options,
  placeholder,
  currentValue,
  multiselect,
  rulesTableParameterType,
  ruleTableData,
}: {
  options?: any;
  placeholder?: any;
  currentValue: any;
  multiselect?: any;
  rulesTableParameterType:
    | "rulesetIds"
    | "tableType"
    | "propertiesDisplaying"
    | "propertiesAdding"
    | "addNewProperty"
    | "lookUpFieldIndex";
  ruleTableData?: any;
}) {
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const newRulesTable = {
      [rulesTableParameterType]: e,
    };
    dispatch(
      editRuleTableAction({
        ruleTableId: ruleTableData.id,
        ruleTable: newRulesTable,
      })
    );
  };

  if (
    rulesTableParameterType === "tableType" ||
    rulesTableParameterType === "rulesetIds"
  ) {
    return (
      <StyledDropdown
        options={options || []}
        name={rulesTableParameterType}
        noShadow
        search={false}
        noHoverBorder
        handleChange={handleChange}
        value={currentValue}
        placeholder={placeholder}
        multiple={multiselect}
        backgroundColor={colors.tables.hoveredCell}
        id={rulesTableParameterType + "customDropdownUUID"}
      />
    );
  }

  return (
    <StyledTypeConstrainedCell
      dataType={DataTypeEnum.INT}
      type="editCell"
      handleSubmit={handleChange}
      value={currentValue}
      multiple={false}
      background={"white"}
    />
  );
}

const colorsPalette = {
  propertiesDisplaying: colors.tables.hoveredCel,
  addNewProperty: colors.tables.columnsAndRowsTitles,
  tableType: colors.white,
  rulesetIds: colors.white,
  propertiesAdding: colors.white,
};

const StyledDropdown = styled(SearchableDropdown)`
  min-width: 140px !important;
  > div > div > input {
    background-color: ${(props) => colorsPalette[props.name]} !important;
    min-height: 19px !important;
    font-size: 12px !important;
    font-weight: ${(props) =>
      props.name === "addNewProperty" ? "700" : "normal"};
  }
`;

const StyledTypeConstrainedCell = styled(TypeConstrainedCell)<any>`
  width: 100%;
  height: 100%;
`;
