import React from "react";
import styled from "styled-components";

export function InputCell() {
  return <StyledInput />;
}

const StyledInput = styled.input<any>`
  text-decoration: none;
  font-size: 14px;
  color: ${(props) => props.theme.colors.primary};
  outline: none;
  border: none;
  background: none;
  padding: 0;
  max-width: 120px;

  :disabled {
    cursor: not-allowed;
  }

  :focus {
    outline: none; /* Remove default outline and use border or box-shadow */
  }
`;
