import { useEffect, useState } from "react";
import {
  DataTypeEnum,
  DomainEnum,
} from "store/reducers/products/properties/pl-properties.types";
import {
  ErrorObject,
  ValidatorNames,
} from "../../store/reducers/products/validation/validation.types";

export default function useValidateBooleanDataType({
  domain,
  dataType,
}: {
  domain: DomainEnum;
  dataType: DataTypeEnum;
}) {
  const [errorMessage, setErrorMessage] = useState<
    Pick<ErrorObject, "message" | "validatorName">
  >({
    message: null,
    validatorName: ValidatorNames.WrongDomainForBoolean,
  });

  useEffect(() => {
    if (
      dataType === DataTypeEnum.BOOL &&
      [DomainEnum.RANGE, DomainEnum.OPEN].includes(domain)
    ) {
      setErrorMessage({
        message: "wrong domain for boolean data type",
        validatorName: ValidatorNames.WrongDomainForBoolean,
      });
      return;
    }

    setErrorMessage({
      message: null,
      validatorName: ValidatorNames.WrongDomainForBoolean,
    });
  }, [domain, dataType]);

  return errorMessage;
}
