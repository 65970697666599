import React from "react";
import { TableHeader } from "./TableHeader.component";
import { TableRow } from "./TableRow.component";
import styled from "styled-components";
import { TableButton } from "components/Buttons/TableButton.component";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { reorder } from "./helper";
import { toast } from "react-toastify";

export function Table({
  data,
  canAddRow = false,
  addRowContent,
  addRowAction,
  addRowButtonLabel,
  addRowIsDisabled,
  className,
  size = "medium",
  rowDeleteAction,
  tableWidth,
  fixHeaderWidth,
  onDragEndActionHeader,
  onDragEndActionRows,
}: {
  data: any;
  canAddRow?: boolean;
  addRowAction?: any;
  addRowContent?: any;
  addRowButtonLabel?: any;
  addRowIsDisabled?: boolean;
  className?: any;
  size?: "small" | "medium";
  rowDeleteAction?: any;
  tableWidth?: string;
  // this fix some bugs with drag and drop
  fixHeaderWidth?: boolean;
  onDragEndActionHeader?: any;
  onDragEndActionRows?: any;
}) {
  if (!data) return <></>;
  const { header, rows } = data;

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    try {
      const items = reorder(
        rows,
        result.source.index,
        result.destination.index
      );

      if (!onDragEndActionRows) {
        console.error("You tried to drag items without any action specified.");
      }
      const list = Array.from(rows);
      const [target]: any = list.splice(result.source.index, 1);

      const targetId = target[0]?.rowId ?? target.id;

      onDragEndActionRows(
        items,
        targetId,
        result.destination.index,
        result.source.index
      );
    } catch (e) {
      console.error(e);
      toast.error("error while re-ordering rows");
    }
  };

  return (
    <Wrapper className={className}>
      <StyledTable
        size={size}
        tableWidth={tableWidth}
        fixHeaderWidth={fixHeaderWidth}
      >
        <TableHeader header={header} onDragEndAction={onDragEndActionHeader} />
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={"list" + "rows"}>
            {(provided, snapshot) => (
              <Tbody
                ref={provided.innerRef}
                {...provided.droppableProps}
                isDraggingOver={snapshot.isDraggingOver}
              >
                {rows &&
                  rows.map((row, index) => {
                    if (!row || row.length === 0) return <></>;
                    // row can be array or object with a content attribute
                    const rowContent = row.id ? row.content : row;
                    const uniqueId =
                      row[0]?.rowId || row[0]?.id || row.id || index;

                    return (
                      <Draggable
                        draggableId={uniqueId.toString()}
                        index={index}
                        key={uniqueId}
                        isDragDisabled={!onDragEndActionRows}
                      >
                        {(provided, snapshot) => {
                          return (
                            <TableRow
                              key={uniqueId}
                              row={rowContent}
                              rowDeleteAction={rowDeleteAction}
                              innerRef={provided.innerRef}
                              draggableProps={provided.draggableProps}
                              dragHandleProps={provided.dragHandleProps}
                              isDragging={snapshot.isDragging}
                            />
                          );
                        }}
                      </Draggable>
                    );
                  })}
                {provided.placeholder}
              </Tbody>
            )}
          </Droppable>
        </DragDropContext>

        {canAddRow && <TableRow type="addRow" row={addRowContent} />}
      </StyledTable>
      {canAddRow && (
        <TableButton
          onClick={addRowAction}
          label={addRowButtonLabel}
          disabled={addRowIsDisabled}
          style={{ marginTop: 8 }}
        />
      )}
    </Wrapper>
  );
}

const Tbody = styled.tbody`
  &:hover .row-trash-container {
    display: flex;
  }
`;

// Most of the style for Table is here
// Maybe not optimal if the style gets more complicated, usually we colocate style with the component
// TODO: re-asses if we transfer th{} and td{} to their corresponding components

const Wrapper = styled.div`
  max-width: fit-content;
`;

export const StyledTable = styled.table<any>`
  border-collapse: collapse;
  color: ${(props) => props.theme.colors.primary};
  font-size: 12px;
  box-sizing: border-box;
  table-layout: fixed;
  width: ${(props) => props.tableWidth || "auto"};

  th {
    border: 1px solid ${(props) => props.theme.colors.tables.borders};
    height: ${(props) => (props.size === "medium" ? "56px" : "40px")};
    box-sizing: border-box;
    background-color: ${(props) =>
      props.theme.colors.tables.columnsAndRowsTitles};
    font-weight: bold;
    text-align: left;
    padding-left: 16px;
    padding-right: 16px;
    table-layout: fixed;
    width: ${(props) => (props.fixHeaderWidth ? "240px !important" : "180px")};
    * {
      width: 180px !important;
    }
  }

  td {
    /* cell background color is managed directly in TableRow */
    border: 1px solid ${(props) => props.theme.colors.tables.borders};
    height: ${(props) => (props.size === "medium" ? "56px" : "40px")};
    box-sizing: border-box;
    font-weight: normal;
    padding-left: 16px;
    padding-right: 16px;
    table-layout: fixed;
  }
`;
