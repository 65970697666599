import ProductLineCard from "./ProductLineCard.component";
import React, { useEffect, useState } from "react";

export const BUCKET_IMG_BASE_URL =
  "https://productlineimages.blob.core.windows.net/images/";

export default function ProductLineCardContainer(props: ProductLineCard) {
  const [imgUrl, setImgUrl] = useState("");
  async function getImage() {
    try {
      if (imgUrl) return "";

      // if the img includes full link, just set it as it is
      if (props.img?.includes("https")) {
        setImgUrl(props.img);
        return;
      }

      // otherwise preprend the bucket url
      setImgUrl(BUCKET_IMG_BASE_URL + props.img);
    } catch (e) {
      console.error("error while fetching image");
    }
  }

  useEffect(() => {
    getImage();
  }, []);

  return <ProductLineCard {...props} displayImage={imgUrl} />;
}
