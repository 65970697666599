import React, { useState } from "react";
import styled from "styled-components";
import { Button, Input, LoaderSpinner2 } from "../../../../components";
import { useDispatch } from "react-redux";
import { addPropertyRowToRuleTable } from "../../../../store/reducers/products/rulestables/pl-rulestables.actions";
import { useTypedSelector } from "../../../../store";

export function AddMultipleRowsButton({
  ruletableData,
}: {
  ruletableData: any;
}) {
  const [inputValue, setInputValue] = useState<number | undefined>(undefined);

  const posting = useTypedSelector(
    (state) => state.products.rulestables.posting
  );

  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    if (e.target.value === "") {
      setInputValue(undefined);
      return;
    }
    setInputValue(parseInt(e.target.value.replace(/\D/, "")));
  };

  const handleSubmit = () => {
    if (!inputValue) return;
    if (inputValue > 30) {
      dispatch(addPropertyRowToRuleTable(ruletableData, 30));
      return;
    }

    dispatch(addPropertyRowToRuleTable(ruletableData, inputValue));
  };

  return (
    <Wrapper>
      <StyledButton
        size={"small"}
        onClick={handleSubmit}
        disabled={!inputValue || posting}
      >
        Add multiple rows
      </StyledButton>
      <StyledInput onChange={handleInputChange} value={inputValue} />
      {posting ? <LoaderSpinner2 /> : <InfoWrapper>max. 30</InfoWrapper>}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  gap: 8px;
`;

const StyledButton = styled(Button)<any>``;

const InfoWrapper = styled.div`
  font-size: 10px;
  color: slategrey;
`;

const StyledInput = styled(Input)<any>`
  width: 50px;
  height: 32px;
  padding: 6px 5px;
  font-size: 12px;
`;
