import { PropertyPrioritiesState } from "./pl-propertyPriorities.types";
import { SET_VERB } from "../products.actions";
import {
  ADD_NEW_PROPERTY_PRIORITIES_LIST,
  ADD_PROPERTIES_TO_PROPERTIES_PRIORITY_LIST,
  CLEAR_PROPERTY_PRIORITY_LIST,
  EDIT_PROPERTY_IN_PRIORITY_LIST,
  EDIT_PROPERTY_PRIORITY_LIST,
  REMOVE_PROPERTY_IN_PRIORITY_LIST,
  REMOVE_PROPERTY_PRIORITIES_LIST,
  SET_PROPERTY_PRIORITIES_LIST,
} from "./pl-propertyPriorities.action";

export const plPropertyPrioritiesInitialState: PropertyPrioritiesState = {
  byId: {},
  allIds: [],

  newPropertyPriority: {
    rulesetId: "",
    properties: [],
  },

  getting: false,
  posting: false,
  deleting: false,
};

export function plPropertyPrioritiesReducer(
  state: PropertyPrioritiesState = plPropertyPrioritiesInitialState,
  action: any
) {
  switch (action.type) {
    case SET_PROPERTY_PRIORITIES_LIST:
      return {
        ...state,
        byId: action.payload.newById,
        allIds: action.payload.newAllIds,
      };

    case SET_VERB: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case ADD_NEW_PROPERTY_PRIORITIES_LIST: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: action.payload,
        },
        allIds: [...state.allIds, action.payload.id],
      };
    }

    case REMOVE_PROPERTY_IN_PRIORITY_LIST:
    case EDIT_PROPERTY_IN_PRIORITY_LIST:
    case ADD_PROPERTIES_TO_PROPERTIES_PRIORITY_LIST: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: action.payload,
        },
      };
    }

    case EDIT_PROPERTY_PRIORITY_LIST:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...state.byId[action.payload.id],
            properties: action.payload.propertyList,
          },
        },
      };

    case REMOVE_PROPERTY_PRIORITIES_LIST: {
      return {
        ...state,
        byId: action.payload.newById,
        allIds: action.payload.newAllIds,
      };
    }

    case CLEAR_PROPERTY_PRIORITY_LIST:
      return plPropertyPrioritiesInitialState;

    default:
      return state;
  }
}
