export const setRestVerb = (
  reducerName: string,
  status: boolean,
  verb: "getting" | "posting" | "deleting"
) => {
  return {
    type: `${reducerName}/REST_VERB`,
    payload: {
      [verb]: status,
    },
  };
};
