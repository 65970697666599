import React from "react";
import styled from "styled-components";
import { useTypedSelector } from "store";
import { H3, H4 } from "components";
import { Button } from "components";
import { logout } from "@carrier/auth-module";
import appConfig from "api/carrierAuthApi/appConfig";

export default function SettingsPage() {
  const { preferences, firstName, lastName } = useTypedSelector(
    (state) => state.user
  );

  const { navigationOpen } = preferences;

  const handleLogout = () => {
    logout(appConfig);
  };

  return (
    <Wrapper navigationOpen={navigationOpen}>
      <AccountWrapper>
        <H3>Account Information</H3>
        <H4>
          {firstName} {lastName}
        </H4>
        <LogoutButton onClick={handleLogout} buttonType="submit">
          Logout
        </LogoutButton>
      </AccountWrapper>
    </Wrapper>
  );
}

const AccountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div<any>`
  padding-left: 10px;
  padding-top: 20px;

  @media ${(props) => props.theme.device.mobile} {
    padding-left: ${(props) => (props.navigationOpen ? "50px" : "10px")};
  }
`;

const LogoutButton = styled(Button)`
  height: 50px;
  display: flex;
  align-items: center;
  //margin-left: 0px;
  //margin-top: 10px;

  /* for smooth transition */
  white-space: nowrap;
`;
