import { api } from "api/api";
import { toast } from "react-toastify";

export const SET_CANDIDATE_SELECTION = "candidate/SET_CANDIDATE_SELECTION";
export const SET_VERB = "candidate/SET_VERB";

export function saveCandidateSelection(json: any) {
  return async (dispatch, getState) => {
    const state = getState();
    const selectedProductLineId = state.products.selectedProductLine.id;
    try {
      const res = await api.modifyCandidateSelection(
        selectedProductLineId,
        json
      );
      if (!res) throw null;

      dispatch({
        type: SET_CANDIDATE_SELECTION,
        payload: res.data,
      });
    } catch (e) {
      console.error(e);
      toast.error("Error while saving candidate selection");
      toast.error(e);
    }
  };
}

export function getCandidateSelection() {
  return async (dispatch, getState) => {
    const state = getState();
    const selectedProductLineId = state.products.selectedProductLine.id;

    try {
      dispatch({ type: SET_VERB, payload: { getting: true } });
      let candidateSelection: any = {};
      const res = await api.getCandidateSelection(selectedProductLineId);

      // erroring
      if (!res || !res.data) throw null;

      candidateSelection = res.data;

      dispatch({
        type: SET_CANDIDATE_SELECTION,
        payload: candidateSelection,
      });
    } catch (e) {
      console.error(e);
    }
    dispatch({ type: SET_VERB, payload: { getting: false } });
  };
}

export function addSectionCandidate() {
  return async (dispatch, getState) => {
    const state = getState();
    const selectedProductLineId = state.products.selectedProductLine.id;
    dispatch({ type: SET_VERB, payload: { getting: true } });

    try {
      const res = await api.createCandidateSelection(selectedProductLineId);
      const candidateSelection = res?.data;
      dispatch({
        type: SET_CANDIDATE_SELECTION,
        payload: candidateSelection,
      });
    } catch (e) {
      console.error(e);
    }
    dispatch({ type: SET_VERB, payload: { getting: false } });
  };
}
