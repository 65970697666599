import React from "react";
import {
  Breakpoint,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";

export interface ModalConfirmDialogProps {
  open: boolean;
  isWarning?: boolean;
  title?: string;
  maxWidth?: false | Breakpoint;
  content: string | React.ReactNode;
  defaultButton?: "OK" | "CANCEL";
  cancelButtonText?: string;
  okButtonText?: string;
  onCancelButtonClick: () => void;
  onOKButtonClick: () => void;
}

export const ModalConfirmDialog: React.FC<ModalConfirmDialogProps> = ({
  open,
  isWarning = false,
  title = "",
  maxWidth = false,
  content = "",
  defaultButton = "CANCEL",
  cancelButtonText = "Cancel",
  okButtonText = "OK",
  onCancelButtonClick,
  onOKButtonClick,
}) => {
  return (
    <Dialog
      open={open}
      maxWidth={maxWidth}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      <DialogTitle id="dialog-title">{title}</DialogTitle>
      <DialogContent id="dialog-description" dividers>
        {typeof content === "string" ? (
          <DialogContentText>{content}</DialogContentText>
        ) : (
          content
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant={defaultButton === "CANCEL" ? "contained" : "outlined"}
          autoFocus={defaultButton === "CANCEL"}
          focusRipple
          onClick={onCancelButtonClick}
          tabIndex={defaultButton === "CANCEL" ? 1 : 2}
        >
          {cancelButtonText}
        </Button>
        <Button
          variant={defaultButton === "OK" ? "contained" : "outlined"}
          autoFocus={defaultButton === "OK"}
          focusRipple
          color={isWarning ? "error" : "primary"}
          onClick={onOKButtonClick}
          tabIndex={defaultButton === "OK" ? 1 : 2}
        >
          {okButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalConfirmDialog;
