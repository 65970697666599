import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { IconButton, TextField, Tooltip } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Button } from "components";
import OverlayRightPanel from "containers/OverlayRightPanel/OverlayRightPanel.container";
import { useTypedSelector } from "store";
import {
  domainOptions,
  DomainEnum,
  DataTypeEnum,
  dataTypesOptions,
} from "store/reducers/products/properties/pl-properties.types";
import { useDispatch } from "react-redux";
import {
  setNewSubProperty,
  addSubPropertyPropertyPL,
  getSubPropertiesAction,
} from "store/reducers/products/subproperties/pl-subproperties.actions";
import { determineDataTypeOptions, PROPERTY_NAME_REGEX } from "../helpers";
import SelectDropdownComponent, {
  getSingleValueFromOptions,
  getSelectedValueFromOptions,
  stringArrayToOptions,
} from "../../../../../components/Forms/SelectDropdown/SelectDropdown.component";
import useValidateBooleanDataType from "../../../../../components/Validator/useValidateBooleanDataType";

export default function AddSubpropertyButton({
  parentPropertyId,
  sectionName,
  productLineId,
}: {
  parentPropertyId: string;
  sectionName: any;
  productLineId: any;
}) {
  const [open, setOpen] = useState(false);
  const inputRef = useRef<any>();

  const { newSubProperty, posting } = useTypedSelector(
    (state) => state.products?.subproperties
  );

  const dispatch = useDispatch();
  const { name, dataType, domain } = newSubProperty;

  const { message: booleanPropertyError } = useValidateBooleanDataType({
    domain,
    dataType,
  });

  const isValidProperty = name && dataType && domain && !booleanPropertyError;

  const handleNameChange = (e: any) => {
    const { value, name } = e.target;

    if (value.match(PROPERTY_NAME_REGEX)) {
      const newSubPropertyName = sectionName + "." + value;
      dispatch(
        setNewSubProperty({
          ...newSubProperty,
          [name]: newSubPropertyName.trim(),
        })
      );
    }
  };

  const handleDropdown = (val: any, name) => {
    const newValue = getSelectedValueFromOptions(false, val);
    dispatch(
      setNewSubProperty({
        ...newSubProperty,
        [name]: newValue,
      })
    );
  };

  const handleSubmit = async () => {
    await dispatch(addSubPropertyPropertyPL(newSubProperty, parentPropertyId));
    if (domain === DomainEnum.RANGE) {
      dispatch(
        getSubPropertiesAction({
          productLineId: productLineId,
          PLPropertyId: parentPropertyId,
        })
      );
    }
    setOpen(false);
  };

  useEffect(() => {
    if (open && inputRef.current) {
      inputRef.current.value = "";
      setTimeout(() => inputRef.current.focus(), 100);
    }
    if (!open) {
      //Clear the newSubProperty redux store values when we close the form
      dispatch(
        setNewSubProperty({
          ...newSubProperty,
          name: "",
          domain: "",
          dataType: "",
        })
      );
    }
  }, [open]);

  useEffect(() => {
    if (!dataType) return;
    if (
      domain === DomainEnum.RANGE &&
      [DataTypeEnum.STRING, DataTypeEnum.BOOL].includes(dataType)
    )
      dispatch(setNewSubProperty({ ...newSubProperty, dataType: "" }));
  }, [domain]);

  return (
    <>
      <OverlayRightPanel
        open={open}
        closeNav={() => setOpen(false)}
        title="New Custom Subproperty"
      >
        <AddPropertyContent>
          <TextField
            variant="outlined"
            size="small"
            disabled
            label="Parent Property Name"
            id="parentName"
            defaultValue={`${sectionName}.`}
            fullWidth
            margin="none"
            sx={{ marginBottom: "32px" }}
          />
          <TextField
            variant="outlined"
            size="small"
            label="Subproperty Name"
            id="name"
            name="name"
            onChange={handleNameChange}
            inputRef={inputRef}
            fullWidth
            margin="none"
            sx={{ marginBottom: "32px" }}
          />
          <StyledSelectDropdownComponent
            placeholder="Domain"
            name="domain"
            options={stringArrayToOptions(domainOptions)}
            handleChange={(val) => handleDropdown(val, "domain")}
            value={getSingleValueFromOptions(domainOptions, domain)}
            noShadow
            noHoverBorder
          />
          <StyledSelectDropdownComponent
            placeholder="Data type"
            name="dataType"
            options={stringArrayToOptions(determineDataTypeOptions(domain))}
            handleChange={(val) => handleDropdown(val, "dataType")}
            value={getSingleValueFromOptions(dataTypesOptions, dataType)}
            noShadow
            isDisabled={!domain}
            noHoverBorder
          />
          <StyledAddProductLineButton
            onClick={handleSubmit}
            buttonType="submit"
            loading={posting}
            disabled={!isValidProperty}
          >
            Add Subproperty
          </StyledAddProductLineButton>
          <StyledErrorMessage>{booleanPropertyError}</StyledErrorMessage>
        </AddPropertyContent>
      </OverlayRightPanel>

      <Tooltip title="Add Custom Subproperty" arrow>
        <IconButton
          size="medium"
          color="primary"
          aria-label="add custom subproperty"
          onClick={() => setOpen(true)}
        >
          <AddCircleOutlineIcon fontSize="inherit" />
        </IconButton>
      </Tooltip>
    </>
  );
}

const StyledSelectDropdownComponent = styled(SelectDropdownComponent)<any>`
  margin-bottom: 32px;
`;

const AddPropertyContent = styled.div`
  padding: 24px;
`;

const StyledAddProductLineButton = styled(Button)`
  width: 100%;

  /* for smooth transition */
  white-space: nowrap;
`;

const StyledErrorMessage = styled.span<any>`
  color: ${(props) => props.theme.colors.error};
`;
