import React, { useState } from "react";
import { Button } from "components";
import styled from "styled-components";
import { Steps, stepRoutes } from "../ProgramsAddNew.page";
import { useHistory } from "react-router-dom";
import { useTypedSelector } from "store";
import { validateNameStep } from "../Steps/Name/validation";

export default function Actions({ currentRoute }: { currentRoute: any }) {
  const [isValid, setIsValid] = useState(false);
  const { newProgram } = useTypedSelector((state) => state.programs);
  const history = useHistory();

  const handleNext = async (stepName: Steps) => {
    history.push(stepRoutes[stepName].path);
  };

  const handlePrevious = (stepName: Steps) => {
    history.push(stepRoutes[stepName].path);
  };

  const getValidation = async (newProgram) => {
    const validation = await validateNameStep(newProgram);
    if (validation === true) {
      return setIsValid(true);
    } else {
      return setIsValid(false);
    }
  };

  getValidation(newProgram);

  const button1: Map<Steps, any> = new Map([
    ["name", { label: "CANCEL", action: () => history.push("/dashboard") }],
    ["team", { label: "GO BACK", action: () => handlePrevious("name") }],
    ["models", { label: "GO BACK", action: () => handlePrevious("team") }],
    [
      "completionDate",
      { label: "GO BACK", action: () => handlePrevious("models") },
    ],
    [
      "milestones",
      { label: "GO BACK", action: () => handlePrevious("completionDate") },
    ],
  ]);

  const button2: Map<Steps, any> = new Map([
    ["name", { label: "NEXT", action: () => handleNext("team") }],
    ["team", { label: "NEXT", action: () => handleNext("models") }],
    ["models", { label: "NEXT", action: () => handleNext("completionDate") }],
    [
      "completionDate",
      { label: "NEXT", action: () => handleNext("milestones") },
    ],
    [
      "milestones",
      { label: "SUBMIT", action: () => console.log("submitting") },
    ],
  ]);

  return (
    <Wrapper>
      <StyledButton
        onClick={button1.get(currentRoute?.step)?.action}
        color="primary"
        type="secondary"
        buttonType="button"
        size="normal"
        name="action1"
      >
        {button1.get(currentRoute?.step)?.label}
      </StyledButton>
      <StyledButton
        onClick={button2.get(currentRoute?.step)?.action}
        buttonType="submit"
        size="normal"
        name="action2"
        disabled={!isValid}
      >
        {button2.get(currentRoute?.step)?.label}
      </StyledButton>
    </Wrapper>
  );
}

const StyledButton = styled<any>(Button)`
  margin: 24px 8px 0px 8px;
  width: 145px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
