import styled from "styled-components";
import React from "react";

import { H3 } from "components/Typography/Headlines.component";
import { Close } from "assets/logos/icons/MicroIcons";
import colors from "theme/colors";

export function Modal({
  open,
  closeModal,
  children,
  title,
  width,
  height,
  dialogType = "normal",
}: {
  open: boolean;
  closeModal: () => void;
  children: any;
  title?: any;
  width?: string;
  height?: string;
  dialogType?: "normal" | "error";
}) {
  return (
    <Background open={open} onClick={closeModal}>
      <Wrapper
        open={open}
        onClick={(e) => e.stopPropagation()}
        width={width}
        height={height}
      >
        <Title>
          <StyledH3 dialogType={dialogType}>{title}</StyledH3>
          <CloseButton onClick={closeModal}>
            <Close color={colors.muttedHeadlines} />
          </CloseButton>
        </Title>
        <ChildrenWrapper dialogType={dialogType} open={open}>
          {children}
        </ChildrenWrapper>
      </Wrapper>
    </Background>
  );
}

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 32px;
  overflow: hidden;
  white-space: nowrap;
  color: ${(props) => props.theme.colors.primary};
`;

const StyledH3 = styled(H3)<any>`
  color: ${(props) =>
    props.dialogType === "error" ? colors.error : colors.muttedHeadlines};
`;

const Wrapper = styled.div<any>`
  box-sizing: border-box;
  z-index: 99999 + 1;
  background-color: #fefefe;
  border-radius: 4px;
  margin: auto;
  width: ${(props) => (props.width ? props.width : "400px")};
  max-width: 100%;
  height: ${(props) => (props.height ? props.height : "300px")};
  transition: 0.5s;
  display: flex;
  flex-direction: column;
`;

const Background = styled.div<any>`
  height: 100vh;
  width: 100vw;
  display: ${(props) => (props.open ? "fex" : "none")};
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  z-index: 99999;
`;

const ChildrenWrapper = styled.div<any>`
  height: 100%;
  width: 100%;
  justify-content: space-around;
  display: flex;
  align-items: left;
  flex-direction: column;
  padding: 32px;
  padding-top: 0;
  box-sizing: border-box;
  text-align: left;
  overflow-y: scroll;
  color: ${(props) =>
    props.dialogType === "error" ? colors.error : colors.primary};

  /* Hack for smooth transition (equivalent to width: 100%) */
`;

const CloseButton = styled.div`
  display: flex;
  align-items: center;
  font-size: 36px;
  margin-left: 50px;
  cursor: pointer;
  margin-right: 30px;

  :hover {
    opacity: 0.7;
  }
`;
