import { Property } from "../properties/pl-properties.types";

export enum TablesTypeEnum {
  INCLUDE = "INCLUDE",
  EXCLUDE = "EXCLUDE",
  ATTR_LOOKUP = "ATTR_LOOKUP",
  LOOKUP = "LOOKUP",
  // CONSTRAINT = "CONSTRAINT",
}

export const tablesTypes: TablesTypeEnum[] = [
  TablesTypeEnum.INCLUDE,
  TablesTypeEnum.EXCLUDE,
  TablesTypeEnum.ATTR_LOOKUP,
  TablesTypeEnum.LOOKUP,
  // TablesTypeEnum.CONSTRAINT,
];

export interface RulesTable {
  TableType: TablesTypeEnum;
  RulesetIds: any;
  propertyIds: any;
  properties: Property[];
  ownerTool: string;
  dbSchemaVersion: number;
}

export interface RulesTablesState {
  byId: any;
  allIds: string[];
  newRulesTable: any;
  total: number;
  page: number;
  getting: boolean;
  deleting: boolean;
  posting: boolean;
}
