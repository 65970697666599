import useValidateBooleanDataType from "./useValidateBooleanDataType";
import { ErrorObject } from "../../store/reducers/products/validation/validation.types";
import useValidateRangeProperty from "./useValidateRangeProperty";
import useValidateRuletableRulesets from "./useValidateRuletableRulesets";

export const useValidationBatchCheck = ({
  objectData,
}: {
  objectData: any;
}) => {
  const validationsArray: Pick<ErrorObject, "message" | "validatorName">[] = [];

  validationsArray.push(
    useValidateRuletableRulesets({ properties: objectData?.properties ?? null })
  );

  validationsArray.push(
    useValidateBooleanDataType({
      domain: objectData.domain,
      dataType: objectData.dataType,
    })
  );

  validationsArray.push(
    useValidateRangeProperty({
      domain: objectData.domain,
      dataType: objectData.dataType,
    })
  );

  return validationsArray;
};
