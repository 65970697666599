import { randomId } from "@mui/x-data-grid-generator";
import {
  DisplayUnitsDataGridRow,
  UnitInfo,
  ROUND_DIGIT_DEFAULT,
  ROUND_DIGIT_MIN,
  ROUND_DIGIT_MAX,
  RoundMethod,
  ROUND_METHOD_DEFAULT,
} from "./UnitPickerInterfaces";
import {
  DomainEnum,
  Property,
} from "store/reducers/products/properties/pl-properties.types";
import ecatUnits from "./ecatUnits.json";

export const CreateDisplayUnitsDataGridRow = (
  displayUnit: string,
  isDefault = false,
  roundMethod: RoundMethod = ROUND_METHOD_DEFAULT,
  roundDigits: number = ROUND_DIGIT_DEFAULT
): DisplayUnitsDataGridRow => {
  const row: DisplayUnitsDataGridRow = {
    id: randomId(),
    displayUnit: displayUnit,
    isDefault: isDefault,
    roundMethod: roundMethod,
    roundDigit: roundDigits,
    __reorder__: "drag row to reorder",
  };

  return row;
};

//Build a set of all units from our ecatUnits json that lists
//unit name and the unit group (i.e. "Temperature")
//This will be used for the options list
export const GetAllUnits = (): UnitInfo[] => {
  console.log("GetAllUnits BEGIN");
  const unitsWithGroups: UnitInfo[] = [];

  ecatUnits.unitGroups.forEach((group) => {
    group.units.forEach((unit) => {
      unitsWithGroups.push({
        label: unit,
        group: group.group,
      });
    });
  });

  return unitsWithGroups;
};

export const GetUnitGroupFromUnit = (
  unit: string,
  allUnitInfo?: UnitInfo[]
): string => {
  if (!allUnitInfo) allUnitInfo = GetAllUnits();

  const unitInfo: UnitInfo | undefined = allUnitInfo.find(
    (ui) => ui.label === unit
  );
  return unitInfo?.group ? unitInfo.group : "";
};

export const GetGroupUnits = (
  group = "",
  allUnitInfo?: UnitInfo[]
): string[] => {
  if (!allUnitInfo) allUnitInfo = GetAllUnits();
  if (!group) return allUnitInfo.map((unit) => unit.label);

  const unitsForActiveGroup: string[] = allUnitInfo
    .filter((unit) => unit.group === group)
    .map((unit) => {
      return unit.label;
    });
  return unitsForActiveGroup;
};

export const GetPropertyValues = (property: Property): string[] | null => {
  if (!property) return null;
  if (property.domain !== DomainEnum.DISCRETE) return null;
  if (!property.propertyValues) return null;

  //Get the property values for the property
  const values: string[] = property?.propertyValues.map((propertyValue) => {
    const cellValue = propertyValue?.valueAttributeCells.find((vac) => {
      if (vac.propertyValueAttribute.name === "Value") return vac;
    });
    return cellValue.value;
  });
  return values;
};

export const ValidateRoundDigits = (
  roundDigits: number | null
): number | null => {
  //if null or undefined set to null
  if (roundDigits === null || roundDigits === undefined) return null;

  //If no value, return the default
  if (!roundDigits) return ROUND_DIGIT_DEFAULT;

  //If less than minimum, return minimum
  if (roundDigits < ROUND_DIGIT_MIN) return ROUND_DIGIT_MIN;

  //If more than max, return max
  if (roundDigits > ROUND_DIGIT_MAX) return ROUND_DIGIT_MAX;

  //Return the supplied value
  return roundDigits;
};

export const ValidateRoundMethod = (method: any): RoundMethod => {
  //validate supplied method is one of the enum values
  if (Object.values(RoundMethod).includes(method)) {
    return method as RoundMethod;
  }

  //If not return the default round method
  return ROUND_METHOD_DEFAULT;
};
