import { useTypedSelector } from "store";
import { Button, Loader1, Modal } from "components";
import React, { useEffect } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { setRulesTablesInitialState } from "store/reducers/products/rulestables/pl-rulestables.actions";
import { sortValidationByObject } from "./helpers";
import ValidationList from "./ValidationList.component";

export default function ValidationModal({
  open,
  closeModal,
  handleExport,
}: {
  open: boolean;
  closeModal: () => void;
  handleExport: any;
}) {
  const { getting, byId } = useTypedSelector(
    (state) => state.products.validation
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setRulesTablesInitialState());
  }, []);

  const sortedValidationObjects = sortValidationByObject(byId);

  const handleRefreshValidation = () => {
    handleExport();
  };

  return (
    <Modal
      width={"999px"}
      height={"80vh"}
      open={open}
      closeModal={closeModal}
      title={"Validation"}
    >
      {getting ? (
        <Loader1 />
      ) : (
        <Wrapper>
          <Info>Before exporting the Product Line, fix the errors below:</Info>
          <ValidationList validationData={sortedValidationObjects} />
          <RefreshButtonWrapper>
            <Button onClick={handleRefreshValidation} size={"small"}>
              Refresh list
            </Button>
          </RefreshButtonWrapper>
        </Wrapper>
      )}
    </Modal>
  );
}

const Wrapper = styled.div`
  text-align: left;
  width: 100%;
  height: 100%;
`;

const RefreshButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 32px;
  padding-bottom: 32px;
`;

const Info = styled.div`
  font-size: 14px;
  margin-bottom: 32px;
`;
