import React from "react";
import styled from "styled-components";
import colors from "theme/colors";
import { H3, H6 } from "components/Typography/Headlines.component";
import {
  Edit,
  CloseProgram,
  PauseProgram,
  Upload,
} from "assets/logos/icons/MediumIcons";
import { ArrowLeft } from "assets/logos/icons/MicroIcons";
import { useHistory } from "react-router-dom";
import { useTypedSelector } from "store";

export default function ProgramDetailsHeader() {
  const history = useHistory();
  const { selectedProgram } = useTypedSelector((state) => state.programs);
  return (
    <Box>
      <TitleRow>
        <Title>{selectedProgram ? selectedProgram.name : ""}</Title>
        <BackButton onClick={() => history.goBack()}>
          <ArrowLeft />
          <ButtonTitle bold>Back To Programs</ButtonTitle>
        </BackButton>
      </TitleRow>
      <ButtonsRow>
        <NaviButton>
          <Edit />
          <ButtonTitle bold uppercase>
            Edit Program
          </ButtonTitle>
        </NaviButton>
        <NaviButton>
          <CloseProgram />
          <ButtonTitle bold uppercase>
            Close Program
          </ButtonTitle>
        </NaviButton>
        <NaviButton>
          <PauseProgram />
          <ButtonTitle bold uppercase>
            Pause Program
          </ButtonTitle>
        </NaviButton>
        <NaviButton>
          <Upload />
          <ButtonTitle bold uppercase>
            Export Data
          </ButtonTitle>
        </NaviButton>
      </ButtonsRow>
    </Box>
  );
}

const Box = styled.header`
  display: flex;
  width: 100%;
  flex-direction: column;
  box-shadow: 0px 2px 4px rgba(81, 93, 105, 0.15);
`;
const TitleRow = styled.div`
  height: auto;
  padding-left: 64px;
  padding-right: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  border-bottom: 1px solid ${colors.tables.borders};
  flex-wrap: wrap;
`;

const ButtonsRow = styled.div`
  height: auto;
  padding-left: 64px;
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
`;

const NaviButton = styled.div`
  cursor: pointer;
  padding: 28px 24px 28px 26px;
  border-right: 1px solid ${colors.tables.borders};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

const Title = styled(H3)`
  font-size: 32px;
`;

const ButtonTitle = styled(H6)<any>`
  color: ${colors.secondary};
  margin-left: 10px;
  text-transform: ${(props) => (props.uppercase ? "uppercase" : "none")};
  margin-block-start: 0px;
  margin-block-end: 0px;
`;

const BackButton = styled.div`
  display: flex;
  flex-direction: row;
  padding: 4px 8px 4px 9px;
  border-radius: 4px;
  background-color: ${colors.background.primaryLabels};
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
