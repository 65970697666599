import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Label from "./components/Label.component";
import { useTypedSelector } from "store";

export default function LabelsBar() {
  const [currentTab, setCurrentTab] = useState("All");
  const [allPrograms, setAllPrograms] = useState(0);
  const [activePrograms, setActivePrograms] = useState(0);
  const [latePrograms, setLatePrograms] = useState(0);
  const [completePrograms, setCompletePrograms] = useState(0);
  const programs = useTypedSelector((state) => state.programs.programs);
  const setAmounts = () => {
    setAllPrograms(programs.length);
    programs.map((el) => {
      switch (el.status) {
        case "complete":
          setCompletePrograms(completePrograms + 1);
          break;
        case "late":
          setLatePrograms(latePrograms + 1);
          break;
        case "started":
          setActivePrograms(activePrograms + 1);
          break;
      }
    });
  };
  useEffect(() => {
    setAmounts();
  }, []);
  return (
    <Row>
      <Label
        name="All"
        count={allPrograms}
        setCurrentTab={setCurrentTab}
        currentTab={currentTab}
      />
      <Label
        name="Active"
        count={activePrograms}
        setCurrentTab={setCurrentTab}
        currentTab={currentTab}
      />
      <Label
        name="Late"
        count={latePrograms}
        setCurrentTab={setCurrentTab}
        currentTab={currentTab}
      />
      <Label
        name="Complete"
        count={completePrograms}
        setCurrentTab={setCurrentTab}
        currentTab={currentTab}
      />
    </Row>
  );
}

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-right: -18px;
  flex-wrap: wrap;
`;
