import { H4, Button, Input, DatePickerInput } from "components";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import colors from "theme/colors";
import { useDispatch } from "react-redux";
import {
  addNewTaskAction,
  editTaskAction,
} from "store/reducers/programs/programs.actions";
import { selectTaskById } from "store/reducers/programs/programs.selectors";
import { useTypedSelector } from "store";
import AddTeamMember from "./AddTeamMember.component";

export default function AddNewTask({
  id,
  close,
}: {
  id?: any;
  close: () => void;
}) {
  const [taskName, setTaskName] = useState("");
  const [milestoneName, setMilestoneName] = useState("");
  const [taskDetails, setTaskDetails] = useState("");
  const [dueDate, setDueDate] = useState<any>();
  const [taskOwners, setTaskOwners] = useState<any>([]);
  const [taskManagers, setTaskManagers] = useState<any>([]);
  const [currentId, setCurrentId] = useState();
  const editTaskDetails = selectTaskById(
    useTypedSelector((state) => state.programs),
    id
  );

  const clearData = () => {
    setTaskName(editTaskDetails?.name || "");
    setMilestoneName(editTaskDetails?.milestone || "");
    setTaskDetails(editTaskDetails?.details || "");
    setDueDate(editTaskDetails?.dueDate || null);
    setTaskOwners(editTaskDetails?.taskOwners || []);
    setTaskManagers(editTaskDetails?.taskManagers || []);
  };
  const idRandom = Math.floor(Math.random() * 1000);
  const AddNewTaskAction = () => {
    dispatch(
      addNewTaskAction({
        byId: {
          id: String(idRandom),
          name: taskName,
          milestone: milestoneName,
          dueDate: dueDate,
          details: taskDetails,
          taskOwners: taskOwners,
          taskManager: taskManagers,
        },
        allIds: { id: String(idRandom) },
      })
    );
    clearData();
    close();
  };

  const submitEdit = () => {
    dispatch(
      editTaskAction({
        id: id,
        name: taskName,
        milestone: milestoneName,
        dueDate: dueDate,
        details: taskDetails,
        taskOwners: taskOwners,
        taskManagers: taskManagers,
        status: editTaskDetails.status,
      })
    );
    clearData();
    close();
  };
  const dispatch = useDispatch();
  useEffect(() => {
    clearData();
    setCurrentId(id);
  }, [id]);

  return (
    <Container>
      <Box>
        <Title bold color={colors.muttedHeadlines}>
          Task
        </Title>
        <CustomInput
          label="Task Name"
          onChange={(taskName) => setTaskName(taskName.target.value)}
          value={taskName}
        />
        <CustomInput
          label="Milestone"
          onChange={(milestoneName) =>
            setMilestoneName(milestoneName.target.value)
          }
          value={milestoneName}
        />
        <Title bold color={colors.muttedHeadlines}>
          Details
        </Title>
        <CustomInput
          label="TaskDetails"
          onChange={(details) => setTaskDetails(details.target.value)}
          value={taskDetails}
        />
        <DatePickerInput
          onChange={(dueDate) => setDueDate(dueDate)}
          value={dueDate}
          id={currentId}
        />
        <Title bold color={colors.muttedHeadlines}>
          Task
        </Title>
        <AddTeamMember
          sectionName="taskOwners"
          team={taskOwners}
          onChange={setTaskOwners}
          id={currentId}
        />

        <Title bold color={colors.muttedHeadlines}>
          Task Manager
        </Title>
        <AddTeamMember
          sectionName="taskManagers"
          team={taskManagers}
          onChange={setTaskManagers}
          id={currentId}
        />
        <CustomButton
          type="primary"
          color="secondary"
          onClick={id ? submitEdit : AddNewTaskAction}
        >
          {id ? "Update" : "Add Task"}
        </CustomButton>
      </Box>
    </Container>
  );
}

const Title = styled(H4)`
  margin-block-start: 16px;
  margin-block-end: 8px;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  overflow-y: scroll;
`;

const Box = styled.div`
  // min-height: 300vh;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 64px;
  padding-top: 48px;
`;

const CustomButton = styled(Button)`
  margin: 0px;
  width: 138px;
  margin-top: 32px;
`;

const CustomInput = styled(Input)`
  width: 600px;
  margin-bottom: 16px;
  > input {
    margin-top: 0px;
  }
`;
