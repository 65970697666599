export function selectProductById(state, id) {
  const selectedProduct = state.products.find(
    (product: any) => product.id === id
  );
  return selectedProduct;
}

export function selectProductLineMembers(state, id) {
  const selectedProductLine = state.products.find(
    (product: any) => product.id === id
  );
  return selectedProductLine.teamMembers;
}

export function selectPVAValues(state, propertyId) {
  return state.products.properties.byId[propertyId].propertyValues.map(
    (propertyValue) => {
      const value = propertyValue?.valueAttributeCells.find((cell) => {
        if (cell.propertyValueAttribute.name === "Value") return cell;
      });
      return value.value;
    }
  );
}

export function selectProductLineId(state) {
  try {
    return state.products.selectedProductLine.id;
  } catch (e) {
    return "";
  }
}

export function selectProductLineRulesets(state) {
  return state.products.selectedProductLine?.rulesets ?? [];
}
