export enum ErrorOriginEnum {
  BACKEND = "BACKEND",
  FRONTEND = "FRONTEND",
}

export enum ObjectAssignedEnum {
  PRODUCTLINE = "PRODUCTLINE",

  PROPERTY = "PROPERTY",
  PROPERTYVALUE = "PROPERTYVALUE",
  VALUEATTRCELL = "VALUEATTRCELL",

  RULETABLE = "RULETABLE",
  RULETABLEROW = "RULETABLEROW",
  RULETABLECELL = "RULETABLECELL",

  LOGICGROUP = "LOGICGROUP",
}

export enum ValidatorNames {
  WrongDomainForBoolean = "WrongDomainForBoolean",
  WrongDataTypeForRange = "WrongDataTypeForRange",
  WrongRulesetsInRuleTableProperties = "WrongRulesetsInRuleTableProperties",
}

export interface ErrorObject {
  id: string;
  objectInfo: any;
  productLineId: string;
  type: string;
  origin: ErrorOriginEnum;
  objectAssigned: ObjectAssignedEnum;
  objectId: string;
  validatorName: ValidatorNames;
  message: string | null;
  relatedObjects: any[];
  createdAt: string;
}

export interface ValidationById {
  [key: string]: ErrorObject;
}

export interface ValidationState {
  byId: ValidationById;
  allIds: string[];
  getting: boolean;
  deleting: boolean;
  posting: boolean;
}
