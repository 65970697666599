import { Product } from "./products.types";

export const mockedProducts: Product[] = [
  // {
  //   name: "EMEA Packaged Chillers",
  //   displayImage: img,
  //   id: "32",
  //   teamMembers,
  //   region: "EMEA",
  // },
  // {
  //   name: "NAO Applied Chillers",
  //   displayImage: img,
  //   id: "25",
  //   teamMembers,
  //   region: "ASIA",
  // },
  // {
  //   name: "Asia Applied Chillers",
  //   displayImage: img,
  //   id: "53",
  //   teamMembers: shortTeamMembers,
  //   region: "ASIA",
  // },
];
