// tsx extension so hex colors are displayed

const size = {
  mobileS: "340px",
  mobile: "425px",
  tablet: "768px",
  laptop: "1440px",
};

export interface Device {
  mobileS: string;
  mobile: string;
  tablet: string;
  laptop: string;
}

const device: Device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobile: `(min-width: ${size.mobile})`,
  tablet: `(min-width: ${size.mobile})`,
  laptop: `(min-width: ${size.laptop})`,
};

export default device;
