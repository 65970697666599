export enum ConflictAction {
  ABORT = "ABORT",
  NEW = "NEW",
  REPLACE = "REPLACE",
}

//These are identical to the ProcessState values in the API
export enum ProcessState {
  IDLE = "IDLE",
  INITIALIZING = "INITIALIZING",
  QUEUED = "QUEUED",
  PROCESSING = "PROCESSING",
  COMPLETE = "COMPLETE",
  ERROR = "ERROR",
}
