import { H6, ExpandableSection } from "components";
import React from "react";
import styled from "styled-components";
import colors from "theme/colors";
import DocumentBox from "./DocumentBox.component";
import { selectDocumentsByCategory } from "store/reducers/programs/programs.selectors";
import { PlusActionIcon } from "assets/logos/icons/QuickActions";
import { useHistory } from "react-router-dom";

export default function SupportingDocsSection({
  name,
  docs,
  category,
}: {
  name: string;
  docs?: any;
  category: string;
}) {
  const selectedDocs = selectDocumentsByCategory(docs);
  const docsByCategory = selectedDocs[category];
  const history = useHistory();
  const displayDocs = () => {
    return (
      <ExpandedSection>
        {docsByCategory?.map((doc, index) => {
          return (
            <DocumentBox
              key={index}
              published={doc.published}
              content={doc.content}
            />
          );
        })}
        <AddNewDocumentBox onClick={() => history.push("/add-new-document")}>
          <PlusActionIcon />
          <CustomH6 bold>Add new document</CustomH6>
        </AddNewDocumentBox>
      </ExpandedSection>
    );
  };
  return (
    <ExpandableSection title={name} length={docsByCategory.length}>
      {displayDocs()}
    </ExpandableSection>
  );
}

const ExpandedSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const AddNewDocumentBox = styled.div`
  background-color: ${colors.white};
  width: 348px;
  height: 244px;
  border-radius: 4px;
  border: 1px solid ${colors.tables.borders};
  display: flex;
  align-items: center;
  margin-top: 24px;
  justify-content: center;
  cursor: pointer;
  box-sizing: border-box;
  flex-direction: column;
  &:hover circle {
    fill: ${colors.secondary};
  }
  &:hover path {
    fill: ${colors.white};
  }

  &:hover {
    border: 1px solid transparent;
    box-shadow: inset 0 0 0 2px ${colors.secondary};
  }
`;

const CustomH6 = styled(H6)`
  text-transform: uppercase;
  color: ${colors.secondary};
  margin-top: 16px;
  margin-block-end: 0px;
`;
