//Rules Engine API URLS
export enum RulesEngineApiUrl {
  LOCAL = "http://localhost:51387/api/EcatEngine/",
  SANDBOX = "https://sandbox-rulesengine.carrier.com/api/EcatEngine/",
  DEV = "https://dev-rulesengine.carrier.com/api/EcatEngine/",
}

export enum RuleEngineTestUiUrl {
  SANDBOX = "https://sandbox-rulesengine.carrier.com/",
  DEV = "https://dev-rulesengine.carrier.com/",
}

export enum RulesEngineEndpoint {
  CLEAN_CACHES = "CleanCaches",
  FILTER_RECONFIGURE_VERSIONED = "FilterAndReconfigureVersioned",
  GET_INIT_STATE_VERSIONED = "GetInitialStateWithDefaultValuesVersioned",
  UPLOAD_PRODUCTLINE_RULES_ZIP = "UploadProductLineRulesZip",
}
