import {
  SET_NEW_RULES_TABLE,
  ADD_NEW_RULES_TABLE,
  DELETE_RULES_TABLE,
  SET_PL_RULES_TABLES,
  SET_PL_RULE_TABLE,
  EDIT_PL_RULETABLE,
  DELETE_RULE_TABLE_ROW,
  ADD_NEW_PROPERTY,
  SET_RULES_TABLES_INITIAL_STATE,
  GET_RULETABLE_VALIDATION_BY_OBJECT_ID,
  SET_PL_RULES_TABLES_PAGINATION,
} from "./pl-rulestables.actions";
import { RulesTablesState } from "./pl-rulestables.types";
import { SET_VERB } from "./pl-rulestables.actions";

const newRuletableInitialState = {
  TableType: null,
  propertyIds: [],
  RulesetIds: [],
};

export const plRulesTablesInitialState: RulesTablesState = {
  byId: {},
  allIds: [],
  newRulesTable: newRuletableInitialState,
  total: 0,
  page: 0,

  getting: false,
  posting: false,
  deleting: false,
};

export function plRulesTablesReducer(
  state: RulesTablesState = plRulesTablesInitialState,
  action: any
) {
  switch (action.type) {
    case SET_NEW_RULES_TABLE: {
      return {
        ...state,
        newRulesTable: action.payload,
      };
    }

    case SET_VERB: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case ADD_NEW_RULES_TABLE:
      return {
        ...state,
        ...action.payload,
        newRulesTable: newRuletableInitialState,
      };

    case DELETE_RULES_TABLE:
      const newAllIds = state.allIds.filter((id) => id !== action.payload.id);
      const newId = action.payload.id;
      const oldState = state.byId;
      const newById = Object.keys(oldState).reduce((object, key) => {
        if (key !== newId) {
          object[key] = oldState[key];
        }
        return object;
      }, {});
      return {
        ...state,
        allIds: newAllIds,
        byId: newById,
      };

    case SET_PL_RULES_TABLES: {
      if (action.payload.page)
        return {
          ...state,
          byId: {
            ...action.payload.byId,
          },
          allIds: [...action.payload.allIds],
          total: action.payload.total,
        };
      return {
        ...state,
        newRulesTable: newRuletableInitialState,
        ...action.payload,
      };
    }

    case SET_PL_RULE_TABLE:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.ruleTableId]: action.payload.data,
        },
      };

    case EDIT_PL_RULETABLE: {
      return {
        ...state,
        byId: action.payload,
      };
    }

    case DELETE_RULE_TABLE_ROW: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.ruleTable.id]: action.payload.ruleTable,
        },
      };
    }

    case ADD_NEW_PROPERTY: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.ruleTableId]: action.payload.ruleTable,
        },
      };
    }

    case GET_RULETABLE_VALIDATION_BY_OBJECT_ID: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.objectId]: {
            ...state.byId[action.payload.objectId],
            validations: action.payload.validation,
          },
        },
      };
    }

    case SET_PL_RULES_TABLES_PAGINATION: {
      return {
        ...state,
        page: action.payload.page,
      };
    }

    case SET_RULES_TABLES_INITIAL_STATE: {
      return plRulesTablesInitialState;
    }

    default:
      return state;
  }
}
