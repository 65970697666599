import React from "react";
import { InputOptionEnum } from "./RulesTableCell";
import styled from "styled-components";
import { TypeConstrainedCell } from "components";
import {
  DataTypeEnum,
  DomainEnum,
} from "../../../../store/reducers/products/properties/pl-properties.types";

export function FunctionPredicateInput({
  option,
  value,
  id,
  handleCellSubmit,
  domain,
}: {
  option: InputOptionEnum.FUNCTION | InputOptionEnum.PREDICATE;
  value: any;
  id: any;
  handleCellSubmit: (e: any) => void;
  domain: DomainEnum;
}) {
  const parseValue = () => {
    if (
      (domain === DomainEnum.DISCRETE && typeof value === "object") ||
      value === undefined
    )
      return "";
    if (option === InputOptionEnum.FUNCTION) return value.replace("FN:", "");
    if (option === InputOptionEnum.PREDICATE) return value.replace("PRD:", "");
  };

  const parsedValue = parseValue();

  const handleSubmit = (e) => {
    if (option === InputOptionEnum.FUNCTION) {
      handleCellSubmit("FN:" + e);
      return;
    }
    handleCellSubmit("PRD:" + e);
  };

  return (
    <>
      {option === InputOptionEnum.FUNCTION && (
        <Wrapper>
          <StyledPrefix>FN:</StyledPrefix>
          <TypeConstrainedCell
            type="editCell"
            dataType={DataTypeEnum.STRING}
            value={parsedValue}
            id={id}
            handleSubmit={handleSubmit}
          />
        </Wrapper>
      )}
      {option === InputOptionEnum.PREDICATE && (
        <Wrapper>
          <StyledPrefix>PRD:</StyledPrefix>
          <TypeConstrainedCell
            type="editCell"
            dataType={DataTypeEnum.STRING}
            value={parsedValue}
            id={id}
            handleSubmit={handleSubmit}
          />
        </Wrapper>
      )}
    </>
  );
}

const StyledPrefix = styled.div`
  background: ${(props) => props.theme.colors.secondary};
  border-radius: 6px;
  color: white;
  padding: 0 6px;
  font-size: 12px;
  height: 25px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 180px;
`;
