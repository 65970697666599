import React, { useEffect, useRef, useState } from "react";
import { Button, Input } from "components";
import OverlayRightPanel from "containers/OverlayRightPanel/OverlayRightPanel.container";
import styled from "styled-components";
import { useTypedSelector } from "store";
import {
  DataTypeEnum,
  dataTypesOptions,
  DomainEnum,
  domainOptions,
} from "store/reducers/products/properties/pl-properties.types";
import { useDispatch } from "react-redux";
import {
  addPropertyToProductLineAction,
  setNewProperty,
} from "store/reducers/products/properties/pl-properties.actions";
import { determineDataTypeOptions, PROPERTY_NAME_REGEX } from "../helpers";
import useValidateRangeProperty from "components/Validator/useValidateRangeProperty";
import useValidateBooleanDataType from "../../../../../components/Validator/useValidateBooleanDataType";
import SelectDropdownComponent, {
  getSingleValueFromOptions,
  getSelectedValueFromOptions,
  stringArrayToOptions,
} from "../../../../../components/Forms/SelectDropdown/SelectDropdown.component";

export default function AddPropertyButton() {
  const [open, setOpen] = useState(false);
  const [isValidProperty, setIsValidProperty] = useState(false);

  const dispatch = useDispatch();
  const inputRef = useRef<any>(null);

  const { newProperty, posting } = useTypedSelector(
    (state) => state.products.properties
  );
  const { name, dataType, domain, rulesetids } = newProperty;

  const { message: rangePropertyError } = useValidateRangeProperty({
    domain,
    dataType,
  });
  const { message: booleanPropertyError } = useValidateBooleanDataType({
    domain,
    dataType,
  });
  const errors = [booleanPropertyError, rangePropertyError];

  const { ruleSets } =
    useTypedSelector((state) => state.products.parameters) || [];
  const rulesetOptions = ruleSets.map((ruleset) => ({
    value: ruleset.id,
    label: ruleset.name,
  }));

  useEffect(() => {
    if (ruleSets.length === 1 && !rulesetids.length) {
      dispatch(
        setNewProperty({ ...newProperty, rulesetids: [ruleSets[0].id] })
      );
    }
  }, [rulesetids, ruleSets]);

  useEffect(() => {
    setIsValidProperty(
      name &&
        dataType &&
        domain &&
        !!rulesetids.length &&
        !rangePropertyError &&
        !booleanPropertyError
    );
  }, [
    name,
    dataType,
    domain,
    rulesetids,
    rangePropertyError,
    booleanPropertyError,
  ]);

  useEffect(() => {
    if (!dataType) return;
    if (
      domain === DomainEnum.RANGE &&
      [DataTypeEnum.STRING, DataTypeEnum.BOOL].includes(dataType)
    )
      dispatch(setNewProperty({ ...newProperty, dataType: "" }));
  }, [domain]);

  const handlePropertyNameInputChange = (e: any) => {
    const { name, value } = e.target;

    if (value.match(PROPERTY_NAME_REGEX)) {
      dispatch(setNewProperty({ ...newProperty, [name]: value }));
    }
  };

  const handleDropdown = (selectedOptions, name, multi) => {
    const newValue = getSelectedValueFromOptions(multi, selectedOptions);
    dispatch(setNewProperty({ ...newProperty, [name]: newValue }));
  };

  const handleSubmit = () => {
    dispatch(addPropertyToProductLineAction(newProperty));
    setOpen(false);
  };

  useEffect(() => {
    if (open && inputRef.current) {
      setTimeout(() => inputRef.current.focus(), 100);
    }
  }, [open]);

  return (
    <>
      <CustomButton onClick={() => setOpen(true)}>Add Property</CustomButton>
      <OverlayRightPanel
        open={open}
        closeNav={() => setOpen(false)}
        title="New Property"
      >
        {open && (
          <AddPropertyContent>
            <StyledInput
              label="name"
              size="short"
              name="name"
              value={name}
              onChange={handlePropertyNameInputChange}
              inputRef={inputRef}
            />
            <StyledSelectDropdownComponent
              placeholder="Rulesets"
              name="rulesets"
              options={rulesetOptions}
              handleChange={(e) => handleDropdown(e, "rulesetids", true)}
              value={rulesetOptions.filter(({ value }) =>
                rulesetids.includes(value)
              )}
              noShadow
              isMulti
              noHoverBorder
              closeMenuOnSelect={false}
            />
            <StyledSelectDropdownComponent
              placeholder="Domain"
              name="domain"
              options={stringArrayToOptions(domainOptions)}
              handleChange={(value) => handleDropdown(value, "domain", false)}
              value={getSingleValueFromOptions(domainOptions, domain)}
              noShadow
              noHoverBorder
              isSearchable={false}
            />
            <StyledSelectDropdownComponent
              placeholder="Data type"
              name="dataType"
              options={stringArrayToOptions(determineDataTypeOptions(domain))}
              handleChange={(value) => handleDropdown(value, "dataType", false)}
              value={getSingleValueFromOptions(dataTypesOptions, dataType)}
              noShadow
              noHoverBorder
              disabled={!domain}
              isSearchable={false}
            />
            <StyledAddProductLineButton
              onClick={handleSubmit}
              buttonType="submit"
              loading={posting}
              disabled={!isValidProperty}
            >
              Add Property
            </StyledAddProductLineButton>
            <StyledErrorMessage>
              {errors.map((error) => error)}
            </StyledErrorMessage>
          </AddPropertyContent>
        )}
      </OverlayRightPanel>
    </>
  );
}

const StyledSelectDropdownComponent = styled(SelectDropdownComponent)<any>`
  margin-bottom: 32px;
`;

const AddPropertyContent = styled.div`
  padding: 24px;
`;

const StyledAddProductLineButton = styled(Button)`
  width: 100%;

  /* for smooth transition */
  white-space: nowrap;
`;

const StyledInput = styled(Input)`
  margin-bottom: 32px;
`;

const StyledErrorMessage = styled.span<any>`
  color: ${(props) => props.theme.colors.error};
`;

const CustomButton = styled(Button)`
  width: 150px;
  padding: 0;
  height: 38px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
