import React from "react";
import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";

export const Link = (props: any) => {
  return <StyledLink {...props} />;
};

const StyledLink = styled(RouterLink)<any>`
  text-decoration: none;
  color: ${(props) => (props.color ? props.color : "inherit")};
  font-weight: bold;

  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  :hover {
    opacity: ${(props) => (props.disabled ? 0.5 : 0.7)};
  }

  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;
