import { NewProductDto } from "store/reducers/products/products.types";
import { validateOrReject } from "class-validator";

export const validateNewProduct = async ({
  name,
  region,
  displayImage,
}: Partial<NewProductDto>): Promise<true | false | Array<any>> => {
  try {
    const program = new NewProductDto();

    // this avoids a Typescript error, see TS known limitation with Partials: https://stackoverflow.com/questions/54489817/typescript-partialt-type-without-undefined
    // @ts-ignore
    program.name = name;
    // @ts-ignore
    program.region = region;
    // @ts-ignore
    program.displayImage = displayImage;

    await validateOrReject(program, {
      forbidUnknownValues: true,
    });

    return true;
  } catch (errors) {
    return errors;
  }
};
