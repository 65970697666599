import { setRestVerb } from "../../generalActions";
import { api } from "../../../../api/api";

export const SET_LOGIC_GROUPS = "products/plLogicGroups/SET_LOGIC_GROUPS";
export const SET_LOGIC_GROUP = "products/plLogicGroups/SET_LOGIC_GROUP";
export const SET_VERB = "products/plLogicGroups/REST_VERB";
export const SET_LOGIC_GROUPS_INITIAL_STATE =
  "products/plLogicGroups/SET_LOGIC_GROUPS_INITIAL_STATE";

export const getLogicGroupsAction = ({
  productLineId,
  parentLogicGroupId,
}: {
  productLineId: string;
  parentLogicGroupId?: string;
}) => {
  return async (dispatch) => {
    dispatch(setRestVerb("products/plLogicGroups", true, "getting"));
    try {
      const { data } = await api.getLogicGroups(
        productLineId,
        parentLogicGroupId
      );

      if (!data) throw null;

      const newAllIds: Array<any> = [];
      const newById = {};
      data.map((logicGroup) => {
        newAllIds.push(logicGroup.id);
        newById[logicGroup.id] = { ...logicGroup };
      });

      dispatch({
        type: SET_LOGIC_GROUPS,
        payload: { allIds: newAllIds, byId: newById },
      });
    } catch (e) {
      console.error(e, "error while getting logic groups");
    }
    dispatch(setRestVerb("products/plLogicGroups", false, "getting"));
  };
};

export const addLogicGroup = ({
  productLineId,
  logicGroupName,
}: {
  productLineId: string;
  logicGroupName;
}) => {
  return async (dispatch) => {
    dispatch(setRestVerb("products/plLogicGroups", true, "posting"));

    try {
      const { data } = await api.createLogicGroup(productLineId, {
        name: logicGroupName,
      });

      if (!data) throw null;

      dispatch({ type: SET_LOGIC_GROUP, payload: { data } });
    } catch (e) {
      console.error(e, "error while creating logic group");
    }

    dispatch(setRestVerb("products/plLogicGroups", false, "posting"));
  };
};

export const removeLogicGroup = () => {
  return async (dispatch) => {
    dispatch(setRestVerb("products/plLogicGroups", true, "deleting"));
    try {
    } catch (e) {
      console.error(e, "error while deleting logic group");
    }
    dispatch(setRestVerb("products/plLogicGroups", false, "deleting"));
  };
};
