import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { Alert, IconButton, TextField, Tooltip } from "@mui/material";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { H4, ModalConfirmDialog, Table } from "components";
import { getPvaTableData, GetTableData } from "./helpers";
import { DomainEnum } from "store/reducers/products/properties/pl-properties.types";
import colors from "theme/colors";
import {
  Ruleset,
  Tag,
} from "../../../../store/reducers/products/parameters/pl-parameters.types";
import ManagePVA from "./ManagePVA/ManagePVA";
import { useDispatch } from "react-redux";
import {
  addPvaRowToProperty,
  clonePropertyAction,
  removePropertyValueRowFromProperty,
  reOrderPropertyValueAction,
} from "store/reducers/products/properties/pl-properties.actions";
import { useHistory } from "react-router-dom";
import {
  addPvaRowToSubProperty,
  removePvaRowFromSubProperty,
  reOrderSubPropertyValueAction,
} from "store/reducers/products/subproperties/pl-subproperties.actions";
import { useParentPropertyContext } from "../../pages/ProductDetail/ProductLineProperties/ParentPropertyContext";
import RemovePropertyModal from "../../pages/ProductDetail/ProductLineProperties/RemovePropertieModal.component";
import { CommentsAndSources } from "../CommentsAndSources.component";
import Validator from "../../../../components/Validator/Validator.component";
import { ObjectAssignedEnum } from "../../../../store/reducers/products/validation/validation.types";
import GraphvizPopUp from "./GraphvizPopUp";
import { PROPERTY_NAME_REGEX } from "pages/Products/pages/ProductDetail/helpers";

export function PropertiesDefinitionTable({
  propertyData,
  ruleSets = [],
  tags = [],
  type = "main",
}: {
  propertyData: any;
  ruleSets?: Ruleset[];
  tags?: Tag[];
  type: "main" | "subproperties" | "standardSubProperty";
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { parentId, productLineId } = useParentPropertyContext();
  const { domain, name } = propertyData as { domain: string; name: string };

  //State
  const [showCommentsAndSources] = useState<boolean>(true);
  const [canAddRow, setCanAddRow] = useState(
    propertyData.domain === DomainEnum.DISCRETE
  );
  const [openGraphviz, setOpenGraphviz] = useState<boolean>(false);
  const [openCloneDialog, setOpenCloneDialog] = useState<boolean>(false);
  const [cloneName, setCloneName] = useState<string>("");
  const [cloneNameError, setCloneNameError] = useState<string>("");

  //Refs
  const clonePropNameRef = useRef<HTMLInputElement>(null);

  //useMemo
  const relatedTablesURL: string = useMemo(() => {
    return `/products/${propertyData.productLineId}/rules-tables?propertyIds=${propertyData.id}`;
  }, [propertyData.productLineId, propertyData.id]);

  //useEffects
  useEffect(() => {
    if (openCloneDialog) {
      //Give the Clone name area focus and select the text
      setTimeout(() => {
        if (clonePropNameRef.current) {
          clonePropNameRef.current.focus();
        }
      }, 100);
    }
  }, [openCloneDialog]);

  // TODO: `data` and `subPropertyData` are computed everytime
  // but the component only renders one or the other
  // shall be protected with conditional logic or put in diff components
  const data = GetTableData(propertyData, type, ruleSets, tags);

  const selectedRulesets = propertyData.ruleSets.map((ruleset) => ({
    value: ruleset.id,
    name: ruleset.name,
  }));

  //event handlers

  const handleCloneButtonClick = () => {
    //Reset clone name to base state
    setCloneName("");
    setCloneNameError("");

    //Open clone dialog
    setOpenCloneDialog(true);
  };

  const handleClone = () => {
    if (validateCloneName(cloneName)) {
      //Send to api to clone the property
      dispatch(clonePropertyAction(parentId, cloneName));

      //Close the dialog
      setOpenCloneDialog(false);
    } else {
      //Validation of name occurred, give it focus for correction
      if (clonePropNameRef.current) {
        clonePropNameRef.current.focus();
        clonePropNameRef.current.select();
      }
    }
  };

  const handleCloneNameChange = (e) => {
    //Only allow valid property name characters
    const inputValue = e.target.value;
    if (inputValue.match(PROPERTY_NAME_REGEX)) {
      //persist the new clone property name
      setCloneName(inputValue);

      //Validate the cloned property name
      validateCloneName(inputValue);
    }
  };

  const handleTableLinkClick = (e) => {
    // Only handle the navigation if the click is a left-click (button 0)
    if (e.button === 0) {
      e.preventDefault();

      //Jump to the related tables
      history.push(relatedTablesURL);
    }
  };

  const validateCloneName = (cloneName: string): boolean => {
    //May not be blank
    if (cloneName.trim().length === 0) {
      setCloneNameError("Specify a new name for the cloned property");
      return false;
    }

    //May not be same as original name
    if (cloneName.trim().toLowerCase() === name.trim().toLowerCase()) {
      setCloneNameError("New name may not be the same as the original name");
      return false;
    }

    //Ensure that the property name meets our property naming restrictions
    if (!cloneName.match(PROPERTY_NAME_REGEX)) {
      setCloneNameError("New name does not follow property naming guidelines");
      return false;
    }

    //Name is valid
    setCloneNameError("");
    return true;
  };

  const pvaData = getPvaTableData(
    propertyData,
    dispatch,
    selectedRulesets,
    type,
    parentId
  );
  const subPropertyData = GetTableData(propertyData, type, ruleSets, tags);
  const pvaTableOptions = {
    canAddRow: canAddRow,
    addRowAction: () => {
      setCanAddRow(false);
      setTimeout(() => setCanAddRow(true), 150);
      if (type === "main") {
        dispatch(addPvaRowToProperty({}, propertyData));
      }
      if (type === "subproperties" || type === "standardSubProperty") {
        dispatch(addPvaRowToSubProperty({}, propertyData, parentId || ""));
      }
    },
    addRowContent: {},
    show: DomainEnum.DISCRETE === domain,
    rowDeleteAction: (row: any) => {
      try {
        if (type === "main")
          dispatch(
            removePropertyValueRowFromProperty(
              propertyData,
              row[0].propertyValueRowId
            )
          );
        if (type === "subproperties" || "standardSubProperty")
          dispatch(
            removePvaRowFromSubProperty(
              propertyData,
              row[0].propertyValueRowId,
              parentId
            )
          );
      } catch (e) {}
    },
  };

  const reOrderPropertyValues = (
    items: any,
    target,
    destinationPosition,
    sourcePosition
  ) => {
    type === "main"
      ? dispatch(
          reOrderPropertyValueAction({
            propertyId: propertyData.id,
            propertyValueId: target,
            destinationPosition,
            sourcePosition,
          })
        )
      : dispatch(
          reOrderSubPropertyValueAction({
            parentId,
            isStandardSubProperty: propertyData.isStandardSubProp,
            propertyId: propertyData.id,
            propertyValueId: target,
            destinationPosition,
            sourcePosition,
          })
        );
  };

  return (
    <Wrapper>
      <HeaderWrapper>
        {type === "main" && (
          <>
            <H4 bold color={colors.muttedHeadlines}>
              Main Property
            </H4>
            <a
              href={relatedTablesURL}
              target="_blank"
              rel="noopener noreferrer"
              onClick={handleTableLinkClick}
              style={{ textDecoration: "none" }}
            >
              <Tooltip title="See Related Tables" arrow>
                <IconButton
                  size="medium"
                  color="primary"
                  aria-label="related tables"
                >
                  <TableChartOutlinedIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </a>

            <Tooltip title="Visualize Related Properties" arrow>
              <IconButton
                size="medium"
                color="primary"
                aria-label="visualize related properties"
                onClick={() => {
                  setOpenGraphviz(true);
                }}
              >
                <VisibilityIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
            <GraphvizPopUp
              open={openGraphviz}
              onClose={() => setOpenGraphviz(false)}
              productLineId={productLineId}
              propertyData={propertyData}
              ruleSets={propertyData.ruleSets}
            />

            <Tooltip title="Clone Property and all Subproperties" arrow>
              <IconButton
                size="medium"
                color="primary"
                aria-label="clone property"
                onClick={handleCloneButtonClick}
              >
                <CopyAllIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
            <ModalConfirmDialog
              open={openCloneDialog}
              isWarning={false}
              title={`Clone Property: ${propertyData.name}`}
              content={
                <div>
                  <p>Enter a new name for the cloned property.</p>
                  <TextField
                    variant="outlined"
                    size="small"
                    label="New Property Name"
                    id="name"
                    name="name"
                    value={cloneName}
                    helperText={cloneNameError}
                    error={Boolean(cloneNameError)}
                    onChange={handleCloneNameChange}
                    inputRef={clonePropNameRef}
                    fullWidth
                    sx={{
                      width: 350,
                    }}
                  />
                </div>
              }
              defaultButton="OK"
              cancelButtonText="Cancel"
              okButtonText="Clone"
              onCancelButtonClick={() => setOpenCloneDialog(false)}
              onOKButtonClick={handleClone}
            />

            <RemovePropertyModal
              propertyId={propertyData.id}
              productLineId={productLineId}
            />
          </>
        )}
        {(type === "subproperties" || type === "standardSubProperty") && (
          <CustomSubPropertiesHeader>
            <RemovePropertyModal
              propertyId={propertyData?.id ?? ""}
              productLineId={productLineId}
              parentId={parentId}
              type={"sub"}
            />
          </CustomSubPropertiesHeader>
        )}
      </HeaderWrapper>

      <Validator
        objectType={ObjectAssignedEnum.PROPERTY}
        validations={propertyData.validations}
        objectData={propertyData}
        isSubProperty={type === "subproperties"}
        isStandardSubProperty={type === "standardSubProperty"}
      />

      {propertyData.ownerTool && (
        <Alert severity="warning">
          This property is owned by Tool &quot;{propertyData.ownerTool}&quot;.
          Any edits may be overwritten.
        </Alert>
      )}

      <Table data={type === "main" ? data : subPropertyData} size={"small"} />
      {pvaTableOptions.show && type === "main" && (
        <>
          <StyledPvaTable
            data={pvaData}
            {...pvaTableOptions}
            size={"small"}
            onDragEndActionRows={reOrderPropertyValues}
          />
          {propertyData.id && <ManagePVA property={propertyData} type={type} />}
        </>
      )}
      {pvaTableOptions.show &&
        (type === "subproperties" || type === "standardSubProperty") && (
          <>
            <StyledPvaTable
              data={pvaData}
              {...pvaTableOptions}
              size={"small"}
              onDragEndActionRows={reOrderPropertyValues}
            />
            {propertyData.id && (
              <ManagePVA type={type} property={propertyData} />
            )}
          </>
        )}
      {type !== "standardSubProperty" && showCommentsAndSources && (
        <CommentsAndSources
          data={propertyData}
          parentId={type === "subproperties" ? parentId : undefined}
        />
      )}
    </Wrapper>
  );
}

const StyledPvaTable = styled(Table)`
  margin-top: 8px;
  margin-right: 24px;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  margin-right: 24px;
`;

const HeaderWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;

  //Children margins
  & > * {
    margin-top: 0px; //will not be 0, but will go to a min value
    margin-bottom: 0px; //will not be 0, but will go to a min value
  }
`;

const CustomSubPropertiesHeader = styled.div`
  border-top: 1px solid ${colors.tables.borders};
  width: 100%;
  display: flex;
  padding: 8px;
  justify-content: flex-end;
`;
