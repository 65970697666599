import { StandardSubPropertiesOptions } from "./pl-subproperties.types";

export const containsStandardSubPropertyName = (
  name: string
): undefined | string => {
  const subPNamesArray = name.split(".");
  subPNamesArray.shift();
  return subPNamesArray.find((subPName: string) => {
    return StandardSubPropertiesOptions.find((subPDefaultName) => {
      return (
        subPDefaultName.toLocaleLowerCase() === subPName.toLocaleLowerCase()
      );
    });
  });
};
