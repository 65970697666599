import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import styled from "styled-components";
import { Tooltip } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { ModalConfirmDialog } from "components";
import { removePropertyFromRuleTable } from "store/reducers/products/rulestables/pl-rulestables.actions";
import { useRulesTableContext } from "../../pages/ProductDetail/ProductLineRulesTables/RulesTableContext";
import { TablesTypeEnum } from "../../../../store/reducers/products/rulestables/pl-rulestables.types";
import { GetParentPropertyName } from "modules/utility/utility";

const isLookupItem = (propertyIndex, tableLookupIndex) => {
  return propertyIndex <= tableLookupIndex;
};

const isPva = (propertyName) => {
  return propertyName.includes("::");
};

const checkValidity = (propertyName, ruleTableData, index) => {
  const { tableType, lookUpFieldIndex } = ruleTableData;

  // check if table type is INCLUDE or EXCLUDE
  if (![TablesTypeEnum.ATTR_LOOKUP, TablesTypeEnum.LOOKUP].includes(tableType))
    return true;

  // check validity if table type is ATTR_LOOKUP
  if (tableType === TablesTypeEnum.ATTR_LOOKUP) {
    if (!isLookupItem(index, lookUpFieldIndex)) return true;
    return isLookupItem(index, lookUpFieldIndex) && isPva(propertyName);
  }

  // check validity if table type is LOOKUP
  if (!isLookupItem(index, lookUpFieldIndex)) return true;
  return isLookupItem(index, lookUpFieldIndex) && !isPva(propertyName);
};

export default function RulesTablePropertyHeader({
  propertyName,
  propertyId,
  ruleTableId,
  index,
}: {
  propertyName: string;
  propertyId: string;
  ruleTableId: string;
  index: number;
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const data = useRulesTableContext();

  // State
  const [isValid, setIsValid] = useState<boolean>(
    checkValidity(propertyName, data, index)
  );
  const [isLookupItem, setIsLookupItem] = useState<boolean>(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);

  const { productLineId } = useParams<{ productLineId: string }>();

  // useEffects
  useEffect(() => {
    setIsValid(checkValidity(propertyName, data, index));
  }, [index, data.lookUpFieldIndex, data.tableType]);

  useEffect(() => {
    setIsLookupItem(
      isValid &&
        index <= data.lookUpFieldIndex &&
        (data.tableType === TablesTypeEnum.ATTR_LOOKUP ||
          data.tableType === TablesTypeEnum.LOOKUP)
    );
  }, [index, data.lookUpFieldIndex, isValid, data.tableType]);

  //useMemos
  const parentPropertyName: string = useMemo(() => {
    return GetParentPropertyName(propertyName);
  }, [propertyName]);

  const parentPropertyURL: string = useMemo(() => {
    return `/products/${productLineId}/properties?name=${parentPropertyName}`;
  }, [productLineId, parentPropertyName]);

  const propertyToolTipText: string = useMemo(() => {
    if (!isValid) return "Invalid LOOKUP property";
    return `View property ${propertyName}`;
  }, [propertyName, isValid]);

  const propertyTextColor = useMemo(() => {
    if (!isValid) return "red";
    if (isLookupItem) return "green";
    return "black";
  }, [isValid, isLookupItem]);

  //event handlers
  const handlePropertyLinkClick = (e) => {
    // Only handle the navigation if the click is a left-click (button 0)
    if (e.button === 0) {
      e.preventDefault();

      //Jump to the parent property definition
      history.push(parentPropertyURL);
    }
  };

  return (
    <Wrapper>
      <Tooltip title={propertyToolTipText} arrow>
        <a
          href={parentPropertyURL}
          target="_blank"
          rel="noopener noreferrer"
          onClick={handlePropertyLinkClick}
          style={{
            color: propertyTextColor,
            display: "block",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        >
          {propertyName}
        </a>
      </Tooltip>
      <Tooltip title={`Remove Property ${propertyName}`} arrow>
        <StyledDeleteIcon
          fontSize="small"
          onClick={() => setIsDeleteDialogOpen(true)}
        />
      </Tooltip>
      <ModalConfirmDialog
        open={isDeleteDialogOpen}
        isWarning={true}
        title="Remove Property"
        content={
          <div>
            <p>
              Are you sure you want to remove the property: {propertyName} from
              the table ?
            </p>
            <strong>This will delete all column data!</strong>
          </div>
        }
        defaultButton="CANCEL"
        cancelButtonText="Cancel"
        okButtonText="Delete"
        onCancelButtonClick={() => setIsDeleteDialogOpen(false)}
        onOKButtonClick={() =>
          dispatch(removePropertyFromRuleTable(ruleTableId, propertyId))
        }
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  display: table-cell;
  text-align: center;

  :hover {
    svg {
      display: block;
    }
  }
  svg {
    width: 30px !important;
  }
`;

const StyledDeleteIcon = styled(DeleteOutlineIcon)`
  color: rgb(2, 123, 253);
  position: absolute;
  opacity: 0.25;
  right: -49px;
  top: 9px;
  cursor: pointer;
  :hover {
    opacity: 1;
  }
`;
