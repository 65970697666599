import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import userReducer from "./reducers/user/user.reducer";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import notificationsReducer from "./reducers/notifications/notifications.reducer";
import programsReducer from "./reducers/programs/programs.reducer";
import productsReducer from "./reducers/products/products.reducer";
import usersReducer from "./reducers/users/users.reducer";

export const store = configureStore({
  reducer: {
    user: userReducer,
    users: usersReducer,
    notifications: notificationsReducer,
    programs: programsReducer,
    products: productsReducer,
  },
  devTools: process.env.NODE_ENV !== "production",
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

// Use this instead of `useSelector`
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
