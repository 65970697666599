import React, { useState } from "react";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { ModalConfirmDialog } from "components";
import styled from "styled-components";
import { deleteProductLineAction } from "store/reducers/products/products.actions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

export default function DeleteProductLineButton({
  productLineId,
  disabled,
}: {
  productLineId?: string;
  disabled?: boolean;
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [open, setOpen] = useState(false);

  if (!productLineId) return <></>;

  const handleDeleteProductLine = async () => {
    dispatch(deleteProductLineAction(productLineId, history));
    setOpen(false);
  };

  return (
    <Wrapper>
      <ModalConfirmDialog
        open={open}
        isWarning={true}
        title="Delete Product Line"
        content={
          <div>
            <p>Are you sure you want to delete the current product line?</p>
            <strong>This will delete all productline data!</strong>
          </div>
        }
        defaultButton="CANCEL"
        cancelButtonText="Cancel"
        okButtonText="Delete"
        onCancelButtonClick={() => setOpen(false)}
        onOKButtonClick={handleDeleteProductLine}
      />

      <StyledButton
        variant="outlined"
        size="small"
        color="error"
        startIcon={<DeleteIcon />}
        onClick={() => setOpen(true)}
        disabled={disabled}
      >
        Delete Product Line
      </StyledButton>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledButton = styled(Button)`
  height: 32px;
  width: 250px;
`;
