import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import ProgramsMain from "./pages/ProgramsMain/ProgramsMain.page";
import ProgramDetails from "./pages/ProgramDetails/ProgramDetails.page";
import styled from "styled-components";

export default function ProgramsPage() {
  const { path } = useRouteMatch();

  return (
    <Wrapper>
      <Switch>
        <Route exact path={path}>
          <ProgramsMain />
        </Route>
        <Route path={path + "/:id/overview"}>
          <ProgramDetails />
        </Route>
      </Switch>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-right: 40px;
`;
