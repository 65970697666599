import React, { useEffect, useMemo, useRef, useState } from "react";
import { Alert, Autocomplete, TextField, Tooltip } from "@mui/material";
import { ModalConfirmDialog } from "components";
import DisplayUnitsDataGrid from "./DisplayUnitsDataGrid";
import {
  DisplayUnitsDataGridRow,
  UnitInfo,
  UnitPickerDialogProps,
} from "./UnitPickerInterfaces";
import {
  CreateDisplayUnitsDataGridRow,
  GetAllUnits,
  GetGroupUnits,
  GetPropertyValues,
  GetUnitGroupFromUnit,
  ValidateRoundDigits,
  ValidateRoundMethod,
} from "./UnitPickerUtility";
import { useTypedSelector } from "store";
import {
  ParentPropertyContextType,
  useParentPropertyContext,
} from "../../pages/ProductDetail/ProductLineProperties/ParentPropertyContext";
import { StandardSubProperties } from "store/reducers/products/subproperties/pl-subproperties.types";
import { Property } from "store/reducers/products/properties/pl-properties.types";

export const UnitPickerDialog: React.FC<UnitPickerDialogProps> = ({
  open,
  setOpen,
}) => {
  // State
  const [primaryUnit, setPrimaryUnit] = useState<UnitInfo | null>(null);
  const [activeGroupUnits, setActiveGroupUnits] = useState<string[]>([]);
  const [rows, setRows] = useState<DisplayUnitsDataGridRow[]>([]);
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  // References
  const allUnitsRef = useRef<UnitInfo[]>([]);
  useEffect(() => {
    allUnitsRef.current = GetAllUnits();
  }, []);

  // Redux Store
  const { subproperties } = useTypedSelector((state) => state.products);

  // context variables
  const parentPropertyContext: ParentPropertyContextType = useParentPropertyContext();

  useEffect(() => {
    //Load the component state from the parent propery and subproperties
    loadStateFromProperties();
  }, []);

  useEffect(() => {
    console.log("UnitPickerDialog primaryUnit changed = ", primaryUnit);

    if (!loading) {
      console.log("UnitPickerDialog primaryUnit not loading");
      //Get the units for the new primary unit's group
      const groupUnits: string[] = GetGroupUnits(
        primaryUnit?.group,
        allUnitsRef.current
      );

      //Sync the rows so they only contain display units in the primary unit's group
      //and also contains the primary unit.
      const newRows = syncRowsToPrimaryUnit(
        primaryUnit?.label,
        groupUnits,
        rows
      );

      //Save the group units and new rows to state
      setActiveGroupUnits(groupUnits);
      setRows(newRows);
    }
  }, [primaryUnit]);

  const syncRowsToPrimaryUnit = (
    primaryUnitValue: string | undefined,
    groupUnits: string[],
    displayUnitRows: DisplayUnitsDataGridRow[]
  ): DisplayUnitsDataGridRow[] => {
    console.log("syncRowsToPrimaryUnit primaryUnitValue = ", primaryUnitValue);
    console.log("syncRowsToPrimaryUnit groupUnits = ", groupUnits);
    console.log("syncRowsToPrimaryUnit displayUnitRows = ", displayUnitRows);
    //Remove any display unit rows that aren't in the primary unit's group
    const newRows: DisplayUnitsDataGridRow[] = displayUnitRows.filter((r) =>
      groupUnits.includes(r.displayUnit)
    );
    console.log(
      "syncRowsToPrimaryUnit newRows after group filter = ",
      ...newRows
    );

    //If the primary unit is not in the rows, add it.
    if (primaryUnitValue) {
      //Determine if existing rows already have a default display unit
      const hasDefaults = newRows.filter((r) => r.isDefault).length > 0;
      console.log("syncRowsToPrimaryUnit hasDefaults = ", hasDefaults);

      //Find the row for the primary unit, if it exists
      const primaryUnitRow = newRows.find(
        (r) => r.displayUnit === primaryUnitValue
      );
      console.log("syncRowsToPrimaryUnit primaryUnitRow = ", primaryUnitRow);

      //If we found the primary unit row
      if (primaryUnitRow) {
        //If no default display unit already selected, mark the primary unit as default
        if (!hasDefaults) primaryUnitRow.isDefault = true;
      }

      //We don't have a row for the primary unit.  Add it to beginning of display unit rows.
      else {
        newRows.unshift(
          CreateDisplayUnitsDataGridRow(primaryUnitValue, !hasDefaults)
        );
      }
    }

    return newRows;
  };

  useEffect(() => {
    console.log(
      "UnitPickerDialog activeGroupUnits changed = ",
      activeGroupUnits
    );
  }, [activeGroupUnits]);

  useEffect(() => {
    console.log("UnitPickerDialog rows changed = ", rows);
  }, [rows]);

  const loadStateFromProperties = () => {
    console.log("UnitPickerDialog.loadStateFromProperties BEGIN");

    //Get the Primary Unit from .UNIT subproperty
    const primaryUnitValue: string = getPrimaryUnit();
    if (primaryUnitValue) {
      const unit: UnitInfo = allUnitsRef.current.filter(
        (u) => u.label === primaryUnitValue
      )[0];
      if (unit) setPrimaryUnit(unit);
    }

    //Get the unit group from the primaryUnitValue and the units for that group
    const unitGroup: string = GetUnitGroupFromUnit(primaryUnitValue);
    console.log(
      "UnitPickerDialog.loadStateFromProperties unitGroup = ",
      unitGroup
    );
    const groupUnits: string[] = GetGroupUnits(unitGroup, allUnitsRef.current);
    console.log(
      "UnitPickerDialog.loadStateFromProperties groupUnits = ",
      groupUnits
    );
    setActiveGroupUnits(groupUnits);

    //Build the Display Units
    let displayUnitRows: DisplayUnitsDataGridRow[] = getDisplayUnits();
    console.log(
      "UnitPickerDialog.loadStateFromProperties rows = ",
      ...displayUnitRows
    );

    //Load the Default Unit into the rows
    const defaultUnit: string = getDefaultUnit(primaryUnitValue);
    console.log(
      "UnitPickerDialog.loadStateFromProperties defaultUnit = ",
      defaultUnit
    );
    const defaultRow: DisplayUnitsDataGridRow | null | undefined = defaultUnit
      ? displayUnitRows.find((row) => row.displayUnit === defaultUnit)
      : null;
    console.log(
      "UnitPickerDialog.loadStateFromProperties defaultRow = ",
      defaultRow
    );
    if (defaultRow) defaultRow.isDefault = true;
    console.log(
      "UnitPickerDialog.loadStateFromProperties rows with default = ",
      ...displayUnitRows
    );

    //Sync the primary unit to the rows
    displayUnitRows = syncRowsToPrimaryUnit(
      primaryUnitValue,
      groupUnits,
      displayUnitRows
    );

    //Set the rows prop
    if (displayUnitRows) setRows(displayUnitRows);

    //Flag loading is complete
    setTimeout(() => {
      setLoading(false);
    }, 300);

    console.log("UnitPickerDialog.loadStateFromProperties END");
  };

  const getPrimaryUnit = (): string => {
    console.log("UnitPickerDialog.getPrimaryUnit BEGIN");

    let unitValue = "";

    //See if the .UNIT subproperty exists for the current parent property
    const unitSubProp = findSubProperty(StandardSubProperties.UNIT);
    if (unitSubProp) {
      console.log(
        "UnitPickerDialog.getPrimaryUnit subproperty = ",
        unitSubProp
      );

      //Read the domain values of the subproperty and get the first one for the .UNIT
      const values: string[] | undefined = GetPropertyValues(
        unitSubProp
      )?.filter((v) => {
        if (v) return v;
      });
      console.log("UnitPickerDialog.getPrimaryUnit values = ", values);
      unitValue = values && values.length > 0 ? values[0] : "";

      //Verify that the unit value is a legitimate unit
      if (!allUnitsRef.current.find((ui) => ui.label === unitValue))
        unitValue = "";
    }
    console.log("UnitPickerDialog.getPrimaryUnit value = ", unitValue);
    return unitValue;
  };

  const getDisplayUnits = (): DisplayUnitsDataGridRow[] => {
    console.log("UnitPickerDialog.loadDisplayUnits BEGIN");

    const displayUnitRows: DisplayUnitsDataGridRow[] = [];

    //See if the .DISPLAYUNIT subproperty exists for the current parent property
    const displayUnitSubProp = findSubProperty(
      StandardSubProperties.DISPLAYUNIT
    );
    if (
      displayUnitSubProp &&
      displayUnitSubProp.propertyValues &&
      displayUnitSubProp.propertyValues.length > 0
    ) {
      console.log(
        "UnitPickerDialog.loadDisplayUnits subproperty = ",
        displayUnitSubProp
      );

      //Read the domain values of the subproperty and create a grid row for each
      displayUnitSubProp.propertyValues.forEach((pv) => {
        let value = pv?.valueAttributeCells.find((vac) => {
          if (vac.propertyValueAttribute.name === "Value") return vac;
        })?.value;
        if (!value) value = "";
        console.log("value = ", value);

        let roundMethod = pv?.valueAttributeCells.find((vac) => {
          if (vac.propertyValueAttribute.name === "RoundMethod")
            return vac.value;
        })?.value;
        roundMethod = ValidateRoundMethod(roundMethod);
        console.log("roundMethod = ", roundMethod);

        let roundDigits = pv?.valueAttributeCells.find((vac) => {
          if (vac.propertyValueAttribute.name === "RoundDigits")
            return vac.value;
        })?.value;
        console.log("pre-validate roundDigits = ", roundDigits);
        roundDigits = ValidateRoundDigits(roundDigits);
        console.log("post-validate roundDigits = ", roundDigits);

        //Create a new row for the grid
        const row: DisplayUnitsDataGridRow = CreateDisplayUnitsDataGridRow(
          value,
          false,
          roundMethod,
          roundDigits
        );
        console.log(
          "UnitPickerDialog.loadDisplayUnits adding displayunit row = ",
          row
        );
        displayUnitRows.push(row);
      });
    }

    return displayUnitRows;
  };

  const getDefaultUnit = (primaryUnitValue: string): string => {
    console.log("UnitPickerDialog.loadDefaultUnit BEGIN");

    //Determine what our default value should be
    // 1) If there is a .DISPLAYUNIT.DEFAULT subproperty
    // 2) If .DISPLAYUNIT.DEFAULT subproperty has at least one, non-null value, use first.
    // 3) Failing the above, if there is a non-null primary unit, use that.
    let defaultValue = "";

    //See if the .DISPLAYUNIT.DEFAULT subproperty exists
    const defaultSubProp = findSubProperty(
      StandardSubProperties.DISPLAYUNIT_DEFAULT
    );
    if (defaultSubProp) {
      console.log(
        "UnitPickerDialog.loadDefaultUnit subproperty = ",
        defaultSubProp
      );

      //Read the domain values of the subproperty and get the first one for the .UNIT
      const values: string[] | undefined = GetPropertyValues(
        defaultSubProp
      )?.filter((v) => v !== null);
      console.log("UnitPickerDialog.loadDefaultUnit values = ", values);

      // See if we have at least one value, if so use that as our default value.
      if (values && values.length > 0) {
        defaultValue = values[0];
      }
      console.log("UnitPickerDialog.loadDefaultUnit value = ", defaultValue);
    }

    //If we still have no default value, see if there is a primary unit
    if (!defaultValue) {
      if (primaryUnitValue) defaultValue = primaryUnitValue;
    }

    return defaultValue;
  };

  const findSubProperty = (subpropName: string): Property | null => {
    //Build the subprop name
    const fullSubPropName =
      parentPropertyContext.parentPropertyName + subpropName;

    //Find all the detail on the parent property's subproperties
    const mySubproperties =
      subproperties.byParentPropertyId[parentPropertyContext.parentId];
    if (!mySubproperties) return null;

    let targetSubProperty = null;
    for (const key in mySubproperties.standardById) {
      const subProperty = mySubproperties.standardById[key];
      if (subProperty.name === fullSubPropName) {
        targetSubProperty = subProperty;
        break;
      }
    }

    return targetSubProperty;
  };

  //Build the content section for the dialog
  const buildContent = useMemo(() => {
    console.log("UnitPickerDialog buildContent");
    return (
      <div>
        <Tooltip
          title="The selected Primary Unit will become the value of the .UNIT subproperty. This is the unit of measure the rules will use for the value of the RANGE property and any .MIN and .MAX subproperty values."
          placement="right"
          arrow
        >
          <Autocomplete
            id="primaryUnitDropdown"
            value={primaryUnit}
            onChange={(event: any, newValue: UnitInfo | null) => {
              setPrimaryUnit(newValue);
            }}
            autoHighlight
            blurOnSelect
            options={allUnitsRef.current}
            groupBy={(option) => option.group}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Primary Unit"
                size="small"
                error={Boolean(!primaryUnit)}
                helperText={primaryUnit ? "" : "Please supply a Primary Unit"}
              />
            )}
            sx={{ width: 300, marginTop: 1, marginBottom: 2 }}
          />
        </Tooltip>
        <DisplayUnitsDataGrid
          rows={rows}
          setRows={setRows}
          displayUnitOptions={activeGroupUnits}
        />
        {showWarning && (
          <Alert variant="filled" severity="warning">
            Clicking the &quot;Create Unit Subproperties&quot; button will
            replace any existing .UNIT, .DISPLAYUNIT and .DISPLAYUNIT.DEFAULT
            subproperties.
          </Alert>
        )}
      </div>
    );
  }, [
    allUnitsRef,
    activeGroupUnits,
    primaryUnit,
    setPrimaryUnit,
    rows,
    setRows,
    showWarning,
  ]);

  return (
    <ModalConfirmDialog
      open={open}
      isWarning={false}
      title={`Configure Unit Subproperties Macro`}
      content={buildContent}
      defaultButton="OK"
      cancelButtonText="Cancel"
      okButtonText="Create UNIT Subproperties"
      onCancelButtonClick={() => setOpen(false)}
      onOKButtonClick={() => setOpen(false)}
    />
  );
};

export default ModalConfirmDialog;
