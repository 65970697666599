import { Property } from "../../../../../store/reducers/products/propertyPriorities/pl-propertyPriorities.types";
import { StyledTable, TypeConstrainedCell } from "../../../../../components";
import { DataTypeEnum } from "../../../../../store/reducers/products/properties/pl-properties.types";
import React from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import {
  editPropertyInPriorityList,
  editPropertyPriorityList,
  removePropertyFromPriorityList,
} from "../../../../../store/reducers/products/propertyPriorities/pl-propertyPriorities.action";
import { Close } from "../../../../../assets/logos/icons/MicroIcons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { reorder } from "components/Table/helper";

export default function PropertiesListTable({
  properties,
  propertyPriorityId,
}: {
  properties: Property[];
  propertyPriorityId: string;
}) {
  const dispatch = useDispatch();

  const onChange = (e: any, propertyId: string): void => {
    const editPropertyObject = { propertyId: propertyId, fixed: e };
    dispatch(
      editPropertyInPriorityList({
        propertyPrioritiesId: propertyPriorityId,
        editPropertyObject,
      })
    );
  };

  const handleDelete = (propertyId: string) => {
    dispatch(
      removePropertyFromPriorityList({
        propertyPrioritiesId: propertyPriorityId,
        propertyIds: [propertyId],
      })
    );
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      properties,
      result.source.index,
      result.destination.index
    );

    dispatch(
      editPropertyPriorityList({
        propertyPrioritiesId: propertyPriorityId,
        newPropertyList: { properties: items },
      })
    );
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={"list" + "header"}>
        {(provided, snapshot) => (
          <StyledTable
            ref={provided.innerRef}
            {...provided.droppableProps}
            isDraggingOver={snapshot.isDraggingOver}
          >
            {properties.map((property, index) => {
              return (
                <Draggable
                  draggableId={property.propertyId}
                  index={index}
                  key={property.propertyId}
                  isDragDisabled={false}
                >
                  {(provided, snapshot) => {
                    return (
                      <Tr
                        key={property.propertyId}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        isDragging={snapshot.isDragging}
                      >
                        <PropertyCell>
                          <StyledClose
                            onClick={() => handleDelete(property.propertyId)}
                          />
                          {property.propertyName}
                        </PropertyCell>
                        <FixedCell>
                          <TypeConstrainedCell
                            type="editCell"
                            dataType={DataTypeEnum.BOOL}
                            handleSubmit={(e) =>
                              onChange(e, property.propertyId)
                            }
                            value={property.fixed.toString()}
                            background={"white"}
                          />
                        </FixedCell>
                        {provided.placeholder}
                      </Tr>
                    );
                  }}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </StyledTable>
        )}
      </Droppable>
    </DragDropContext>
  );
}

const Tr = styled.tr<any>`
  background-color: ${(props) =>
    props.isDragging ? `${props.theme.colors.projectPaused}` : "white"};
  * {
    background-color: ${(props) =>
      props.isDragging
        ? `${props.theme.colors.projectPaused}`
        : "white"} !important;
  }
`;

const StyledClose = styled(Close)<any>`
  display: none;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%) scale(0.7);
  left: 2px;
  cursor: pointer;
`;

const FixedCell = styled.td<any>`
  width: 100px;
`;

const PropertyCell = styled.td<any>`
  position: relative;

  &:hover ${StyledClose} {
    display: block;
  }
`;
