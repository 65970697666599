import { SET_VERB, SET_USERS } from "./users.actions";
import { UsersReducerObject } from "./users.types";

export interface UserReducerState {
  users?: UsersReducerObject;

  // rest verbs
  getting: boolean;
  posting: boolean;
  deleting: boolean;
}

const initialState: UserReducerState = {
  users: undefined,

  // rest verbs
  getting: false,
  posting: false,
  deleting: false,
};

export default function usersReducer(
  state: UserReducerState = initialState,
  action: any
): UserReducerState {
  switch (action.type) {
    case SET_USERS: {
      return {
        ...state,
        users: action.payload,
      };
    }

    case SET_VERB: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
}
