// tsx extension so hex colors are displayed

export interface Colors {
  white: string;
  slate: string;
  error: string;
  primary: string;
  secondary: string;
  lightSecondary: string;
  disabled: string;
  projectComplete: string;
  projectPaused: string;
  projectLate: string;
  secondaryHeadlines: string;
  muttedHeadlines: string;
  dropdowns: string;
  background: {
    cardsOrHeaders: string;
    avatars: string;
    pageTable: string;
    primaryLabels: string;
    secondaryLabels: string;
    primaryLabelsOrange: string;
    primaryLabelsGreen: string;
    primaryLabelsPink: string;
    pageBackground: string;
  };
  darkUI: any;
  darkBlue: string;
  tables: {
    cells: string;
    hoveredCell: string;
    columnsAndRowsTitles: string;
    borders: string;
  };
  delete: string;
}

const colorPalette = {
  white: "#FFFFFF",
  red: "#FF6464",
};

const colors: Colors = {
  white: colorPalette.white,
  error: colorPalette.red,
  slate: "#343841",
  primary: "#1F2227",
  secondary: "#027BFD",
  disabled: "#C9C9CB",
  lightSecondary: "#2099FF",
  projectLate: colorPalette.red,
  projectComplete: "#2ACA65",
  projectPaused: "#FFA664",
  secondaryHeadlines: "#343841",
  muttedHeadlines: "#77787C",
  dropdowns: "#797A7D",
  delete: "#FF6464",

  tables: {
    cells: colorPalette.white,
    hoveredCell: "#FAFAFA",
    columnsAndRowsTitles: "#F4F8FC",
    borders: "#C1C3CB",
  },

  background: {
    cardsOrHeaders: colorPalette.white,
    avatars: "#343841",
    pageTable: "#FBFBFD",
    primaryLabels: "#E1EFFF",
    secondaryLabels: "#E3E3E6",
    primaryLabelsOrange: "#FFEDE0",
    primaryLabelsGreen: "#D4F4E0",
    primaryLabelsPink: "#FFE0E0",
    pageBackground: "F7F7F9",
  },

  darkUI: {
    columnsAndRowsTitlesDarkGrey: "#474A4F",
    columnsAndRowsTitlesLightGrey: "#F5F5F5",
    columnsAndRowsTitlesLightBlack: "#292C33",
  },

  // else
  darkBlue: "#0068D7",
};

export const mappedLabelTypesToColors = {
  default: {
    color: colors.secondary,
    bg: colors.background.primaryLabels,
  },
  paused: {
    color: colors.projectPaused,
    bg: colors.background.primaryLabelsOrange,
  },
  complete: {
    color: colors.projectComplete,
    bg: colors.background.primaryLabelsGreen,
  },
  started: {
    bg: colors.background.secondaryLabels,
    color: colors.primary,
  },
  late: { color: colors.projectLate, bg: colors.background.primaryLabelsPink },
  onTime: {
    color: colors.secondary,
    bg: colors.background.primaryLabels,
  },
};

export default colors;
