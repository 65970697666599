import { createContext, useContext } from "react";
import { Ruleset } from "../../../../../store/reducers/products/parameters/pl-parameters.types";
import { Property } from "../../../../../store/reducers/products/properties/pl-properties.types";
import { TablesTypeEnum } from "../../../../../store/reducers/products/rulestables/pl-rulestables.types";

export type RulesTableContextType = {
  rulesets: Ruleset[];
  lookUpFieldIndex: number;
  properties: Property[];
  tableType: TablesTypeEnum | undefined;
  id: string;
};

export const RulesTableContext = createContext<RulesTableContextType>({
  rulesets: [],
  lookUpFieldIndex: 0,
  properties: [],
  tableType: undefined,
  id: "",
});

export const useRulesTableContext = () => useContext(RulesTableContext);
