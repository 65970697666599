import { Dropdown, H5 } from "components";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import colors from "theme/colors";
import { User } from "components/Avatar/User.component";
import { AddCircle } from "assets/logos/icons/MediumIcons";
import { Close } from "assets/logos/icons/MicroIcons";
import { useTypedSelector } from "store";

export default function AddTeamMember({
  sectionName,
  team,
  onChange,
  id,
}: {
  sectionName: string;
  team?: any;
  onChange: (el: any) => void;
  id: any;
}) {
  const [membersOptions, setMembersOptions] = useState<any>([" "]);
  // const [membersList, setMembersList] = useState<any>(team);
  const [currentUserId, setCurrentUserId] = useState<any>();
  const { users } = useTypedSelector((state) => state.users);
  const formattedUsers =
    users &&
    Object.keys(users.byId).map((id) => {
      return {
        ...users.byId[id],
        value: users.byId[id].userID,
      };
    });
  const generateDropdownOptions = () => {
    const options = formattedUsers?.map((user) => {
      return {
        ...membersOptions,
        label: user.firstName + " " + user.lastName,
        value: user.userID,
      };
    });
    setMembersOptions(options);
    options ? setCurrentUserId(options[0].value) : null;
  };

  const deleteTeamMember = (id) => {
    const findIndex = team.findIndex((el) => el === id);
    const arrFirstPart = team.slice(0, findIndex);
    const arrSecondPart = team.slice(findIndex + 1, team.length);
    const newArray = arrFirstPart.concat(arrSecondPart);
    onChange(newArray);
  };

  useEffect(() => {
    generateDropdownOptions();
  }, [users, id]);

  const displayTasksOwners = () => {
    return (
      <MembersContainer>
        {team.length > 0
          ? team.map((owner) => {
              const filteredMember = formattedUsers?.find(
                (user) => user.userID === owner
              );
              return (
                <MemberRow key={filteredMember.userID}>
                  <CustomUser
                    firstName={filteredMember.firstName}
                    lastName={filteredMember.lastName}
                  />
                  <CloseBox
                    onClick={() => deleteTeamMember(filteredMember.userID)}
                  >
                    <Close />
                  </CloseBox>
                </MemberRow>
              );
            })
          : null}
      </MembersContainer>
    );
  };

  const setTeamMembersList = () => {
    const id = currentUserId;
    const member = formattedUsers?.find((member) => member.userID === id);
    if (!team.map((member) => member).includes(id)) {
      const newArr = [...team, member.userID];
      onChange(newArr);
    }
  };
  return (
    <>
      {displayTasksOwners()}
      <CustomDropdown
        options={membersOptions}
        onChange={(owner) => setCurrentUserId(owner.target.value)}
        value={currentUserId}
      />
      <AddTeamRow onClick={setTeamMembersList}>
        <AddCircle />
        <CustomH5 bold color={colors.secondary}>
          {sectionName === "taskOwners"
            ? "add antoher owner"
            : "add task manager"}
        </CustomH5>
      </AddTeamRow>
    </>
  );
}

const MembersContainer = styled.div`
  width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const CustomUser = styled(User)`
  width: auto;
`;

const CustomDropdown = styled(Dropdown)`
  margin: 0px;
  width: 600px;
  margin-bottom: 12px;
`;

const AddTeamRow = styled.div`
  display: flex;
  cursor: pointer;
`;

const CustomH5 = styled(H5)<any>`
  margin-block-start: 0px;
  margin-block-end: 0px;
  text-transform: uppercase;
  margin-left: 4px;
  margin-bottom: 24px;
`;

const MemberRow = styled.div`
  justify-content: space-between;
  margin-bottom: 5px;
  width: 600px;
  flex-direction: row;
  display: flex;
  align-items: center;
`;

const CloseBox = styled.div`
  cursor: pointer;
  width: auto;
`;
