import React, { useEffect, useRef } from "react";
import { useIsMount } from "../../hooks/useIsMount";
import ErrorMessage from "./ErrorMessage.component";
import {
  ErrorObject,
  ObjectAssignedEnum,
} from "../../store/reducers/products/validation/validation.types";
import { useDispatch } from "react-redux";
import { getMainPropertyValidation } from "../../store/reducers/products/properties/pl-properties.actions";
import * as _ from "lodash";
import { getSubPropertyValidation } from "../../store/reducers/products/subproperties/pl-subproperties.actions";
import { useParentPropertyContext } from "../../pages/Products/pages/ProductDetail/ProductLineProperties/ParentPropertyContext";
import { getRuleTableValidation } from "../../store/reducers/products/rulestables/pl-rulestables.actions";
import { validationDispatcher } from "./ValidationDispatcher";
import { useValidationBatchCheck } from "./useValidationBatchCheck";

interface ErrorMessage {
  id: string;
  message: string | null;
}

export default function Validator({
  objectType,
  validations,
  objectData = {},
  isStandardSubProperty = false,
  isSubProperty = false,
  readOnly = false,
}: {
  objectType: ObjectAssignedEnum;
  validations: ErrorObject[];
  objectData?: any;
  isStandardSubProperty?: boolean;
  isSubProperty?: boolean;
  readOnly?: boolean;
}) {
  const dispatch = useDispatch();

  const isMount = useIsMount();

  const { parentId } = useParentPropertyContext();

  const objectDataRef = useRef(objectData);
  if (!_.isEqual(objectDataRef.current, objectData)) {
    objectDataRef.current = objectData;
  }

  const errors: ErrorMessage[] = validations.map((validation) => {
    return { id: validation.id, message: validation.message };
  });

  const validationBatchCheck = useValidationBatchCheck({ objectData });
  const validationBatchCheckRef = useRef(validationBatchCheck);
  if (!_.isEqual(validationBatchCheckRef.current, validationBatchCheck)) {
    validationBatchCheckRef.current = validationBatchCheck;
  }

  useEffect(() => {
    validationDispatcher(
      dispatch,
      objectType,
      objectData.id,
      validationBatchCheck,
      validations
    );
  }, [validationBatchCheckRef.current]);

  // fetch validation based on assigned object
  useEffect(() => {
    if (isMount) return;
    if (readOnly) return;

    if (objectType === ObjectAssignedEnum.RULETABLE) {
      setTimeout(() => {
        dispatch(
          getRuleTableValidation({
            objectId: objectData.id,
            objectAssigned: ObjectAssignedEnum.RULETABLE,
          })
        );
      }, 2000);
      return;
    }

    if (isSubProperty) {
      setTimeout(() => {
        dispatch(
          getSubPropertyValidation({
            parentId,
            objectId: objectData.id,
            objectAssigned: objectType,
          })
        );
      }, 2000);
      return;
    }

    if (objectType === ObjectAssignedEnum.PROPERTY && !isStandardSubProperty) {
      setTimeout(() => {
        dispatch(
          getMainPropertyValidation({
            objectId: objectData.id,
            objectAssigned: objectType,
          })
        );
      }, 2000);
      return;
    }

    if (isStandardSubProperty) {
      setTimeout(() => {
        dispatch(
          getSubPropertyValidation({
            parentId,
            objectId: objectData.id,
            objectAssigned: objectType,
            isStandardSubProperty,
          })
        );
      }, 2000);
      return;
    }
  }, [objectDataRef.current]);

  return <ErrorMessage errors={errors} />;
}
