import React from "react";
import ProductDetail from "./pages/ProductDetail/ProductDetail";
import ProductsList from "./pages/ProductsList/ProductsList";
import { Switch, Route, useRouteMatch } from "react-router-dom";

export default function ProductsPage() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <ProductsList />
      </Route>
      <Route path={`${path}/:productLineId`}>
        <ProductDetail />
      </Route>
    </Switch>
  );
}
