import { api } from "api/api";
import { toast } from "react-toastify";

export const SET_METADATA = "metadata/SET_METADATA";
export const SET_VERB = "metadata/SET_VERB";

export function saveMetadata(json: any) {
  return async (dispatch, getState) => {
    const state = getState();
    const selectedProductLineId = state.products.selectedProductLine.id;
    try {
      const res = await api.modifyMetadata(selectedProductLineId, json);
      if (!res) throw null;

      dispatch({
        type: SET_METADATA,
        payload: res.data,
      });
    } catch (e) {
      console.error(e);
      toast.error("Error while saving candidate selection");
      toast.error(e);
    }
  };
}

export function getMetadata() {
  return async (dispatch, getState) => {
    const state = getState();
    const selectedProductLineId = state.products.selectedProductLine.id;

    try {
      dispatch({ type: SET_VERB, payload: { getting: true } });
      let metadata: any = {};
      const res = await api.getMetadata(selectedProductLineId);

      // erroring
      if (!res || !res.data) throw null;

      // no metadata created, create one by default
      if (!res?.data || Object.keys(res.data).length === 0) {
        const newRes = await api.createMetadata(selectedProductLineId);
        metadata = newRes?.data;
      } else {
        metadata = res.data;
      }

      dispatch({
        type: SET_METADATA,
        payload: metadata,
      });
    } catch (e) {
      console.error(e);
    }
    dispatch({ type: SET_VERB, payload: { getting: false } });
  };
}
