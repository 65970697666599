import React, { useEffect } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import Layout from "containers/Layout/Layout.container";
import routes from "./routes";
import DashboardPage from "pages/Dashboard/Dashboard.page";
import NotificationsPage from "pages/Notifications/Notifications.page";
import ProgramsPage from "pages/Programs/Programs.page";
import ProgramsAddNew from "pages/ProgramsAddNew/ProgramsAddNew.page";
import ProductsPage from "pages/Products/Products.page";
import DocumentsAddNew from "pages/DocumentsAddNew/DocumentsAddNew.page";
import ComponentsLibraryPage from "pages/ComponentsLibrary/ComponentsLibrary.page";
import SettingsPage from "pages/Settings/Settings.page";
import ScrollToTop from "modules/ScrollToTop";
import { getUsersAction } from "store/reducers/users/users.actions";
import { useDispatch } from "react-redux";

const Router = () => {
  const {
    DASHBOARD,
    NOTIFICATIONS,
    PROGRAMS,
    PROGRAMS_ADD_NEW,
    PRODUCTS,
    COMPONENT_LIBRARY,
    SETTINGS,
    DOCUMENTS_ADD_NEW,
  } = routes;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsersAction());
  }, []);

  return (
    <BrowserRouter>
      <Layout>
        <ScrollToTop />
        <Switch>
          <Route path={DASHBOARD}>
            <DashboardPage />
          </Route>
          <Route path={NOTIFICATIONS}>
            <NotificationsPage />
          </Route>

          {/* Programs Pages */}
          <Route path={PROGRAMS}>
            <ProgramsPage />
          </Route>
          <Route path={PROGRAMS_ADD_NEW}>
            <ProgramsAddNew />
          </Route>

          <Route path={DOCUMENTS_ADD_NEW}>
            <DocumentsAddNew />
          </Route>

          {/* Product Pages */}
          <Route path={PRODUCTS}>
            <ProductsPage />
          </Route>
          <Route path={COMPONENT_LIBRARY}>
            <ComponentsLibraryPage />
          </Route>
          <Route path={SETTINGS}>
            <SettingsPage />
          </Route>

          <Redirect to={routes.PRODUCTS} />
        </Switch>
      </Layout>
    </BrowserRouter>
  );
};

export default Router;
