import React from "react";
import styled from "styled-components";
import { menuItems } from "./menuItems";
import MenuItem from "./MenuItem";
import FooterNote from "./FooterNote";
import { CarrierLogoLarge, CarrierLogoSmall } from "assets/logos/Carrier.logo";
import { DesktopOnly, MobileOnly } from "components";
import { useTypedSelector } from "store";

export default function SideMenu() {
  const { navigationOpen } = useTypedSelector(
    (state) => state.user.preferences
  );

  return (
    <Container hide={!navigationOpen}>
      <LogoContainer>
        <DesktopOnly hide={!navigationOpen}>
          <CarrierLogoLarge />
        </DesktopOnly>
        <MobileOnly show={!navigationOpen}>
          <CarrierLogoSmall />
        </MobileOnly>
      </LogoContainer>

      <Box>
        <ItemsContainer>
          {menuItems.map((item) => (
            <MenuItem
              hide={!navigationOpen}
              key={item.label}
              item={item}
              disabled={item.disabled}
            />
          ))}
        </ItemsContainer>
        <FooterNote />
      </Box>
    </Container>
  );
}

const LogoContainer = styled.div`
  width: 50%;
  margin: auto;
  height: 14%;
  text-align: center;
  box-sizing: border-box;
  padding-top: 32px;
`;

const Container = styled.aside<any>`
  position: fixed;
  top: 0;
  width: 56px;
  @media ${(props) => props.theme.device.mobile} {
    width: ${(props) => (props.hide ? "56px" : "210px")};
  }

  transition: width 0.5s;

  height: 100%;
  background-color: ${(props) => props.theme.colors.secondary};

  z-index: 999;
`;

const ItemsContainer = styled.div`
  padding-top: 24px;
  display: flex;
  flex-direction: column;
`;

const Box = styled.div`
  height: 86%;
  display: flex;
  flex-direction: column;
`;
