import { Input } from "./Input.component";
import React from "react";
import styled from "styled-components";
import { SearchIcon } from "assets/logos/icons/MediumIcons";
import colors from "theme/colors";
import { Close } from "assets/logos/icons/MicroIcons";

export function SearchInput({
  placeholder,
  onChange,
  className,
  autoFocus,
  value,
}: any) {
  return (
    <Wrapper>
      <StyledSearchIcon color={colors.muttedHeadlines} />
      <StyledInput
        placeholder={placeholder}
        type="text"
        onChange={onChange}
        className={className}
        autoFocus={autoFocus}
        value={value}
      />
      {value && (
        <CloseWrapper onClick={() => onChange({ target: { value: "" } })}>
          <Close color={colors.muttedHeadlines} />
        </CloseWrapper>
      )}
    </Wrapper>
  );
}

const CloseWrapper = styled.div<any>`
  width: 28px;
  position: absolute;
  cursor: pointer;
  right: 0;
  top: 26px;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`;

const StyledInput = styled(Input)`
  padding-left: 48px;
  width: 100%;
`;

const StyledSearchIcon = styled(SearchIcon)`
  position: absolute;
  top: 22px;
  left: 11px;
`;
