import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { Button, LoaderSpinner2 } from "components";
import { api } from "api/api";
import styled from "styled-components";

enum ImportStates {
  IDLE,
  PENDING,
  SUCCESS,
  ERROR,
}

export default function ImportProductLineRulesEngineJSONButton({
  handleRefreshProductlines,
}) {
  const fileInputRef = useRef(null);
  const [importStatus, setImportStatus] = useState(ImportStates.IDLE);

  const handleClick = () => {
    //Open the file input dialog
    if (fileInputRef.current) fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    //Get a reference to the file object from the input component
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }

    //reset file input
    event.target.value = null;

    //Import the file
    handleImport(fileObj);
  };

  const handleImport = async (fileObj) => {
    setImportStatus(ImportStates.PENDING);

    try {
      //Create a form data object containing the file
      const formData = new FormData();
      formData.append("file", fileObj);

      //Call the api to export the table
      await api.importProductLineRuleEngineJson(formData);

      setImportStatus(ImportStates.SUCCESS);
    } catch (e) {
      console.error("Error importing product line:", e);
    }

    //Trigger the callback to refresh the product lines
    handleRefreshProductlines();
  };

  return (
    <Wrapper>
      <StyledButton size="small" type="secondary" onClick={handleClick}>
        Import Product Line from Rules Engine JSON
      </StyledButton>
      <input
        type="file"
        accept=".json"
        name="importfile"
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={handleFileChange}
      />
      {importStatus === ImportStates.PENDING && <LoaderSpinner2 />}
    </Wrapper>
  );
}

ImportProductLineRulesEngineJSONButton.propTypes = {
  handleRefreshProductlines: PropTypes.func.isRequired,
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledButton = styled(Button)`
  width: 200px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
`;
