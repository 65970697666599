import { PropertiesState } from "./pl-properties.types";
import {
  SET_PL_PROPERTIES,
  SET_VERB,
  SET_NEW_PROPERTY,
  SET_PL_PROPERTY,
  EDIT_PL_PROPERTY,
  REMOVE_PL_PROPERTY,
  SET_PROPERTIES_INITIAL_STATE,
  GET_MAIN_PROPERTY_VALIDATION_BY_OBJECT_ID,
  SET_PL_PROPERTY_PAGINATION,
} from "./pl-properties.actions";

const newPropertyInitialState = {
  name: "",
  domain: "",
  dataType: "",
  rulesetids: [],
};

export const plPropertiesInitialState: PropertiesState = {
  byId: {},
  allIds: [],
  newProperty: newPropertyInitialState,
  total: 0,
  page: 0,

  getting: false,
  posting: false,
  deleting: false,
};

export function plPropertiesReducer(
  propertiesState: PropertiesState = plPropertiesInitialState,
  action: any
) {
  switch (action.type) {
    case SET_PL_PROPERTIES: {
      if (action.payload.page)
        return {
          ...propertiesState,
          byId: {
            ...propertiesState.byId,
            ...action.payload.byId,
          },
          allIds: [...propertiesState.allIds, ...action.payload.allIds],
          total: action.payload.total,
        };
      return {
        ...propertiesState,
        newProperty: newPropertyInitialState,
        ...action.payload,
      };
    }

    case SET_VERB: {
      return {
        ...propertiesState,
        ...action.payload,
      };
    }

    case SET_NEW_PROPERTY: {
      return {
        ...propertiesState,
        newProperty: action.payload,
      };
    }

    case SET_PL_PROPERTY: {
      return {
        ...propertiesState,
        byId: {
          ...propertiesState.byId,
          [action.payload.propertyId]: action.payload.data,
        },
      };
    }

    case EDIT_PL_PROPERTY: {
      return {
        ...propertiesState,
        byId: action.payload,
      };
    }

    case REMOVE_PL_PROPERTY: {
      return {
        ...propertiesState,
        allIds: [...action.payload.newAllIds],
        byId: action.payload.newById,
      };
    }

    case GET_MAIN_PROPERTY_VALIDATION_BY_OBJECT_ID:
      return {
        ...propertiesState,
        byId: {
          ...propertiesState.byId,
          [action.payload.objectId]: {
            ...propertiesState.byId[action.payload.objectId],
            validations: action.payload.validation,
          },
        },
      };

    case SET_PL_PROPERTY_PAGINATION: {
      return {
        ...propertiesState,
        page: propertiesState.page + 1,
      };
    }

    case SET_PROPERTIES_INITIAL_STATE: {
      return plPropertiesInitialState;
    }

    default:
      return propertiesState;
  }
}
