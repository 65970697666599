import { ParametersState, Ruleset, Tag } from "./pl-parameters.types";
import {
  SET_PVA,
  SET_RULESETS,
  SET_TAGS,
  SET_TAGS_COUNT,
  ADD_NEW_PVA,
  REMOVE_PVA,
  SET_NEW_PVA_NAME,
  SET_NEW_PVA_TYPE,
  SET_NEW_RULESET,
  ADD_RULESET,
  SET_NEW_TAG,
} from "./parameters.actions";
import { SET_DEFAULT_RULESET, SET_VERB } from "../products.actions";

const newPropertyValueAttribute: any = {
  name: "",
  type: "",
};

const newProductLineRuleset: Ruleset = {
  name: "",
  description: "",
};

export const newProductLineTag: Tag = {
  name: "",
  description: "",
  category: "",
};

export const plParametersInitialState: ParametersState = {
  propertyValueAttributes: [],
  ruleSets: [],
  tags: [],

  newPropertyValueAttribute: newPropertyValueAttribute,
  newRuleset: newProductLineRuleset,
  newTag: newProductLineTag,

  getting: false,
  posting: false,
  deleting: false,
};

export function plParametersReducer(
  parametersState: ParametersState,
  action: any
) {
  switch (action.type) {
    case SET_DEFAULT_RULESET: {
      return {
        ...parametersState,
      };
    }

    case SET_TAGS: {
      return {
        ...parametersState,
        tags: action.payload,
        newTag: newProductLineTag,
      };
    }

    // pva

    case SET_PVA: {
      return {
        ...parametersState,
        propertyValueAttributes: action.payload,
      };
    }

    case SET_NEW_PVA_NAME: {
      return {
        ...parametersState,
        newPropertyValueAttribute: {
          ...parametersState.newPropertyValueAttribute,
          name: action.payload,
        },
      };
    }

    case SET_NEW_PVA_TYPE: {
      return {
        ...parametersState,
        newPropertyValueAttribute: {
          ...parametersState.newPropertyValueAttribute,
          type: action.payload,
        },
      };
    }

    case ADD_NEW_PVA: {
      return {
        ...parametersState,
        propertyValueAttributes: action.payload,
        newPropertyValueAttribute: { ...newPropertyValueAttribute },
      };
    }

    case REMOVE_PVA: {
      return {
        ...parametersState,
        propertyValueAttributes: action.payload,
      };
    }

    // ruleSets

    case SET_RULESETS: {
      return {
        ...parametersState,
        ruleSets: action.payload,
      };
    }

    case SET_NEW_RULESET: {
      return {
        ...parametersState,
        newRuleset: action.payload,
      };
    }

    case ADD_RULESET: {
      return {
        ...parametersState,
        ruleSets: action.payload,
        newRuleset: newProductLineRuleset,
      };
    }

    // tage

    case SET_NEW_TAG: {
      return {
        ...parametersState,
        newTag: action.payload,
      };
    }

    case SET_TAGS_COUNT: {
      return {
        ...parametersState,
        tags: action.payload,
      };
    }

    case SET_VERB: {
      return {
        ...parametersState,
        ...action.payload,
      };
    }
    default:
      return parametersState;
  }
}
