import React from "react";
import styled from "styled-components";
import colors from "../../../../../theme/colors";

export default function Label({
  name = "",
  count = 0,
  currentTab = "",
  setCurrentTab,
}: {
  name?: string;
  count?: any;
  setCurrentTab(arg: string): void;
  currentTab?: string;
}) {
  return (
    <LabelBox
      name={name}
      currentTab={currentTab}
      onClick={() => setCurrentTab(name)}
    >
      <LabelName currentTab={currentTab} name={name}>
        {name}
      </LabelName>
      <CountBox>
        <Count>{count}</Count>
      </CountBox>
    </LabelBox>
  );
}

const LabelBox = styled.div<any>`
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 58px;
  border-bottom: ${(props) =>
    props.currentTab === props.name ? "4px solid" : "none"};
  border-color: ${colors.secondary};
  cursor: pointer;
  box-sizing: border-box;
`;

const LabelName = styled.p<any>`
  font-size: 16px;
  line-height: 24.5px;
  font-family: Roboto;
  color: ${colors.secondary};
  font-weight: ${(props) =>
    props.currentTab === props.name ? "bold" : "normal"};
`;

const CountBox = styled.div`
  height: 24px;
  padding: 4px 8px 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.background.primaryLabels};
  border-radius: 4px;
  margin-left: 8px;
`;

const Count = styled.p`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 700;
  color: ${colors.secondary};
`;
