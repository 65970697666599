import React, { useState } from "react";
import { productRegionOptions } from "store/reducers/products/products.types";
import styled from "styled-components";
import { Button, Input, Dropdown } from "components";
import { useDispatch } from "react-redux";
import {
  setNewProductAction,
  addNewProductAction,
  setAddProductPanelOpen,
} from "store/reducers/products/products.actions";
import { useTypedSelector } from "store";
import { validateNewProduct } from "store/reducers/products/products.validation";
import ImageCrop from "containers/ImageCrop/ImageCrop.container";

export function AddProductForm() {
  const dispatch = useDispatch();

  const { newProduct, posting, showAddProductLineSidePanel } = useTypedSelector(
    (state) => state.products
  );
  const [hasError, setHasError] = useState(false);

  // TODO:
  // if we need to store the img in the redux store one day (so it doesn't disappear when the menu closes)
  // don't store the blob directly, but use createObjectURL() and store the URL
  const [img, setImg] = useState<any>();

  // validation
  const getValidation = async () => {
    const validation = await validateNewProduct({
      ...newProduct,
      displayImage: img,
    });

    if (validation === true) {
      setHasError(false);
      return true;
    } else {
      setHasError(true);
      return false;
    }
  };

  // handlers
  const handleRegionChange = (e) => {
    dispatch(setNewProductAction({ ...newProduct, region: e.target.value }));
  };
  const handleNameChange = (e) => {
    dispatch(setNewProductAction({ ...newProduct, name: e.target.value }));
  };

  const handleSubmit = async () => {
    const isValid = await getValidation();
    if (!isValid) return;
    const { name, region } = newProduct;

    // TODO: same than before: validation needs to be integrated to the ProductDto type
    // because this if() is useless
    if (!name || !region) return;

    dispatch(
      addNewProductAction({
        name,
        region,
        img,
      })
    );
    dispatch(setAddProductPanelOpen(false));
  };

  const handleBlob = (blob) => {
    setImg(blob);
  };

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <StyledInputWrapper open={showAddProductLineSidePanel}>
        <StyledInput
          label="name"
          size="short"
          value={newProduct.name}
          onChange={handleNameChange}
        />
        <StyledDropdown
          placeholder="Region"
          name="Region"
          options={productRegionOptions}
          size="small"
          onChange={handleRegionChange}
          value={newProduct.region}
        />
        <ImageCrop handleBlob={handleBlob} />
        <StyledAddProductLineButton
          onClick={handleSubmit}
          buttonType="submit"
          loading={posting}
        >
          Add Product Line
        </StyledAddProductLineButton>
        {hasError && (
          <ErrorMessage>
            Error: please complete the name (max 30 characters) and select a
            region.
          </ErrorMessage>
        )}
      </StyledInputWrapper>
    </form>
  );
}

const StyledInputWrapper = styled.div<any>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;

  margin-top: 30px;
  padding-left: 32px;
  padding-right: 45px;
`;

const StyledAddProductLineButton = styled(Button)`
  margin-top: 30px;

  /* for smooth transition */
  white-space: nowrap;
`;

const StyledInput = styled(Input)`
  margin-bottom: 30px;
`;

const StyledDropdown = styled(Dropdown)`
  width: 100%;
  box-sizing: border-box;
`;

const ErrorMessage = styled.span`
  color: ${(props) => props.theme.colors.error};
  margin-top: 8px;
`;
