import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  getRulesTablesAction,
  setRulesTablesInitialState,
  RULES_TABLES_ITEMS_PER_PAGE,
} from "store/reducers/products/rulestables/pl-rulestables.actions";
import { useDispatch } from "react-redux";
import { useParams, useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import { useTypedSelector } from "store";
import { tablesTypes } from "store/reducers/products/rulestables/pl-rulestables.types";
import { DropdownWithLabel } from "components";
import PropertySearchDropdown from "./RulesTable/PropertySearchDropDown.component";
import {
  getSelectedValueFromOptions,
  stringArrayToOptions,
} from "../../../components/Forms/SelectDropdown/SelectDropdown.component";
import { selectRulesTablesPagination } from "../../../store/reducers/products/rulestables/pl-ruletables.selectors";
import { FormGroup, FormControlLabel, Switch } from "@mui/material";

export default function PLFiltersRulesTables() {
  const queryParams = queryString.parse(useLocation<any>().search);
  const history = useHistory();

  const { productLineId } = useParams<{ productLineId: string }>();
  const [includePVAProperties, setIncludePVAProperties] = useState<boolean>(
    () => {
      // Check localStorage for the saved value, default to 'true' if not found
      const saved = localStorage.getItem("tables.filter.includePVAProperties");
      return saved === null ? true : saved === "true";
    }
  );
  const [includeSubProperties, setIncludeSubProperties] = useState<boolean>(
    () => {
      // Check localStorage for the saved value, default to 'true' if not found
      const saved = localStorage.getItem("tables.filter.includeSubProperties");
      return saved === null ? true : saved === "true";
    }
  );

  useEffect(() => {
    //persist change to localstorage
    localStorage.setItem(
      "tables.filter.includePVAProperties",
      String(includePVAProperties)
    );
  }, [includePVAProperties]);

  useEffect(() => {
    //persist change to localstorage
    localStorage.setItem(
      "tables.filter.includeSubProperties",
      String(includeSubProperties)
    );
  }, [includeSubProperties]);

  const toggleIncludePVAProperties = () => {
    setIncludePVAProperties((prev) => !prev);
  };

  const toggleIncludeSubProperties = () => {
    setIncludeSubProperties((prev) => !prev);
  };

  const ruleSets =
    useTypedSelector((state) => state.products?.parameters.ruleSets) || [];
  const { page } = useTypedSelector((state) =>
    selectRulesTablesPagination(state)
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getRulesTablesAction({
        productLineId: productLineId,
        tableTypes: queryParams.tableTypes,
        propertyIds: queryParams.propertyIds,
        rulesetIds: queryParams.rulesetIds,
        items: RULES_TABLES_ITEMS_PER_PAGE,
        page,
      })
    );
  }, [
    queryParams.tableTypes && queryParams.tableTypes.length,
    queryParams.propertyIds && queryParams.propertyIds.length,
    queryParams.rulesetIds && queryParams.rulesetIds.length,
    page,
  ]);

  const formattedTypesOptions = tablesTypes.map((type: any) => type);

  const formattedRulesets = ruleSets.map((ruleset) => ({
    value: ruleset.id,
    label: ruleset.name,
  }));

  const handleChange = (e, category) => {
    dispatch(setRulesTablesInitialState());

    const newFilters = { ...queryParams, [category]: e };
    const newParamsString = queryString.stringify(newFilters);
    history.push({
      search: `?${newParamsString}`,
    });
  };

  const getValue = (queryParams, options) => {
    return options.filter(({ value }) => {
      const valuesArray = Array.isArray(queryParams)
        ? queryParams
        : [queryParams] || [];
      return valuesArray.includes(value);
    });
  };

  return (
    <Filters>
      <div>
        <FormGroup row>
          <FormControlLabel
            label="Subprops"
            control={
              <Switch
                size="small"
                checked={includeSubProperties}
                onChange={toggleIncludeSubProperties}
              />
            }
          />
          <FormControlLabel
            label="PVAs"
            control={
              <Switch
                size="small"
                checked={includePVAProperties}
                onChange={toggleIncludePVAProperties}
              />
            }
          />
        </FormGroup>
        <StyledPropertySearchDropdown
          placeholder={"Select properties"}
          name="propertyIds"
          multiselect
          onChange={(e) => handleChange(e, "propertyIds")}
          label="Property Dependencies"
          includePVAProperties={includePVAProperties}
          includeSubProperties={includeSubProperties}
        />
      </div>
      <DropdownWithLabel
        placeholder="Any"
        name="tableTypes"
        options={stringArrayToOptions(formattedTypesOptions)}
        onChange={(e) =>
          handleChange(getSelectedValueFromOptions(true, e), "tableTypes")
        }
        label="Table Types"
        multiple
        value={getValue(
          queryParams.tableTypes,
          stringArrayToOptions(formattedTypesOptions)
        )}
      />
      <DropdownWithLabel
        placeholder="Any"
        name="rulesetsIds"
        options={formattedRulesets}
        onChange={(e) =>
          handleChange(getSelectedValueFromOptions(true, e), "rulesetIds")
        }
        label="Rulesets"
        multiple
        value={getValue(queryParams.rulesetIds, formattedRulesets)}
      />
    </Filters>
  );
}

const Filters = styled.div`
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 16px;
  background-color: ${(props) => props.theme.colors.white};
`;

const StyledPropertySearchDropdown = styled(PropertySearchDropdown)<any>``;
