export const findLabelByMatchingUrl = (pathname: string, menuItems): string => {
  const key = Object.keys(menuItems).find((key) => {
    const { page } = menuItems[key];

    if (pathname.includes(page)) return true;
    return false;
  });

  // handle edge case for no match
  if (key === undefined) return "";

  // handle dashboard specific case
  if (pathname.includes("dashboard")) return "";

  return menuItems[key].label;
};
