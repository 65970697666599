export function selectPropertiesPagination(state) {
  const { total, page } = state.products.properties;
  return { total, page };
}

export function morePropertiesToLoad(state) {
  const { total } = selectPropertiesPagination(state);
  const propertiesCount = state.products.properties.allIds.length;
  return total > propertiesCount;
}
