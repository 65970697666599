import React from "react";
import styled from "styled-components";
import { LogoutIcon } from "assets/logos/icons/NavigationIcons";
import { ResponsiveUser } from "containers/ResponsiveUser/ResponsiveUser.container";
import { DesktopOnly } from "components";
import { useDispatch } from "react-redux";
import { setUserPreferences } from "store/reducers/user/user.actions";
import { useTypedSelector } from "store";
import { useHistory } from "react-router-dom";

export default function FooterNote() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { preferences } = useTypedSelector((state) => state.user);
  const toggleNavigation = () =>
    dispatch(
      setUserPreferences({ navigationOpen: !preferences.navigationOpen })
    );
  return (
    <Wrapper>
      <DesktopOnly style={{ width: "100%" }}>
        <CloseNavLine onClick={toggleNavigation}>
          <div
            style={{
              transform: `scaleX(${preferences.navigationOpen ? "1" : "-1"})`,
              display: "flex",
              alignItems: "center",
            }}
          >
            <LogoutIcon />
          </div>
          <DesktopOnly hide={!preferences.navigationOpen}>
            <span style={{ marginLeft: 8 }}>Close Navigation</span>
          </DesktopOnly>
        </CloseNavLine>
      </DesktopOnly>
      <UserLine onClick={() => history.push("/settings")}>
        <ResponsiveUser />
      </UserLine>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Line = styled.div`
  font-size: 12px;
  width: 100%;

  text-decoration: none;
  text-align: center;

  color: white !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  :hover {
    opacity: 0.7;
  }
`;

const UserLine = styled(Line)`
  min-height: 90px;
  display: flex;
  align-items: center;
`;

const CloseNavLine = styled(Line)`
  min-height: 54px;
  box-sizing: border-box;
  border-top: 1px solid rgb(255, 255, 255, 0.2);
  border-bottom: 1px solid rgb(255, 255, 255, 0.2);
  padding: 16px 0px 16px 0px;
  font-weight: bold;
`;
