import React, { useEffect, useState } from "react";
import { ContextMenuComponent, TypeConstrainedCell } from "components";
import colors from "theme/colors";
import styled from "styled-components";
import { FunctionPredicateInput } from "./FunctionPredicateInput.component";
import { useDispatch } from "react-redux";
import { editRuleTableCellAction } from "store/reducers/products/rulestables/pl-rulestables.actions";
import {
  DataTypeEnum,
  DomainEnum,
} from "../../../../store/reducers/products/properties/pl-properties.types";
import SelectDropdownComponent, {
  getSelectedValueFromOptions,
} from "../../../../components/Forms/SelectDropdown/SelectDropdown.component";

export enum InputOptionEnum {
  TYPE_CONSTRAINED_INPUT = "open input",
  VALUE_DROPDOWN = "discrete value",
  FUNCTION = "function",
  PREDICATE = "predicate",
}

export interface IDropDownValue {
  value: string;
  label: string;
  description: string;
  isInTableRulesets: boolean;
}

const determineInputType = (
  domain: DomainEnum,
  currentValue: Array<IDropDownValue>
) => {
  if (currentValue[0]?.value?.startsWith("PRD:"))
    return InputOptionEnum.PREDICATE;
  if (currentValue[0]?.value?.startsWith("FN:"))
    return InputOptionEnum.FUNCTION;

  if (domain === DomainEnum.DISCRETE) return InputOptionEnum.VALUE_DROPDOWN;
  if (domain === DomainEnum.OPEN || domain === DomainEnum.RANGE)
    return InputOptionEnum.TYPE_CONSTRAINED_INPUT;
  return InputOptionEnum.TYPE_CONSTRAINED_INPUT;
};

// do not refactor this, I think it looks simpler this way even if it looks duplicate

const contextMenuOptionsDiscrete = [
  InputOptionEnum.VALUE_DROPDOWN,
  InputOptionEnum.FUNCTION,
  InputOptionEnum.PREDICATE,
];

const contextMenuOptionsOpen = [
  InputOptionEnum.TYPE_CONSTRAINED_INPUT,
  InputOptionEnum.FUNCTION,
  InputOptionEnum.PREDICATE,
];

const contextMenuOptionsRange = [
  InputOptionEnum.TYPE_CONSTRAINED_INPUT,
  InputOptionEnum.FUNCTION,
  InputOptionEnum.PREDICATE,
];

const getContextMenuOptions = (domain, isLookupItem) => {
  let options;
  if (domain === DomainEnum.DISCRETE) options = contextMenuOptionsDiscrete;
  if (domain === DomainEnum.OPEN) options = contextMenuOptionsOpen;
  if (domain === DomainEnum.RANGE) options = contextMenuOptionsRange;
  if (!isLookupItem)
    //Not allowed to have functions in non-LOOKUP cells
    options = options.filter((option) => option !== InputOptionEnum.FUNCTION);
  if (isLookupItem)
    //Not allowed to have Predicates in LOOKUP cells
    options = options.filter((option) => option !== InputOptionEnum.PREDICATE);
  return options;
};

export function RulesTableCell({
  options,
  originalValue,
  rowId,
  cellId,
  ruleTableId,
  domain,
  dataType,
  isLookupItem = false,
}: {
  options?: Array<IDropDownValue>;
  originalValue: Array<IDropDownValue>;
  rowId: string;
  cellId: string;
  ruleTableId: string;
  domain: DomainEnum;
  dataType: DataTypeEnum;
  isLookupItem: boolean;
}) {
  const dispatch = useDispatch();

  //Type of cell input
  const [inputType, setInputType] = useState<InputOptionEnum>(() =>
    determineInputType(domain, originalValue)
  );

  //Values currently selected in the dropdown
  const [selectedDropDownValues, setSelectedDropDownValues] = useState<
    Array<IDropDownValue>
  >(originalValue);

  //String value for a FN:, PRD:, or Open Input Cell, is the first entry in the original input values
  const [selectedTextValue, setSelectedTextValue] = useState<string>(() => {
    return originalValue && originalValue[0] && originalValue[0].value
      ? originalValue[0].value
      : "";
  });

  //The context menu option array
  const [contextMenuOptions, setContextMenuOptions] = useState<
    Array<InputOptionEnum>
  >([]);

  //This will be a multi-select dropdown unless it is a looked up field where it
  //can only have one value.
  //The OR clause is to handle situations where the cell WAS multiselect
  //(i.e. not a looked up field) but now has become a looked up field
  //for instance an INCLUDE table was changed to a LOOKUP table and the first
  //column is now a LOOKUP column but it has multiple values.
  //This will allow the UI to stay as a multiple select until the user gets it down
  //to a single value and then it will change to single select.
  const multiselect = !isLookupItem || selectedDropDownValues?.length > 1;

  //Updates the context menu choices based on the type of property and
  //selected input type for cell (i.e. FN, PRD, discrete, or open)
  useEffect(() => {
    const menuOptionsWithoutSelected = getContextMenuOptions(
      domain,
      isLookupItem
    ).filter((option) => option !== inputType);
    setContextMenuOptions(menuOptionsWithoutSelected);
  }, [inputType, isLookupItem]);

  //Send the result of the text cell change to back end
  const handleTextCellSubmit = (newTextValue?): void => {
    setSelectedTextValue(newTextValue);
    dispatch(
      editRuleTableCellAction({
        ruleTableId,
        rowId,
        cellId,
        value: [newTextValue],
      })
    );
  };

  //Send the result of the drop down cell change to the back end
  const handleDropDownChange = (selectedOption: Array<IDropDownValue>) => {
    let newValues = [];
    if (selectedOption) {
      const newValue = getSelectedValueFromOptions(multiselect, selectedOption);
      newValues = multiselect ? newValue : [newValue];
    }
    setSelectedDropDownValues(selectedOption);
    dispatch(
      editRuleTableCellAction({
        ruleTableId,
        rowId,
        cellId,
        value: newValues,
      })
    );
  };

  const handleInputTypeChange = (inputType) => {
    setInputType(inputType);

    //Clear the stored values for the cell
    setSelectedDropDownValues([]);
    setSelectedTextValue("");

    //Commit the new value to the database
    dispatch(
      editRuleTableCellAction({
        ruleTableId,
        rowId,
        cellId,
        value: [],
      })
    );
  };

  return (
    <>
      {inputType === InputOptionEnum.VALUE_DROPDOWN && (
        <SelectDropdownComponent
          backgroundColor={colors.tables.hoveredCell}
          closeMenuOnSelect={multiselect ? false : true}
          height="30px"
          isClearable={multiselect ? false : true} //TODO MRU 2023-02-21:  Make Lookup single select clearable
          isSearchable={true}
          handleChange={handleDropDownChange}
          hideControls={false}
          id={cellId + "customDropdownUUID"}
          isMulti={multiselect}
          options={options || []}
          name={"display property"}
          noBorder
          noHoverBorder
          placeholder={multiselect ? "All Values" : ""}
          value={selectedDropDownValues}
        />
      )}

      {inputType === InputOptionEnum.TYPE_CONSTRAINED_INPUT && (
        <StyledTypeConstrainedCell
          dataType={dataType}
          type="editCell"
          handleSubmit={handleTextCellSubmit}
          value={selectedTextValue}
        />
      )}

      {(inputType === InputOptionEnum.FUNCTION ||
        inputType === InputOptionEnum.PREDICATE) && (
        <FunctionPredicateInput
          domain={domain}
          option={inputType}
          value={selectedTextValue}
          id={rowId + cellId + "funPredInput"}
          handleCellSubmit={handleTextCellSubmit}
        />
      )}

      <ContextMenuComponent
        uniqueId={rowId + cellId}
        changeAction={handleInputTypeChange}
        options={contextMenuOptions}
      />
    </>
  );
}

const StyledTypeConstrainedCell = styled(TypeConstrainedCell)<any>`
  width: 100%;
  height: 100%;
`;
