import React from "react";
import routes from "modules/router/routes";
import {
  //DashboardIcon,
  //NotificationsIcon,
  //ProgramsIcon,
  //ComponentLibraryIcon,
  SettingsIcon,
  ProductsIcon,
} from "assets/logos/icons/NavigationIcons";

interface MenuItem {
  label: string;
  page: string;
  icon: any;
  disabled?: boolean;
}

const {
  //DASHBOARD,
  //NOTIFICATIONS,
  //PROGRAMS,
  PRODUCTS,
  //COMPONENT_LIBRARY,
  SETTINGS,
} = routes;

export const menuItems: Array<MenuItem> = [
  // {
  //   label: "Dashboard",
  //   page: DASHBOARD,
  //   icon: <DashboardIcon />,
  // },
  // {
  //   label: "Notifications",
  //   page: NOTIFICATIONS,
  //   icon: <NotificationsIcon />,
  //   disabled: true,
  // },
  // {
  //   label: "Programs",
  //   page: PROGRAMS,
  //   icon: <ProgramsIcon />,
  // },
  {
    label: "Products",
    page: PRODUCTS,
    icon: <ProductsIcon />,
  },
  // {
  //   label: "Components Library",
  //   page: COMPONENT_LIBRARY,
  //   icon: <ComponentLibraryIcon />,
  //   disabled: true,
  // },
  {
    label: "Settings",
    page: SETTINGS,
    icon: <SettingsIcon />,
  },
];
