import React from "react";
import styled from "styled-components";
import { ArrowDown } from "assets/logos/icons/MicroIcons";
import colors from "theme/colors";

interface Option {
  label: string;
  value: string;
}

export const Dropdown = ({
  options = [],
  disabled = false,
  icon = null,
  className,
  placeholder,
  onChange,
  name,
  value,
  border,
  size = "normal",
  tabIndex,
}: {
  options: Array<Option>;
  disabled?: boolean;
  icon?: any;
  width?: string;
  className?: any;
  placeholder?: string;
  onChange?: any;
  name?: string;
  value?: string;
  size?: "small" | "normal";
  border?: string;
  tabIndex?: number;
}) => {
  return (
    <Wrapper className={className} size={size} tabIndex={tabIndex}>
      <StyledSelect
        disabled={disabled}
        onChange={onChange}
        name={name}
        value={value || ""}
        size={size}
        border={border}
      >
        {placeholder && (
          <option value="" disabled>
            {placeholder}
          </option>
        )}
        {options.map(({ value, label }) => {
          return (
            <option key={value} value={value}>
              {label}
            </option>
          );
        })}
      </StyledSelect>
      {icon ? (
        <IconWrapper disabled={disabled}>{icon}</IconWrapper>
      ) : (
        <StyledDownArrow color={colors.muttedHeadlines} size={size} />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div<any>`
  position: relative;
  /* just style the component to override this */
  width: 464px;
  height: ${(props) => (props.size === "small" ? "48px" : "72px")};
`;

const StyledSelect = styled.select<any>`
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  appearance: none;
  height: ${(props) => (props.size === "small" ? "48px" : "72px")};
  border: ${(props) => (props.border ? props.border : "1px")} solid
    ${(props) => props.theme.colors.tables.borders};
  border-radius: 4px;
  padding: ${(props) => (props.size === "small" ? "12px 24px" : "24px")};
  box-sizing: border-box;
  font-size: ${(props) => (props.size === "small" ? "14px" : "16px")};
  color: ${(props) => props.theme.colors.primary};
  width: 100%;

  :focus {
    outline: none; /* Remove default outline and use border or box-shadow */
  }

  :disabled {
    background-color: ${(props) => props.theme.colors.tables.borders};
    cursor: not-allowed;
  }
`;

const IconWrapper = styled.div<any>`
  position: absolute;
  top: 24px;
  right: 17px;
  pointer-events: none;

  path {
    stroke: ${(props) =>
      props.disabled
        ? props.theme.colors.tables.borders
        : props.theme.colors.muttedHeadlines};
  }
`;

const StyledDownArrow = styled<any>(ArrowDown)`
  position: absolute;
  top: ${(props) => (props.size === "small" ? "20px" : "31px")};
  right: 0;
  pointer-events: none;
`;
