import { H4, H6, Button } from "components";
import React from "react";
import styled from "styled-components";
import colors from "theme/colors";

export default function ImpactedBox({
  impactedElement,
}: {
  impactedElement?: any;
}) {
  return (
    <Box>
      <CustomH4>{impactedElement.name}</CustomH4>
      <CustomH6>Type: {impactedElement.type}</CustomH6>
      <CustomH6>
        Capacity Range (Tons): {impactedElement.capacityRange}
      </CustomH6>
      <CustomH6>Effciency (EER/IEER): {impactedElement.effciency}</CustomH6>
      <CustomButton type={"secondary"} color={"primary"}>
        View Model
      </CustomButton>
    </Box>
  );
}

const Box = styled.div`
  width: 225px;
  height: 208px;
  display: flex;
  justify-content: center;
  padding: 18px;
  border: 1px solid ${colors.tables.borders};
  flex-direction: column;
  border-radius: 4px;
  background-color: ${colors.white};
  margin-bottom: 24px;
  box-sizing: border-box;
`;

const CustomH4 = styled(H4)`
  margin-bottom: 8px;
  margin-block-start: 0px;
  line-height: 28px;
`;

const CustomH6 = styled(H6)`
  color: ${colors.muttedHeadlines};
  margin-block-start: 0px;
  margin-block-end: 0px;
  line-height: q6px;
`;

const CustomButton = styled(Button)`
  height: 32px;
  margin-top: 30px;
  padding: 0px;
  width: 100px;
`;
