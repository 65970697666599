import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { JsonEditor as Editor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";
import { H3, Loader1, Button, H4 } from "components";
import colors from "theme/colors";
import { useTypedSelector } from "store";
import { selectProductLineId } from "store/reducers/products/products.selectors";
import { useDispatch } from "react-redux";
import {
  selectMetadataGetting,
  selectMetadataJsonData,
} from "store/reducers/products/metadata/metadata.selectors";
import {
  getMetadata,
  saveMetadata,
  SET_VERB,
} from "store/reducers/products/metadata/metadata.actions";
import { metadataJsonExample } from "store/reducers/products/metadata/metadata.fixtures";

export default function ProductMetadataPage() {
  const [mode, setMode] = useState("tree");

  const dispatch = useDispatch();
  const selectedProductLineId = useTypedSelector(selectProductLineId);
  const metadata = useTypedSelector(selectMetadataJsonData);
  const getting = useTypedSelector(selectMetadataGetting);

  useEffect(() => {
    if (!selectedProductLineId) return;
    dispatch(getMetadata());
  }, [selectedProductLineId]);

  const saveJson = (data) => {
    dispatch(saveMetadata(data));
  };

  const changeMode = () => {
    setMode(mode === "code" ? "tree" : "code");
    dispatch({ type: SET_VERB, payload: { getting: true } });
    setTimeout(() => {
      dispatch({ type: SET_VERB, payload: { getting: false } });
    }, 1000);
  };

  return (
    <Wrapper>
      <StyledHeader bold color={colors.muttedHeadlines}>
        Metadata
      </StyledHeader>
      <StyledButton size="small" onClick={changeMode}>
        Switch to {mode === "code" ? "tree" : "code"} mode
      </StyledButton>{" "}
      {metadata && !getting ? (
        <EditorWrapper>
          <Editor
            value={metadata}
            onChange={(json) => saveJson(json)}
            mode={mode}
          />
          <Divider />

          <div style={{ marginTop: 32 }}>
            <H4>Example:</H4>
            <Example>
              <pre>{JSON.stringify(metadataJsonExample, null, 2)}</pre>
            </Example>
          </div>
        </EditorWrapper>
      ) : (
        <LoaderContainer>
          <Loader1 />
        </LoaderContainer>
      )}
    </Wrapper>
  );
}

const Divider = styled.div`
  height: 2px;
  margin-top: 64px;

  width: 100%;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.disabled};
`;
const EditorWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
const Example = styled.div`
  margin-top: 32px;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 16px;
  background-color: ${(props) => props.theme.colors.disabled};
`;

const StyledButton = styled(Button)`
  max-width: 200px;
  margin-bottom: 32px;
`;
const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  min-height: 500px;
  align-items: center;
`;

const Wrapper = styled.div`
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

const StyledHeader = styled(H3)`
  padding-left: 30px;
`;
