const routes = {
  DASHBOARD: "/dashboard",
  NOTIFICATIONS: "/notifications",
  PROGRAMS: "/programs",
  PROGRAMS_ADD_NEW: "/add-new-program",
  PRODUCTS: "/products",
  COMPONENT_LIBRARY: "/components-library",
  SETTINGS: "/settings",
  DOCUMENTS_ADD_NEW: "/add-new-document",
};

export default routes;
