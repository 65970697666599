import React from "react";
import { PropertyPrioritiesItem } from "store/reducers/products/propertyPriorities/pl-propertyPriorities.types";
import { useDispatch } from "react-redux";
import { removePropertyPrioritiesList } from "store/reducers/products/propertyPriorities/pl-propertyPriorities.action";
import styled from "styled-components";
import { AddPropertyToPriorityList } from "./AddPropertyPropertyToList.component";
import PropertiesListTable from "./PropertiesListTable.component";
import PropertyPrioritiesHeader from "./PropertyPrioritiesHeader.component";
import ConfirmActionButton from "components/ConfirmActionModal/ConfirmActionButton.component";

export default function PropertyPriority({
  data,
}: {
  data: PropertyPrioritiesItem;
}) {
  const dispatch = useDispatch();

  const handleRemove = () => {
    dispatch(
      removePropertyPrioritiesList({ propertyPrioritiesListId: data.id })
    );
  };

  const confirmActionModalMessage =
    "Are you sure you want to delete this priority list?";

  return (
    <Wrapper>
      <TableWrapper>
        <PropertyPrioritiesHeader rulesetName={data.ruleset.name} />
        <PropertiesListTable
          properties={data.properties}
          propertyPriorityId={data.id}
        />
      </TableWrapper>
      <AddPropertyToPriorityList
        propertyPrioritiesId={data.id}
        rulesetName={data.ruleset.name}
      />
      <StyledConfirmActionModal
        action={handleRemove}
        message={confirmActionModalMessage}
        buttonType={"trashIcon"}
      />
    </Wrapper>
  );
}

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 290px;
  gap: 8px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`;

const StyledConfirmActionModal = styled(ConfirmActionButton)<any>`
  margin-top: 16px;
`;
