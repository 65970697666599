import React from "react";
import { HoveredInputCell } from "components";
import { Property } from "store/reducers/products/properties/pl-properties.types";
import { useDispatch } from "react-redux";
import { editPropertyAction } from "store/reducers/products/properties/pl-properties.actions";
import {
  editSubPropertyAction,
  getSubPropertiesAction,
} from "store/reducers/products/subproperties/pl-subproperties.actions";
import styled from "styled-components";
import { useParentPropertyContext } from "../../pages/ProductDetail/ProductLineProperties/ParentPropertyContext";
import { PROPERTY_NAME_REGEX } from "../../pages/ProductDetail/helpers";

export default function NameCell({
  propertyData,
  id,
  type,
}: {
  propertyData: Property;
  id?: string;
  type: string;
}) {
  const {
    parentId,
    parentPropertyName,
    productLineId,
  } = useParentPropertyContext();
  const dispatch = useDispatch();

  const mainPropertyNameFormatted = parentPropertyName.concat(".");
  const mainPRName = propertyData.name.split(mainPropertyNameFormatted);
  const name = type === "main" ? propertyData.name : mainPRName[1];

  const handleSubmit = (val) => {
    //Get the new name.
    const newName =
      type === "main" ? val : mainPropertyNameFormatted.concat(val);

    //If the value has changed, then do the update
    if (newName !== propertyData.name) {
      //Update the property data
      const newProperty = {
        ...propertyData,
        name: newName,
      };

      //If this is a parent property, update the property
      if (type === "main") {
        dispatch(
          editPropertyAction({
            propertyId: propertyData.id,
            property: newProperty,
            updatePayload: { name: val },
          })
        );

        //Fetch subproperties again because the name update of the parent will have cascaded to the subproperties
        //and we must refresh that data
        setTimeout(
          () =>
            dispatch(
              getSubPropertiesAction({
                productLineId: productLineId,
                PLPropertyId: propertyData.id,
              })
            ),
          1500
        );
      }

      //If this is a sub-property, update subproperty
      if (type === "subproperties") {
        dispatch(
          editSubPropertyAction({
            propertyId: propertyData.id,
            updatePayload: { name: newName },
            parentId: parentId ? parentId : "",
          })
        );
      }
    }
  };

  return (
    <StyledNameRow>
      {type === "subproperties" && (
        <StyledTitle>{mainPropertyNameFormatted}</StyledTitle>
      )}
      <HoveredInputCell
        initialContent={name}
        handleSubmit={handleSubmit}
        rows={1}
        id={id}
        validationRegex={PROPERTY_NAME_REGEX}
      />
    </StyledNameRow>
  );
}

const StyledNameRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledTitle = styled.div`
  margin-top: 3px;
`;
