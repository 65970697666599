import { Product, ProductDto, GetProductParams } from "./products.types";
import { api } from "api/api";
import { setRestVerb } from "../generalActions";
import { SET_PL_USERS } from "./users/pl-users.actions";
import { toast } from "react-toastify";

// Actions constants
export const SET_PRODUCTS = "products/SET_PRODUCTS";
export const SET_NEW_PRODUCT = "products/SET_NEW_PRODUCT";
export const ADD_NEW_PRODUCT = "products/ADD_NEW_PRODUCT";
export const IMPORT_NEW_PRODUCT = "products/IMPORT_NEWPRODUCT";
export const SET_VERB = "products/REST_VERB";
export const CREATE_PRODUCT_PANEL_OPEN = "products/CREATE_PRODUCT_PANEL_OPEN";
export const IMPORT_PRODUCT_PANEL_OPEN = "products/IMPORT_PRODUCT_PANEL_OPEN";
export const SET_SELECTED_PRODUCT_LINE =
  "productLines/SET_SELECTED_PRODUCT_LINE";
export const CLEAR_SELECTED_PRODUCT_LINE =
  "productLines/CLEAR_SELECTED_PRODUCT_LINE";
export const SET_PRODUCT_LINES_METADATA =
  "productLines/SET_PRODUCT_LINES_METADATA";
export const SET_DEFAULT_RULESET = "productLines/SET_DEFAULT_RULESET";

// Action creators
export const setProductsAction = (products: Product[]) => {
  return {
    type: SET_PRODUCTS,
    payload: products,
  };
};

export const setNewProductAction = (product: Partial<Product>) => {
  return {
    type: SET_NEW_PRODUCT,
    payload: product,
  };
};

export const addNewProductAction = (product: ProductDto) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setRestVerb("products", true, "posting"));

      const res = await api.createProduct(product);

      if (!res) throw null;

      const { data } = res;
      const { products } = getState();

      const img = await api.addOrUpdateCoverImageProductLine(
        product.img,
        data.id
      );
      data.img = img.data;

      const newProducts = products.products.concat([data]);

      dispatch({
        type: ADD_NEW_PRODUCT,
        payload: newProducts,
      });
    } catch (e) {
      // api errors are automatically caught at the API class level if you don't specify otherwise
      // this catch block is only for other types of errors
    }
    dispatch(setRestVerb("products", false, "posting"));
  };
};

export const importProductAction = (product: Product) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: IMPORT_NEW_PRODUCT,
        payload: product,
      });
    } catch (e) {
      // api errors are automatically caught at the API class level if you don't specify otherwise
      // this catch block is only for other types of errors
    }
  };
};

export const getProductsAction = (params: GetProductParams) => {
  return async (dispatch) => {
    try {
      dispatch(setRestVerb("products", true, "getting"));
      // TODO: make the users fetch using specific endpoint
      dispatch(setRestVerb("products/plUsers", true, "getting"));

      if (!params.regions?.length) params.regions = undefined;

      const res = await api.getProducts(params);
      if (!res) throw null;

      const { productLines, items, page, total } = res.data;
      const formattedPl = productLines.map((productLine) => {
        return {
          ...productLine,
          img: productLine.imageURL,
        };
      });

      dispatch({
        type: SET_PRODUCTS,
        payload: formattedPl,
      });
      dispatch({
        type: SET_PRODUCT_LINES_METADATA,
        payload: {
          items,
          page,
          total,
        },
      });
    } catch (e) {
      // api errors are automatically caught at the API class level if you don't specify otherwise
      // this catch block is only for other types of errors
    }
    dispatch(setRestVerb("products", false, "getting"));
    dispatch(setRestVerb("products/plUsers", false, "getting"));
  };
};

export const getProductLine = (id: string) => {
  return async (dispatch) => {
    try {
      dispatch(setRestVerb("products", true, "getting"));
      const res = await api.getProductLine(id);
      if (!res) throw null;

      const productLine = res.data;
      const { users } = productLine;
      dispatch({
        type: SET_PL_USERS,
        payload: users,
      });
      delete productLine.users;

      dispatch({
        type: SET_SELECTED_PRODUCT_LINE,
        payload: productLine,
      });
    } catch (e) {
      console.error(e, "error while getting product line");
    }
    dispatch(setRestVerb("products", false, "getting"));
  };
};

export const deleteProductLineAction = (productLineId, history) => {
  return async (dispatch) => {
    try {
      dispatch(setRestVerb("products", true, "deleting"));

      await api.deleteProductLine(productLineId);
      dispatch({
        type: SET_SELECTED_PRODUCT_LINE,
        payload: undefined,
      });
      history.push("/products");
    } catch (e) {
      console.error(e, "error while deleting product");
    }
    dispatch(setRestVerb("products", false, "deleting"));
  };
};

export const updateProductLineAction = (productLineId: string, body: any) => {
  return async (dispatch, getState) => {
    try {
      const { selectedProductLine } = getState().products;

      await api.updateProductLIne(productLineId, body);
      dispatch({
        type: SET_SELECTED_PRODUCT_LINE,
        payload: { ...selectedProductLine, ...body },
      });
    } catch (e) {
      toast.error("error while updating product line");
    }
  };
};
// clearing
export const clearSelectedProductLine = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_SELECTED_PRODUCT_LINE,
      payload: null,
    });
  };
};

// others

export const setAddProductPanelOpen = (status: boolean) => {
  return {
    type: CREATE_PRODUCT_PANEL_OPEN,
    payload: status,
  };
};

export const setSelectedProductLineAction = (productLineId: string) => {
  return {
    type: SET_SELECTED_PRODUCT_LINE,
    payload: productLineId,
  };
};

export const changeDefaultRuleset = (newDefaultRuleset: string) => {
  return {
    type: SET_DEFAULT_RULESET,
    payload: newDefaultRuleset,
  };
};

export const setImportProductPanelOpen = (status: boolean) => {
  return {
    type: IMPORT_PRODUCT_PANEL_OPEN,
    payload: status,
  };
};
