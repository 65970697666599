import React from "react";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { Button } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ClearIcon from "@mui/icons-material/Clear";
import SortIcon from "@mui/icons-material/Sort";

export interface RATGridToolbarProps {
  showAddButton?: boolean;
  addButtonText?: string;
  handleAddRow?: () => void;
  showApplySortButton?: boolean;
  handleApplySort?: () => void;
  showClearSortButton?: boolean;
  handleClearSort?: () => void;
  showColumnsButton?: boolean;
  showFilterButton?: boolean;
  showDensityButton?: boolean;
  showExportButton?: boolean;
}

export const RATGridToolbar: React.FC<RATGridToolbarProps> = ({
  showAddButton = true,
  addButtonText = "Add row",
  handleAddRow = () => {
    return;
  },
  showApplySortButton = true,
  handleApplySort = () => {
    return;
  },
  showClearSortButton = true,
  handleClearSort = () => {
    return;
  },
  showColumnsButton = true,
  showFilterButton = true,
  showDensityButton = true,
  showExportButton = true,
}) => {
  return (
    <GridToolbarContainer>
      {showAddButton && (
        <Button size="small" onClick={handleAddRow}>
          <AddCircleOutlineIcon />
          {addButtonText}
        </Button>
      )}
      {showApplySortButton && (
        <Button size="small" onClick={handleApplySort}>
          <SortIcon />
          Apply Sort
        </Button>
      )}
      {showClearSortButton && (
        <Button size="small" onClick={handleClearSort}>
          <ClearIcon />
          Clear Sort
        </Button>
      )}
      {showColumnsButton && <GridToolbarColumnsButton />}
      {showFilterButton && <GridToolbarFilterButton />}
      {showDensityButton && <GridToolbarDensitySelector />}
      {showExportButton && <GridToolbarExport />}
    </GridToolbarContainer>
  );
};

export default RATGridToolbar;
