import { URL_UI_DEV, URL_UI_PROD } from "api/constants";

export const appConfig =
  process?.env?.NODE_ENV === "development"
    ? {
        uiUrl: URL_UI_DEV,
        apiUrl: "https://api.carrierappshub1.com/",
        //postLogoutRedirectUri: "http://localhost:3000",
      }
    : {
        uiUrl: URL_UI_PROD,
        apiUrl: "https://api.carrierappshub1.com/",
        //postLogoutRedirectUri: window.location.origin,
      };

export default appConfig;
