import { GetUsersBasedOnPermissions } from "@carrier/reactauthwrapper";
import { URL_CARRIER_USERMANAGEMENT_API } from "api/constants";
import {
  CarrierUser,
  UsersReducerObject,
} from "store/reducers/users/users.types";
import { toast } from "react-toastify";
import { sanitizeUsers } from "store/reducers/users/users.helpers";
import { appName } from "api/baseUrl";

const APP_PERMISSION = appName;

interface Response {
  usersDetails: any;
  appID: number;
}

const PromiseGetUsersBasedOnPermissions = (): Promise<Response> =>
  new Promise((resolve, reject) => {
    GetUsersBasedOnPermissions(
      URL_CARRIER_USERMANAGEMENT_API,
      {
        Permission: APP_PERMISSION,
        GroupInfo: true,
        UserActivityInfo: false,
      },
      (res) => resolve(res),
      (e) => reject(e)
    );
  });

export async function fetchCarrierUsers(): Promise<UsersReducerObject> {
  try {
    const res = await PromiseGetUsersBasedOnPermissions();

    // TODO: if in need of optimization, use .reduce
    // because filter + map goes through the whole array twice.
    const users = sanitizeUsers(res.usersDetails);

    return formatUsersArrayToObjectById(users);
  } catch (e) {
    console.error(e);
    toast.error(
      "An error happened while fetching users list, please contact technical support"
    );

    return { byId: undefined, allIds: [] };
  }
}

export function formatUsersArrayToObjectById(users: CarrierUser[]) {
  const usersObject: UsersReducerObject = {
    byId: {},
    allIds: [],
  };

  users.forEach((user) => {
    usersObject.byId[user.userID] = user;
    usersObject.allIds.push(user.userID);
  });

  return usersObject;
}
