import React from "react";
import styled from "styled-components";
import { ArrowRight } from "assets/logos/icons/MediumIcons";

const getPages = (total) => {
  let page = 0;
  const pages: any = [];
  for (let left = total; left > 0; left = left - 10) {
    pages.push(page);
    page++;
  }
  return pages;
};

export default function ProductLinePagination({
  metadata,
  setSearchPage,
}: {
  metadata: any;
  setSearchPage: any;
}) {
  const { page, total } = metadata;
  const totalNumberOfPages = Math.ceil(total / 10);
  const pages = getPages(total);

  const handleGetProductLines = (page: number) => {
    setSearchPage(page);
  };

  if (!pages?.length) return <></>;

  const arrowLeftIsDisabled = page === 0;
  const arrowRightIsDisabled = totalNumberOfPages - 1 === page;

  return (
    <Wrapper>
      <Arrow
        onClick={() => (arrowLeftIsDisabled ? null : handleGetProductLines(0))}
        disabled={arrowLeftIsDisabled}
      >
        <StyledArrowLeft size="16" isUnderlined={true} />
      </Arrow>
      <Arrow
        onClick={() =>
          arrowLeftIsDisabled ? null : handleGetProductLines(page - 1)
        }
        disabled={arrowLeftIsDisabled}
      >
        <StyledArrowLeft size="16" />
      </Arrow>

      {pages.map((p) => {
        const isCurrent = page === p;

        if (Math.abs(page - p) > 3) {
          return null;
        }

        return (
          <Page
            isCurrent={isCurrent}
            key={p}
            onClick={() => handleGetProductLines(p)}
          >
            {p + 1}
          </Page>
        );
      })}
      <Arrow
        onClick={() =>
          arrowRightIsDisabled ? null : handleGetProductLines(page + 1)
        }
        disabled={arrowRightIsDisabled}
      >
        <StyledArrowRight size="16" />
      </Arrow>

      <Arrow
        onClick={() =>
          arrowRightIsDisabled
            ? null
            : handleGetProductLines(totalNumberOfPages - 1)
        }
        disabled={arrowRightIsDisabled}
      >
        <StyledArrowRight size="16" isUnderlined={true} />
      </Arrow>
    </Wrapper>
  );
}

const StyledArrowLeft = styled<any>(ArrowRight)`
  border-top: ${(props) => (props.isUnderlined ? "1px solid" : "none")};
  padding-bottom: 4px;
  padding-top: 4px;
  transform: rotate(180deg);
`;

const StyledArrowRight = styled<any>(ArrowRight)`
  border-bottom: ${(props) => (props.isUnderlined ? "1px solid" : "none")};
  padding-bottom: 4px;
  padding-top: 4px;
`;

const Wrapper = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Arrow = styled.div<any>`
  font-size: 16px;
  color: ${(props) =>
    props.disabled
      ? props.theme.colors.muttedHeadlines
      : props.theme.colors.secondary};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  padding: 8px;
  display: flex;
  :hover {
    background-color: ${(props) =>
      props.disabled ? "white" : props.theme.colors.tables.borders};
  }

  svg {
    path {
      fill: ${(props) =>
        props.disabled
          ? props.theme.colors.muttedHeadlines
          : props.theme.colors.secondary};
    }
  }
`;

const Page = styled.span<any>`
  padding: 16px;
  font-size: 16px;
  text-align: center;
  margin-right: 8px;
  cursor: pointer;
  color: ${(props) => props.theme.colors.secondary};
  text-decoration: ${(props) => (props.isCurrent ? "underline" : "none")};

  :hover {
    background-color: ${(props) => props.theme.colors.tables.borders};
  }
`;
