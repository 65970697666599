import { SET_NOTIFICATION } from "./notifications.actions";
import { Notification } from "./notifications.types";

export interface NotificationReducerState {
  notifications: Notification[];
}

const mockedNotifications: Notification[] = [
  {
    title: "Continuous deployment is working!",
    status: "awaitingApproval",
    description: "🚀🚀🚀🚀🚀",
    createdAt: "August 11, 2020",
  },
  {
    title: "50GC Supply Fan Components",
    status: "complete",
    description: "A short description about something that is complete.",
    createdAt: "August 11, 2020",
  },
  {
    title: "47GC Supply Fan Components",
    status: "attentionRequired",
    description:
      "A short description about something that is requiring your attention. And a very long description, that shall automatically add ellipsis.",
    createdAt: "August 11, 2020",
  },
];

const initialState: NotificationReducerState = {
  notifications: mockedNotifications,
};

export default function notificationsReducer(
  state: NotificationReducerState = initialState,
  action: any
): NotificationReducerState {
  switch (action.type) {
    // be aware, this doesn't reset the store (...state)
    case SET_NOTIFICATION: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
}
