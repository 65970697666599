export const candidateJsonExample = [
  {
    Sections: [
      {
        searchVariables: [
          "sModel",
          "sClrSize",
          "sCndSize",
          "sCmpFrameSize",
          "bEconomizer",
          "sFloatValveSize",
          "Clr_Tube_sCode",
          "Cnd_Tube_sCode",
          "sEconFloatValveSize",
        ],
        lookupVariables: [],
        outputVariableNames: [
          "sModel",
          "sClrSize",
          "sCndSize",
          "sCmpFrameSize",
          "bEconomizer",
          "MinCoolerPasses",
          "MaxCoolerPasses",
          "MinCondenserPasses",
          "MaxCondenserPasses",
          "sFloatValveSize",
          "Clr_Tube_sCode",
          "Cnd_Tube_sCode",
          "sEconFloatValveSize",
        ],
        minBoundVars: {
          lClrPassesCode: "MinCoolerPasses",
          lCndPassesCode: "MinCondenserPasses",
        },
        maxBoundVars: {
          lClrPassesCode: "MaxCoolerPasses",
          lCndPassesCode: "MaxCondenserPasses",
        },
        excludedVarNames: {},
        sectionName: "InputCandList",
      },
      {
        searchVariables: ["sModel", "sCmpFrameSize", "sCmpSize"],
        lookupVariables: [],
        outputVariableNames: ["sModel", "sCmpFrameSize", "sCmpSize"],
        minBoundVars: {},
        maxBoundVars: {},
        excludedVarNames: {},
        sectionName: "InputCmpList",
      },
      {
        searchVariables: [
          "sModel",
          "sMtrFrameSize",
          "sMtrCode",
          "sMtrEfficiency",
        ],
        lookupVariables: [],
        outputVariableNames: [
          "sModel",
          "sMtrFrameSize",
          "sMtrCode",
          "sMtrEfficiency",
        ],
        minBoundVars: {},
        maxBoundVars: {},
        excludedVarNames: {},
        sectionName: "InputMtrList",
      },
    ],
    Tables: [],
    ModelName: "SEL_19DV",
  },
  {
    Sections: [
      {
        searchVariables: [
          "Calc_nProductID",
          "sClrSize.nCode",
          "sCndSize.nCode",
          "sCmpFrameSize.nCode",
          "Calc_nEconomizer",
          "sFloatValveSize.nCode",
          "Clr_Tube_sCodeAlt",
          "Clr_Tube_sTypeAlt.nCode",
          "Cnd_Tube_sCodeAlt",
          "Cnd_Tube_sTypeAlt.nCode",
          "StartEquip_sType.nCode",
        ],
        lookupVariables: [],
        outputVariableNames: [
          "Calc_nProductID",
          "sClrSize.nCode",
          "sCndSize.nCode",
          "sCmpFrameSize.nCode",
          "Calc_nEconomizer",
          "MinCoolerPasses",
          "MaxCoolerPasses",
          "MinCondenserPasses",
          "MaxCondenserPasses",
          "sFloatValveSize.nCode",
          "MinFlascOrifCode",
          "MaxFlascOrifCode",
          "Clr_Tube_sCodeAlt",
          "Clr_Tube_sTypeAlt.nCode",
          "Cnd_Tube_sCodeAlt",
          "Cnd_Tube_sTypeAlt.nCode",
          "StartEquip_sType.nCode",
        ],
        minBoundVars: {
          lClrPassesCode: "MinCoolerPasses",
          lCndPassesCode: "MinCondenserPasses",
          "BU_sFlascOrifSize.nCode": "MinFlascOrifCode",
        },
        maxBoundVars: {
          lClrPassesCode: "MaxCoolerPasses",
          lCndPassesCode: "MaxCondenserPasses",
          "BU_sFlascOrifSize.nCode": "MaxFlascOrifCode",
        },
        excludedVarNames: {},
        sectionName: "InputCandList",
      },
      {
        searchVariables: [
          "Calc_nProductID",
          "sCmpFrameSize.nCode",
          "sCmpSize.nCode",
        ],
        lookupVariables: [],
        outputVariableNames: [
          "Calc_nProductID",
          "sCmpFrameSize.nCode",
          "sCmpSize.nCode",
        ],
        minBoundVars: {},
        maxBoundVars: {},
        excludedVarNames: {},
        sectionName: "InputCmpList",
      },
      {
        searchVariables: [
          "Calc_nProductID",
          "sMtrFrameSize.nCode",
          "sMtrSize.nCode",
          "Mtr_sBarMaterial.nCode",
        ],
        lookupVariables: [],
        outputVariableNames: [
          "Calc_nProductID",
          "sMtrFrameSize.nCode",
          "sMtrSize.nCode",
          "Mtr_sBarMaterial.nCode",
        ],
        minBoundVars: {},
        maxBoundVars: {},
        excludedVarNames: {},
        sectionName: "InputMtrList",
      },
    ],
    Tables: [
      {
        propertyName: "MLP_Mtr_Voltage_fExtra",
        tableName: "MotorPrice",
        exportedColumns: ["sMtrSize.nCode", "MLP_Mtr_Voltage_fExtra"],
      },
      {
        propertyName: "MLP_Clr_WB_fExtra",
        tableName: "EvapWaterBoxPrice",
        exportedColumns: [
          "lClrPassesCode",
          "sClrFrameSize.nCode",
          "MLP_Clr_WB_fExtra",
        ],
      },
      {
        propertyName: "MLP_Cnd_WB_fExtra",
        tableName: "CondWaterBoxPrice",
        exportedColumns: [
          "lCndPassesCode",
          "sCndFrameSize.nCode",
          "MLP_Cnd_WB_fExtra",
        ],
      },
      {
        propertyName: "MLP_Clr_Tube_fExtra",
        tableName: "EvapTubingPrice",
        exportedColumns: [
          "sClrSize.nCode",
          "Clr_Tube_sCodeAlt",
          "MLP_Clr_Tube_fExtra",
        ],
      },
      {
        propertyName: "MLP_Cnd_Tube_fExtra",
        tableName: "CondTubingPrice",
        exportedColumns: [
          "sCndSize.nCode",
          "Cnd_Tube_sCodeAlt",
          "MLP_Cnd_Tube_fExtra",
        ],
      },
      {
        propertyName: "MLP_Refrig_fExtra",
        tableName: "RefrigerantPrice",
        exportedColumns: [
          "Calc_nProductID",
          "Calc_nEconomizerRefrigPrices",
          "sClrSize.nCode",
          "MLP_Refrig_fExtra",
        ],
      },
      {
        propertyName: "MLP_Cmp_Opt_fSRD",
        tableName: "OptionalPrice",
        exportedColumns: ["sCmpFrameSize.nCode", "MLP_Cmp_Opt_fSRD"],
      },
      {
        propertyName: "MLP_Cmp_Opt_fIsoValve",
        tableName: "IsolationValvePrice",
        exportedColumns: [
          "sCmpFrameSize.nCode",
          "Calc_nEconomizerIsoValveMLP",
          "MLP_Cmp_Opt_fIsoValve",
        ],
      },
      {
        propertyName: "MLP_VFD_UM_fExtra",
        tableName: "UnitMtdVFDPrice",
        exportedColumns: [
          "Calc_nProductID",
          "MLP_VFD_nSizeIndex",
          "MLP_VFD_nMaxRLA",
          "MLP_VFD_nMaxInRLA",
          "sCmpFrameSize.nCode",
          "MLP_VFD_UM_fExtra",
        ],
      },
      {
        propertyName: "MLP_Start_UM_fExtra",
        tableName: "UnitMtdStarterPrice",
        exportedColumns: [
          "MLP_Start_nSizeIndex",
          "MLP_Start_nMaxRLA",
          "MLP_Start_nMaxLRDA",
          "MLP_Start_nMinCmpFrameSize",
          "MLP_Start_nMaxCmpFrameSize",
          "MLP_Start_UM_fExtra",
        ],
      },
    ],
    ModelName: "SEL_19XR",
  },
  {
    Sections: [
      {
        searchVariables: [
          "Calc_nProductID",
          "sClrSize.nCode",
          "sCndSize.nCode",
          "sCmpFrameSize.nCode",
          "Calc_nEconomizer",
          "sFloatValveSize.nCode",
          "Clr_Tube_sCodeAlt",
          "Clr_Tube_sTypeAlt.nCode",
          "Cnd_Tube_sCodeAlt",
          "Cnd_Tube_sTypeAlt.nCode",
          "StartEquip_sType.nCode",
        ],
        lookupVariables: [],
        outputVariableNames: [
          "Calc_nProductID",
          "sClrSize.nCode",
          "sCndSize.nCode",
          "sCmpFrameSize.nCode",
          "Calc_nEconomizer",
          "MinCoolerPasses",
          "MaxCoolerPasses",
          "MinCondenserPasses",
          "MaxCondenserPasses",
          "sFloatValveSize.nCode",
          "MinFlascOrifCode",
          "MaxFlascOrifCode",
          "Clr_Tube_sCodeAlt",
          "Clr_Tube_sTypeAlt.nCode",
          "Cnd_Tube_sCodeAlt",
          "Cnd_Tube_sTypeAlt.nCode",
          "StartEquip_sType.nCode",
        ],
        minBoundVars: {
          lClrPassesCode: "MinCoolerPasses",
          lCndPassesCode: "MinCondenserPasses",
          "BU_sFlascOrifSize.nCode": "MinFlascOrifCode",
        },
        maxBoundVars: {
          lClrPassesCode: "MaxCoolerPasses",
          lCndPassesCode: "MaxCondenserPasses",
          "BU_sFlascOrifSize.nCode": "MaxFlascOrifCode",
        },
        excludedVarNames: {},
        sectionName: "InputCandList",
      },
      {
        searchVariables: [
          "Calc_nProductID",
          "sCmpFrameSize.nCode",
          "sCmpSize.nCode",
        ],
        lookupVariables: [],
        outputVariableNames: [
          "Calc_nProductID",
          "sCmpFrameSize.nCode",
          "sCmpSize.nCode",
        ],
        minBoundVars: {},
        maxBoundVars: {},
        excludedVarNames: {},
        sectionName: "InputCmpList",
      },
      {
        searchVariables: [
          "Calc_nProductID",
          "sMtrFrameSize.nCode",
          "sMtrSize.nCode",
          "Mtr_sBarMaterial.nCode",
        ],
        lookupVariables: [],
        outputVariableNames: [
          "Calc_nProductID",
          "sMtrFrameSize.nCode",
          "sMtrSize.nCode",
          "Mtr_sBarMaterial.nCode",
        ],
        minBoundVars: {},
        maxBoundVars: {},
        excludedVarNames: {},
        sectionName: "InputMtrList",
      },
    ],
    Tables: [
      {
        propertyName: "MLP_Mtr_Voltage_fExtra",
        tableName: "MotorPrice",
        exportedColumns: ["sMtrSize.nCode", "MLP_Mtr_Voltage_fExtra"],
      },
      {
        propertyName: "MLP_Clr_WB_fExtra",
        tableName: "EvapWaterBoxPrice",
        exportedColumns: [
          "lClrPassesCode",
          "sClrFrameSize.nCode",
          "MLP_Clr_WB_fExtra",
        ],
      },
      {
        propertyName: "MLP_Cnd_WB_fExtra",
        tableName: "CondWaterBoxPrice",
        exportedColumns: [
          "lCndPassesCode",
          "sCndFrameSize.nCode",
          "MLP_Cnd_WB_fExtra",
        ],
      },
      {
        propertyName: "MLP_Clr_Tube_fExtra",
        tableName: "EvapTubingPrice",
        exportedColumns: [
          "sClrSize.nCode",
          "Clr_Tube_sCodeAlt",
          "MLP_Clr_Tube_fExtra",
        ],
      },
      {
        propertyName: "MLP_Cnd_Tube_fExtra",
        tableName: "CondTubingPrice",
        exportedColumns: [
          "sCndSize.nCode",
          "Cnd_Tube_sCodeAlt",
          "MLP_Cnd_Tube_fExtra",
        ],
      },
      {
        propertyName: "MLP_Refrig_fExtra",
        tableName: "RefrigerantPrice",
        exportedColumns: [
          "Calc_nProductID",
          "Calc_nEconomizer",
          "sClrSize.nCode",
          "MLP_Refrig_fExtra",
        ],
      },
      {
        propertyName: "MLP_Cmp_Opt_fSRD",
        tableName: "OptionalPrice",
        exportedColumns: ["sCmpFrameSize.nCode", "MLP_Cmp_Opt_fSRD"],
      },
      {
        propertyName: "MLP_Cmp_Opt_fIsoValve",
        tableName: "IsolationValvePrice",
        exportedColumns: [
          "sCmpFrameSize.nCode",
          "Calc_nEconomizer",
          "MLP_Cmp_Opt_fIsoValve",
        ],
      },
      {
        propertyName: "MLP_VFD_UM_fExtra",
        tableName: "UnitMtdVFDPrice",
        exportedColumns: [
          "Calc_nProductID",
          "MLP_VFD_nSizeIndex",
          "MLP_VFD_nMaxRLA",
          "MLP_VFD_nMaxInRLA",
          "sCmpFrameSize.nCode",
          "MLP_VFD_UM_fExtra",
        ],
      },
    ],
    ModelName: "SEL_23XR",
  },
  {
    Sections: [
      {
        searchVariables: [
          "sModel",
          "sClrSize",
          "sCndSize",
          "sCmpFrameSize",
          "bEconomizer",
          "lClrPassesCode",
          "lCndPassesCode",
          "Clr_Tube_sCode",
          "Cnd_Tube_sCode",
        ],
        lookupVariables: [],
        outputVariableNames: [
          "sModel",
          "sClrSize",
          "sCndSize",
          "sCmpFrameSize",
          "bEconomizer",
          "lClrPassesCode",
          "lCndPassesCode",
          "Clr_Tube_sCode",
          "Cnd_Tube_sCode",
        ],
        minBoundVars: {},
        maxBoundVars: {},
        excludedVarNames: {},
        sectionName: "InputCandList",
      },
      {
        searchVariables: ["sModel", "sCmpFrameSize", "sCmpSize", "Cmp_sType"],
        lookupVariables: [],
        outputVariableNames: [
          "sModel",
          "sCmpFrameSize",
          "sCmpSize",
          "Cmp_sType",
        ],
        minBoundVars: {},
        maxBoundVars: {},
        excludedVarNames: {},
        sectionName: "InputCmpList",
      },
      {
        searchVariables: ["sModel", "sMtrFrameSize", "sMtrSize"],
        lookupVariables: [],
        outputVariableNames: ["sModel", "sMtrFrameSize", "sMtrSize"],
        minBoundVars: {},
        maxBoundVars: {},
        excludedVarNames: {},
        sectionName: "InputMtrList",
      },
      {
        searchVariables: ["sModel", "Perf_VFD_sCode", "Perf_VFD_sSize"],
        lookupVariables: [],
        outputVariableNames: ["sModel", "Perf_VFD_sCode", "Perf_VFD_sSize"],
        minBoundVars: {},
        maxBoundVars: {},
        excludedVarNames: {},
        sectionName: "VFD",
      },
    ],
    Tables: [],
    ModelName: "SEL_19MV",
  },
];
