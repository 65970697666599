import React from "react";
import { mappedLabelTypesToColors } from "theme/colors";
import styled from "styled-components";

export type LabelType =
  | undefined
  | "paused"
  | "complete"
  | "started"
  | "late"
  | "onTime";

export function Label({
  children,
  type = "started",
  icon,
}: {
  children?: any;
  type: LabelType;
  icon?: any;
}) {
  return (
    <Wrapper type={type}>
      {icon && <IconWrapper type={type}>{icon}</IconWrapper>}
      {children}
    </Wrapper>
  );
}

const IconWrapper = styled.div<any>`
  display: flex;
  path {
    fill: ${(props) => mappedLabelTypesToColors[props.type].color};
    stroke: ${(props) => mappedLabelTypesToColors[props.type].color};
    color: ${(props) => mappedLabelTypesToColors[props.type].color};
  }
`;

const Wrapper = styled.div<any>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 6px;
  background-color: ${(props) => mappedLabelTypesToColors[props.type].bg};
  width: fit-content;
  color: ${(props) => mappedLabelTypesToColors[props.type].color};
  font-weight: bold;
  border-radius: 4px;
  line-height: 16px;
  font-size: 12px;
`;
