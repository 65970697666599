import { useEffect, useState } from "react";
import {
  DataTypeEnum,
  DomainEnum,
} from "store/reducers/products/properties/pl-properties.types";
import {
  ErrorObject,
  ValidatorNames,
} from "../../store/reducers/products/validation/validation.types";

export default function useValidateRangeProperty({
  domain,
  dataType,
}: {
  domain: DomainEnum;
  dataType: DataTypeEnum;
}) {
  const [errorMessage, setErrorMessage] = useState<
    Pick<ErrorObject, "message" | "validatorName">
  >({
    message: null,
    validatorName: ValidatorNames.WrongDataTypeForRange,
  });

  useEffect(() => {
    if (
      domain === DomainEnum.RANGE &&
      ![DataTypeEnum.INT, DataTypeEnum.FLOAT].includes(dataType)
    ) {
      setErrorMessage({
        message: "wrong data type for range property",
        validatorName: ValidatorNames.WrongDataTypeForRange,
      });
      return;
    }
    setErrorMessage({
      message: null,
      validatorName: ValidatorNames.WrongDataTypeForRange,
    });
  }, [domain, dataType]);

  return errorMessage;
}
