import React from "react";
import { mappedLabelTypesToColors } from "theme/colors";
import { LabelType } from "components/Label/Label.component";
import styled from "styled-components";

export function ProgressBar({
  completion,
  status = "started",
}: {
  completion: number;
  status?: LabelType;
}) {
  const { color } = mappedLabelTypesToColors[status];
  return (
    <Container>
      <Completion color={color}>{completion}%</Completion>
      <Wrapper color={color}>
        <Progress completion={completion} color={color} />
      </Wrapper>
    </Container>
  );
}

const Progress = styled.div<any>`
  background-color: ${(props) => props.color};
  width: ${(props) => (props.completion ? props.completion + "%" : "5%")};
  height: 8px;
  border-radius: 12px;
`;

const Completion = styled.div<any>`
  text-align: right;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => props.color};
  margin-bottom: 4px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  max-width: 100%;
`;

const Wrapper = styled.div<any>`
  background-color: ${(props) => props.theme.colors.tables.borders};
  width: 300px;
  height: 8px;
  border-radius: 12px;
  max-width: 100%;
`;
