import React from "react";
import styled from "styled-components";
import MenuItem from "./MenuItem";

export default function SubLeftMenu({ menuItems }: { menuItems: any }) {
  return (
    <Wrapper>
      {menuItems.map(({ path, label }) => {
        return (
          <MenuItem key={path} path={path}>
            {label}
          </MenuItem>
        );
      })}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  flex-shrink: 0;
  width: 245px;
  border-right: 1px solid ${(props) => props.theme.colors.tables.borders};
`;
