import React, { useState } from "react";
import styled from "styled-components";
import {
  DataTypeEnum,
  DomainEnum,
} from "store/reducers/products/properties/pl-properties.types";
import { Button, Input, Modal } from "../../../../components";
import SelectDropdownComponent, {
  Option,
} from "components/Forms/SelectDropdown/SelectDropdown.component";
import { useDispatch } from "react-redux";
import { editRuleTableAction } from "../../../../store/reducers/products/rulestables/pl-rulestables.actions";
import Checkbox from "../../../../components/Forms/Checkbox.component";

export function BatchEditButton({ ruletableData }: { ruletableData: any }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState<
    undefined | Option
  >();
  const [valueIncrease, setValueIncrease] = useState<number | undefined>(
    undefined
  );
  const [increaseDeductPrice, setIncreaseDeductPrice] = useState(false);
  const [roundOption, setRoundOption] = useState(false);

  const dispatch = useDispatch();

  const propertyData = ruletableData.properties.filter(
    (property) =>
      [DataTypeEnum.FLOAT, DataTypeEnum.INT].includes(property.dataType) &&
      property.domain === DomainEnum.OPEN
  );

  const propertyOptions = propertyData.map((property) => {
    return { value: property.id, label: property.name };
  });

  const handleSelectProperty = (e) => {
    setSelectedProperty(e);
  };

  const isPropertyDataTypeFloat = (ruletableData, propertyId) => {
    const property = ruletableData.properties.find(
      (property) => property.id === propertyId
    );
    return property.dataType === DataTypeEnum.FLOAT;
  };

  const handleInputChange = (e) => {
    if (e.target.value === "") {
      setValueIncrease(undefined);
      return;
    }
    setValueIncrease(parseFloat(e.target.value));
  };

  const increaseValueByPercent = (
    value,
    percent,
    isFloat,
    increaseDeductPrice,
    roundFloat
  ) => {
    let newValue: number;
    if (increaseDeductPrice) {
      newValue =
        Math.round((value * ((percent + 100) / 100) + Number.EPSILON) * 100) /
        100;

      if (roundFloat) newValue = Math.round(newValue);
      return isFloat ? newValue.toFixed(2) : Math.round(newValue);
    }

    newValue =
      Math.round(
        (value + Math.abs(value * (percent / 100)) + Number.EPSILON) * 100
      ) / 100;

    if (roundFloat) newValue = Math.round(newValue);
    return isFloat ? newValue.toFixed(2) : Math.round(newValue);
  };

  const handleSubmit = async () => {
    if (ruletableData.id && selectedProperty?.value && valueIncrease) {
      const propertyId = selectedProperty.value;
      const isFloat = isPropertyDataTypeFloat(
        ruletableData,
        selectedProperty.value
      );
      const newRows = ruletableData.rows.map((row) => {
        const newCells = row.cells.map((cell) => {
          if (cell.propertyId === propertyId && cell.value[0]) {
            const value = parseFloat(cell.value[0]);
            const newValue = increaseValueByPercent(
              value,
              valueIncrease,
              isFloat,
              increaseDeductPrice,
              roundOption
            );
            return {
              ...cell,
              value: [newValue.toString()],
            };
          }
          return cell;
        });
        return { ...row, cells: newCells };
      });

      dispatch(
        editRuleTableAction({
          ruleTableId: ruletableData.id,
          ruleTable: { rows: newRows },
        })
      );
      setModalOpen(false);
      setSelectedProperty(undefined);
      setValueIncrease(undefined);
      setModalOpen(false);
      setRoundOption(false);
      setIncreaseDeductPrice(false);
    }
  };

  const handleCloseModal = () => {
    setSelectedProperty(undefined);
    setValueIncrease(undefined);
    setModalOpen(false);
    setRoundOption(false);
    setIncreaseDeductPrice(false);
  };

  if (!propertyData.length) return null;

  return (
    <>
      <Button size="small" type="secondary" onClick={() => setModalOpen(true)}>
        Blanket Price Change
      </Button>
      <Modal
        title={"Blanket Price Change"}
        open={modalOpen}
        closeModal={handleCloseModal}
        height={"300"}
      >
        {modalOpen && (
          <ModalContentWrapper>
            Select the column you want to edit:
            <StyledSelectDropdownComponent
              size={"small"}
              options={propertyOptions}
              name={"property-select"}
              value={selectedProperty}
              handleChange={handleSelectProperty}
            />
            Percent value:
            <ValueWrapper>
              <StyledInput value={valueIncrease} onChange={handleInputChange} />
              <Percent>{"%"}</Percent>
            </ValueWrapper>
            <CheckBoxWrapper>
              Increase deduct price
              <Checkbox
                checked={increaseDeductPrice}
                onClick={() =>
                  setIncreaseDeductPrice((prevState) => !prevState)
                }
              />
            </CheckBoxWrapper>
            <CheckBoxWrapper>
              Round float value
              <Checkbox
                checked={roundOption}
                onClick={() => setRoundOption((prevState) => !prevState)}
              />
            </CheckBoxWrapper>
            <Button
              disabled={!selectedProperty || !valueIncrease}
              onClick={handleSubmit}
            >
              submit
            </Button>
          </ModalContentWrapper>
        )}
      </Modal>
    </>
  );
}

const CheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding-bottom: 16px;
`;

const ModalContentWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
`;

const StyledSelectDropdownComponent = styled(SelectDropdownComponent)<any>`
  width: 300px;
  height: 40px;
  padding-bottom: 16px;
`;

const StyledInput = styled(Input)<any>`
  height: 50px;
  margin-right: 8px;
`;

const Percent = styled.div``;

const ValueWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: slategrey;
  font-size: 20px;
  width: 120px;
  margin-bottom: 16px;
`;
