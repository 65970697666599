import React, { useRef, useState } from "react";
import styled from "styled-components";
import { Link } from "assets/logos/icons/MediumIcons";
import { H5 } from "components";
import colors from "theme/colors";

export const FileInput = ({
  placeholder,
  size = "tall",
  label = "",
  required = false,
  className,
}: {
  placeholder?: string;
  label?: string;
  size?: "tall" | "short";
  required?: boolean;
  className?: any;
}) => {
  const [fileName, setFileName] = useState();
  const inputFile = useRef<HTMLInputElement>(null);
  const onButtonClick = () => {
    inputFile?.current?.click();
  };
  const onChangeHandler = (event) => {
    setFileName(event.target.files[0].name);
  };
  return (
    <StyledInputWrapper className={className} size={size} required={required}>
      {label?.length > 0 && size === "tall" && (
        <StyledLabel>{label}</StyledLabel>
      )}
      {label?.length > 0 && size === "short" && (
        <StyledLabelShort>{label}</StyledLabelShort>
      )}
      <CustomH5 color={colors.muttedHeadlines}>
        {fileName ? fileName : placeholder}
      </CustomH5>
      <HiddenInput type="file" ref={inputFile} onChange={onChangeHandler} />
      <StyledIcon onClick={onButtonClick}>
        <Link />
      </StyledIcon>
    </StyledInputWrapper>
  );
};

const StyledInputWrapper = styled.div<any>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: ${(props) => (props.size === "tall" ? "72px" : "48px")};
  width: 464px;
  max-width: 100%;
  border: 1px solid ${(props) => props.theme.colors.tables.borders};
  border-radius: 4px;
  padding: 0 24px;
  box-sizing: border-box;
  background-color: ${(props) =>
    props.disabled ? props.theme.colors.tables.borders : "white"};
`;

const StyledLabel = styled.span<any>`
  color: ${(props) => props.theme.colors.secondary};
  font-size: 12px;
  margin-bottom: 4px;
`;

const StyledLabelShort = styled(StyledLabel)<any>`
  display: inline-block;
  padding: 4px;
  position: absolute;
  margin: -47px 0 0 -10px;
  background-color: #fff;
`;

const StyledIcon = styled.div`
  line-height: 0px;
  cursor: pointer;
`;

const HiddenInput = styled.input`
  visibility: hidden;
`;

const CustomH5 = styled(H5)`
  margin-block-start: 0px;
  margin-block-end: 0px;
`;
