import React, { useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Modal, Button } from "components";
import styled from "styled-components";
import { api } from "../../../../../api/api";
import { propertyErrorHandler } from "../../../../../store/helpers";
import { useDispatch } from "react-redux";
import { removePropertyWithoutApiCall } from "../../../../../store/reducers/products/properties/pl-properties.actions";
import {
  getSubPropertiesAction,
  removeCustomSubPropertyWithoutApiCall,
} from "../../../../../store/reducers/products/subproperties/pl-subproperties.actions";

export default function RemovePropertyModal({
  propertyId,
  productLineId,
  parentId,
  type = "main",
}: {
  propertyId: string;
  productLineId: string;
  parentId?: string;
  type?: "main" | "sub";
}) {
  const errorMessageInitialState = {
    message: null,
    properties: null,
    rulesTables: null,
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(errorMessageInitialState);

  const dispatch = useDispatch();

  const handleClickRemove = async (force = false) => {
    try {
      const res = await api.removeProperty(productLineId, propertyId, {
        force,
      });

      if (!res) throw null;

      if (type === "main")
        dispatch(removePropertyWithoutApiCall({ propertyId }));
      if (type === "sub") {
        await dispatch(
          removeCustomSubPropertyWithoutApiCall({
            customSubPropertyId: propertyId,
            parentId: parentId,
          })
        );
        dispatch(
          getSubPropertiesAction({
            productLineId: productLineId,
            PLPropertyId: parentId,
          })
        );
      }

      setErrorMessage(errorMessageInitialState);
      setModalOpen(false);
    } catch (e) {
      if (!force) {
        const {
          message,
          properties,
          rulesTables,
        } = removePropertyErrorFormatter(e);
        setErrorMessage({ message, properties, rulesTables });
      } else {
        propertyErrorHandler(e, "error while deleting property");
        setErrorMessage(errorMessageInitialState);
        setModalOpen(false);
      }
    }
  };

  const handleCloseModal = () => {
    setErrorMessage(errorMessageInitialState);
    setModalOpen(false);
  };

  return (
    <>
      <Tooltip
        title={type === "main" ? "Delete Property" : "Delete Subproperty"}
        arrow
      >
        <IconButton
          size={type === "main" ? "medium" : "small"}
          color="warning"
          //color={type === "main" ? "warning" : "primary"}
          aria-label="delete property"
          onClick={() => setModalOpen(true)}
          style={{ marginLeft: "30px" }}
        >
          <DeleteOutlineIcon fontSize="inherit" />
        </IconButton>
      </Tooltip>
      <Modal
        closeModal={handleCloseModal}
        open={modalOpen}
        height={"auto"}
        title={"Warning"}
      >
        {!errorMessage.message ? (
          <>
            <StyledMessage>
              You are about to remove the property with all its subproperties.
              Are you sure?
            </StyledMessage>
            <StyledButton onClick={() => handleClickRemove(false)}>
              Remove property
            </StyledButton>
          </>
        ) : (
          <>
            <StyledMessage>
              The property you wish to remove has some dependencies:
            </StyledMessage>
            {printErrorData(errorMessage)}
            <StyledButton onClick={() => handleClickRemove(true)}>
              Remove anyway
            </StyledButton>
          </>
        )}
      </Modal>
    </>
  );
}

const removePropertyErrorFormatter = (error) => {
  const errorData = error?.response?.data;
  const message = errorData?.message;
  const properties = errorData?.subProperties?.map((property) => property.name);
  const rulesTables = errorData?.rulesTables;

  return { message, properties, rulesTables };
};

const printErrorData = (error) => {
  return (
    <DependenciesList>
      <b>ErrorMessage: </b>
      {error.message}

      {error.properties && (
        <DependenciesList>
          <b>Properties: </b>
          {error.properties.map((property, index) => (
            <li key={index}>{property}</li>
          ))}
        </DependenciesList>
      )}

      {error.ruleSets && (
        <DependenciesList>
          <b>Rulesets: </b>
        </DependenciesList>
      )}
    </DependenciesList>
  );
};

const StyledButton = styled(Button)`
  margin-top: 24px;
`;

const StyledMessage = styled.div`
  font-size: 16px;
  margin-bottom: 16px;
`;

const DependenciesList = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
